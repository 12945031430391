import http from '../http-common';

/* const getStructure = (params) => {
    const queryParams = params
    ? Object.keys(params)
        .map((k) => (k) + '=' + (params[k]))
        .join('&')
    : '';
    return http.get(`/structure?${queryParams}`);
} */

const deleteStructure = (params) => {
    const queryParams = params
        ? Object.keys(params)
            .map((k) => k + '=' + params[k])
            .join('&')
        : '';
    return http.delete(`/structure?${queryParams}`);
};

const getStructures = (params) => {
    const queryParams = params
        ? Object.keys(params)
            .map((k) => k + '=' + params[k])
            .join('&')
        : '';
    return http.get(`/structures?${queryParams}`);
};

const getTornaments = (params) => {
    const queryParams = params
        ? Object.keys(params)
            .map((k) => k + '=' + params[k])
            .join('&')
        : '';
    return http.get(`/tournaments?${queryParams}`);
};

/*const getTornamentByWeeks = (params) => {
    const queryParams = params
        ? Object.keys(params)
            .map((k) => k + '=' + params[k])
            .join('&')
        : '';
    return http.get(`/tournament/weeks${queryParams}`);
};*/

const getUsers = (params) => {
    const queryParams = params
        ? Object.keys(params)
            .map((k) => k + '=' + params[k])
            .join('&')
        : '';
    return http.get(`/users?${queryParams}`);
};

const getUser = (params) => {
    const queryParams = params
        ? Object.keys(params)
            .map((k) => (k) + '=' + (params[k]))
            .join('&')
        : '';
    return http.get(`/user?${queryParams}`);
};

const getUsersPersonalizedServices = (params) => {
    const queryParams = params
        ? Object.keys(params)
            .map((k) => (k) + '=' + (params[k]))
            .join('&')
        : '';
    return http.get(`/users/personalizedServices?${queryParams}`);
}

const getSettings = () => {
    return http.get('/settings');
};

const getNetworks = () => {
    return http.get('/networks');
};

const getProfiles = () => {
    return http.get('/profiles');
};

const getSubscriptions = () => {
    return http.get('/subscriptions');
};

const getStraights = (params) => {
    const queryParams = params
    ? Object.keys(params)
        .map((k) => k + '=' + params[k])
        .join('&')
    : '';
    return http.get(`/straights?${queryParams}`);
};


const getTeams = (params) => {
    const queryParams = params
    ? Object.keys(params)
        .map((k) => k + '=' + params[k])
        .join('&')
    : '';
    return http.get(`/teams?${queryParams}`);
};

const postFavoriteTournament = (params) => {
    return http.post('/favoriteTournament', params);
};

const deleteTornamentById = (params) => {
    const queryParams = params
        ? Object.keys(params)
            .map((k) => k + '=' + params[k])
            .join('&')
        : '';
    return http.delete(`/tournament?${queryParams}`);
};

const deleteTornaments = (params) => {
    return http.delete('/tournaments', { data: params });
};

const patchTournament = (params) => {
    return http.patch('/tournament', params);
};

const patchCustomizedTournament = (params) => {
    return http.patch('/customizedTournament', params);
};

const patchUser = (params) => {
    return http.patch('/user', params);
}

const postCustomizedTournament = (params) => {
    return http.post('/customizedTournament', params);
};

const postLogin = (params) => {
    return http.post('/login', params);
};

const postValidateToken = (params) => {
    return http.post('/validateToken', params);
};

const postRetrieveAccount = (params) => {
    const queryParams = params
        ? Object.keys(params)
            .map((k) => k + '=' + params[k])
            .join('&')
        : '';
    return http.post(`/retrieveAccount?${queryParams}`);
};

const postRegisterAccount = (params) => {
    return http.post('/registerAccount', params);
};

const postAlarmTournament = (params) => {
    return http.post('/alarmTournament', params);
};

const postPrioridades = (params) => {
    return http.post('/user/priorities', params);
};

const postRetas = (params) => {
    return http.post('/straights', params);
};

const patchRetas = (params) => {
    return http.patch('/straights', params);
};

const deleteReta = (params) => {
    return http.delete('/straights', { data: params });
};

const deletePrioridades = (params) => {
    const queryParams = params
        ? Object.keys(params)
            .map((k) => (k) + '=' + (params[k]))
            .join('&')
        : '';
    return http.delete(`/user/priorities?${queryParams}`);
};

const putReta = (params) => {
    return http.put('/straights', params);
};

const MyLobbyService = {
    getSettings,
    deleteStructure,
    getStructures,
    getTornaments,
    //getTornamentByWeeks,
    deleteTornaments,
    deleteTornamentById,
    getUser,
    getUsers,
    getUsersPersonalizedServices,
    patchTournament,
    getNetworks,
    getProfiles,
    getSubscriptions,
    getStraights,
    getTeams,
    postLogin,
    postValidateToken,
    postFavoriteTournament,
    patchCustomizedTournament,
    patchUser,
    postCustomizedTournament,
    postRetrieveAccount,
    postRegisterAccount,
    postAlarmTournament,
    postRetas,
    patchRetas,
    deleteReta,
    putReta,
    postPrioridades,
    deletePrioridades,
};

export default MyLobbyService;
