import React from 'react';
import { Divider } from 'primereact/divider';
import { InputSwitch } from 'primereact/inputswitch';
import { InputText } from 'primereact/inputtext';
import { siteImageTemplate } from '../helpers/core/util/SiteUtil';
import { useDebounce } from '../hooks/useDebounce';
import { classNames } from 'primereact/utils';

export const NetworksInfo = ({ isHome = false }) => {
    const data = [
        { nick: 'Kriguer#123123', active: false, idNetwork: '888poker' },
        { nick: 'AcePlayer', active: true, idNetwork: 'PartyPoker' },
        { nick: 'BluffMaster', active: true, idNetwork: 'GGNetwork' },
        { nick: 'LuckyCharm', active: false, idNetwork: 'PokerStars' },
        { nick: 'LuckyCharm', active: false, idNetwork: 'WPN' },
        { nick: '', active: false, idNetwork: 'PokerStars.ES' },
        { nick: '', active: false, idNetwork: 'Winamax.FR' },
        { nick: '', active: false, idNetwork: 'iPoker' },
        { nick: '', active: false, idNetwork: '888Poker.PT' },
        { nick: '', active: false, idNetwork: 'Chico' }
    ];
    const [networks, setNetworks] = React.useState(data);

    const debouncedHandleNickNameChange = useDebounce((value, network) => {
        setNetworks((networks) =>
            networks.map((networkItem) => {
                if (networkItem.idNetwork === network.idNetwork) {
                    networkItem.nick = value;
                }
                return networkItem;
            })
        );
        //Fazer a requisição http aqui
    });

    const handleNickNameChange = (e, network) => {
        const { value } = e.target;
        debouncedHandleNickNameChange(value, network);
    };

    return (
        <div className="w-full bg-gray-900 p-4 border-round flex flex-column gap-4">
            <div>
                <p className="m-0 font-bold text-xl">Seus sites</p>
                <Divider />
            </div>

            <div className="gap-2 p-2" style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fit,minmax(256px,1fr))' }}>
                {networks?.map((network) =>
                    network.nick !== '' && isHome ? (
                        <label htmlFor={network.idNetwork} className={classNames('flex justify-content-between select-none  bg-gray-700 border-round px-4 py-3 gap-2 hover:shadow-6', !isHome && 'cursor-pointer')} key={network.idNetwork}>
                            <div className="flex gap-2 w-full">
                                {siteImageTemplate(network.idNetwork, 32)}
                                <InputText
                                    defaultValue={network.nick}
                                    disabled={isHome}
                                    className={classNames('m-0 font-bold w-full ', isHome && 'bg-gray-700 border-none')}
                                    placeholder="Insira seu nick"
                                    style={{ color: 'white' }}
                                    onChange={(e) => {
                                        handleNickNameChange(e, network);
                                    }}
                                />
                            </div>

                            {!isHome && (
                                <div className="flex flex-row gap-2 align-items-center">
                                    <InputSwitch
                                        checked={network.active}
                                        inputId={network.idNetwork}
                                        onChange={(e) => {
                                            setNetworks((networks) =>
                                                networks.map((networkItem) => {
                                                    if (networkItem.idNetwork === network.idNetwork) {
                                                        networkItem.active = e.value;
                                                    }
                                                    return networkItem;
                                                })
                                            );
                                        }}
                                    />
                                </div>
                            )}
                        </label>
                    ) : (
                        !isHome && (
                            <label htmlFor={network.idNetwork} className="flex justify-content-between select-none cursor-pointer bg-gray-800 border-round px-4 py-3 gap-2 hover:bg-gray-700" key={network.idNetwork}>
                                <div className="flex gap-2 w-full">
                                    {siteImageTemplate(network.idNetwork, 32)}
                                    <InputText
                                        defaultValue={network.nick}
                                        disabled={isHome}
                                        className="m-0 font-bold w-full"
                                        placeholder="Insira seu nick"
                                        onChange={(e) => {
                                            handleNickNameChange(e, network);
                                        }}
                                    />
                                </div>

                                {!isHome && (
                                    <div className="flex flex-row gap-2 align-items-center">
                                        <InputSwitch
                                            checked={network.active}
                                            inputId={network.idNetwork}
                                            onChange={(e) => {
                                                setNetworks((networks) =>
                                                    networks.map((networkItem) => {
                                                        if (networkItem.idNetwork === network.idNetwork) {
                                                            networkItem.active = e.value;
                                                        }
                                                        return networkItem;
                                                    })
                                                );
                                            }}
                                        />
                                    </div>
                                )}
                            </label>
                        )
                    )
                )}
            </div>
        </div>
    );
};
