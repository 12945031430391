import React from 'react';
import { NetworksInfo } from '../components/NetworksInfo';
import { PlayerInfoCard } from '../components/PlayerInfoCard';
import { PlayerInfoData } from '../components/PlayerInfoData';
import { useAuth } from '../hooks/useAuth';

export const AccountProfile = () => {
    const { user } = useAuth();

    return (
        <div className="flex flex-column gap-4 flex w-full bg-gray-800 border-round-left p-4 mt-0 md:mt-8 lg:mt-0">
            <div className="flex flex-column xl:flex-row w-full align-items-start gap-2">
                <PlayerInfoCard />
                <PlayerInfoData />
            </div>

            {user.usersView.forTeam && <NetworksInfo />}
        </div>
    );
};
