import { useState } from 'react'

const useLocalStorage = (keyName, defaultValue) => {
  const [storedValue, setStoredValue] = useState(() => {
    try {
      const value = window.localStorage.getItem(keyName)

      if (value) {
        return JSON.parse(value)
      } else {
        window.localStorage.setItem(keyName, JSON.stringify(defaultValue))
        return defaultValue
      }
    } catch (err) {
      return defaultValue
    }
  })

  const setValue = (newValue) => {
    try {
      window.localStorage.setItem(keyName, JSON.stringify(newValue))
    } catch (err) { }
    setStoredValue(newValue)
  }

  return [storedValue, setValue]
}

const getValue = (keyName) => {
  try {
    return window.localStorage.getItem(keyName)
  } catch (err) { }
}

const removeValue = (keyName) => {
  try {
    window.localStorage.removeItem(keyName)
  } catch (err) { }
}

export { useLocalStorage, getValue, removeValue }
