import { DateTime, Interval } from 'luxon'
import { isUndefined } from '../helpers/core/util/ObjectUtil'

function calculaFimRegistro (dataInicioTorneio, dataFimRegistro) {
  const dataAtual = DateTime.now()
  dataInicioTorneio = DateTime.fromISO(dataInicioTorneio)
  let fimRegistroFormatado = ''
  const datasIguais = dataAtual >= dataInicioTorneio && dataAtual.toFormat('yyyy-MM-dd') === dataInicioTorneio.toFormat('yyyy-MM-dd')
  const torneioCompletado = dataInicioTorneio < dataAtual
  let fimDoRegistro = calculaIntervalo(dataInicioTorneio, dataFimRegistro)
  if (fimDoRegistro !== null) {
    if (fimDoRegistro.isValid) {
      fimDoRegistro = fimDoRegistro.toObject()

      if (fimDoRegistro.hours > 0) {
        fimRegistroFormatado = `${fimRegistroFormatado}${fimDoRegistro.hours} hora(s) `
      }
      if (fimDoRegistro.minutes > 0) {
        fimRegistroFormatado = `${fimRegistroFormatado}${Math.floor(fimDoRegistro.minutes)} min. `
      }
      if (fimDoRegistro.seconds > 0) {
        fimRegistroFormatado = `${fimRegistroFormatado}${Math.floor(fimDoRegistro.seconds)} seg.`
      }
    }
    if (fimDoRegistro.hours === 0 & fimDoRegistro.minutes > 5 & fimDoRegistro.minutes <= 15) {
      fimRegistroFormatado = <span style={{ color: '#ff0000' }}>{fimRegistroFormatado}</span>
    } else {
      if ((fimDoRegistro.minutes > 0 | fimDoRegistro.seconds > 0) & fimDoRegistro.hours === 0 & fimDoRegistro.minutes <= 5) {
        fimRegistroFormatado = <span className='hour-refresh' style={{ color: '#ff0000', fontWeight: 'bold' }}>{fimRegistroFormatado}</span>
      }
    }
  }
  return fimRegistroFormatado !== '' ? fimRegistroFormatado : datasIguais ? 'end' : !torneioCompletado ? 'reg' : 'comp'
};

function calculaIntervalo (dataInicioTorneio, dataFimRegistro) {
  const dataAtual = DateTime.now()
  dataInicioTorneio = DateTime.fromISO(dataInicioTorneio)
  let fimDoRegistro = dataFimRegistro !== null && (dataAtual >= dataInicioTorneio) ? DateTime.fromISO(dataFimRegistro) : null
  if (fimDoRegistro !== null) {
    const intervalo = Interval.fromDateTimes(dataInicioTorneio, fimDoRegistro)
    if (intervalo.isValid) {
      fimDoRegistro = fimDoRegistro.diff(dataAtual, ['hours', 'minutes', 'seconds'])
    } else {
      return null
    }
  }
  return fimDoRegistro
}

function calculaFimTorneio (dataFimTorneio) {
  const fimDoTorneio = !isUndefined(dataFimTorneio) && dataFimTorneio !== '-' ? DateTime.fromISO(dataFimTorneio) : null
  if (fimDoTorneio != null) {
    return fimDoTorneio.toFormat('HH:mm')
  } else {
    return '-'
  }
};

export { calculaFimRegistro, calculaFimTorneio, calculaIntervalo }
