import { classNames } from 'primereact/utils';
import React, { useEffect, useState } from 'react';
// import { InputText } from 'primereact/inputtext';
import { Avatar } from 'primereact/avatar';
import { useLocation, useNavigate } from 'react-router-dom';
import AppMenu from './AppMenu';
import NavHome from './components/NavHome';
import { useAuth } from './hooks/useAuth';

const AppTopbar = (props) => {
    const { user, logout } = useAuth();
    const navigate = useNavigate();
    //const searchPanelDesktop = useRef(null);
    //const searchPanelMobile = useRef(null);
    const location = useLocation();
    const [filter, setFilter] = useState([]);

    useEffect(() => {
        setFilter(props.filter);
    }, [props.filter]);

    /*useEffect(() => {
        console.log(props.filter);
        if (props.searchActive) {
            if (window.innerWidth >= 576) searchPanelDesktop.current.focus();
            else searchPanelMobile.current.focus();
        }
        // eslint-disable-next-line
    }, [props.searchActive]);

    /* const onInputKeydown = (event) => {
        const key = event.which;

        //escape, tab and enter
        if (key === 27 || key === 9 || key === 13) {
            if (props.searchActive) props.onTopbarSearchToggle();
        }
    }; */

    return (
        <div className="layout-topbar">
            <div className="layout-topbar-wrapper">
                <div className="layout-topbar-left">
                    <button tabIndex="0" className="menu-button p-link" onClick={props.onMenuButtonClick}>
                        <i className="pi pi-bars" />
                    </button>
                    {/* <button tabIndex="0" id="logo-link" onClick={() => navigate('/')} className="layout-topbar-logo p-link">
                        <img src={`assets/layout/images/logo-${props.topbarScheme === 'dark' ? 'freya-white' : 'freya'}.svg`} alt="freya-layout" />
                    </button> */}
                </div>

                <AppMenu
                    menuMode={props.menuMode}
                    sidebarActive={props.sidebarActive}
                    sidebarStatic={props.sidebarStatic}
                    model={props.menu}
                    menuActive={props.menuActive}
                    onRootMenuItemClick={props.onRootMenuItemClick}
                    onMobileMenuActive={props.onMobileMenuActive}
                    onMenuItemClick={props.onMenuItemClick}
                    onSidebarMouseOver={props.onSidebarMouseOver}
                    onSidebarMouseLeave={props.onSidebarMouseLeave}
                    onToggleMenu={props.onToggleMenu}
                    resetActiveIndex={props.resetActiveIndex}
                    onMenuClick={props.onMenuClick}
                    onFilter={props.onFilter}
                    filter={filter}
                    filterActive={location.pathname === '/'}
                />

                <div className="layout-topbar-right">
                    <NavHome />

                    <ul className="layout-topbar-actions ">
                        {/* <li className={classNames('topbar-item search-item', { 'active-topmenuitem': props.searchActive })}>
                            <button className="p-link" tabIndex="0" style={{ cursor: 'pointer' }} onClick={props.onTopbarSearchToggle}>
                                <i className="topbar-icon pi pi-search"></i>
                            </button>

                            <div className="search-input-wrapper" onClick={props.onTopbarSearchClick}>
                                <span className="p-input-icon-left">
                                    <i className="pi pi-search"></i>
                                    <InputText ref={searchPanelDesktop} type="text" placeholder="Search..." onKeyDown={onInputKeydown} />
                                </span>
                            </div>

                            <ul className="fadeInDown">
                                <div className="search-input-wrapper p-fluid" style={{ width: '100%' }} onClick={props.onTopbarSearchClick}>
                                    <span className="p-input-icon-left">
                                        <i className="pi pi-search"></i>
                                        <InputText ref={searchPanelMobile} type="text" placeholder="Search..." onKeyDown={onInputKeydown} />
                                    </span>icons
                                </div>
                            </ul>
                        </li> */}

                        <li className={classNames('topbar-item user-profile', { 'active-topmenuitem fadeInDown': props.topbarUserMenuActive })}>
                            <div>
                                <small>{user.name}</small>
                                <br />
                                <small>{user.email}</small>
                            </div>
                            <button className="p-link" style={{ cursor: 'pointer' }} onClick={props.onTopbarUserMenuClick}>
                                {/* <img src="assets/layout/images/avatar-profilemenu.png" alt="freya-layout" /> */}
                                <Avatar label={user.initialLetterName} className="bg-red-500 text-white" shape="circle" />
                            </button>
                            <ul className="fadeInDown">
                                <li
                                    onClick={() => {
                                        navigate('/user_profile');
                                    }}
                                >
                                    <button className="p-link">
                                        <span>Perfil</span>
                                    </button>
                                </li>
                                {/* <li>
                                    <button className="p-link">
                                        <span>Settings</span>
                                    </button>
                                </li>
                                <li>
                                    <button className="p-link">
                                        <span>Messages</span>
                                    </button>
                                </li>
                                <li>
                                    <button className="p-link">
                                        <span>Notifications</span>
                                    </button>
                                </li> */}
                                <li onClick={logout}>
                                    <button type="button" className="p-link">
                                        <span>Sair</span>
                                    </button>
                                </li>
                            </ul>
                        </li>
                    </ul>

                    {/* <button tabIndex="0" style={{ cursor: 'pointer' }} className="layout-rightpanel-button p-link" onClick={props.onRightPanelButtonClick}>
                        <i className="pi pi-arrow-left"></i>
                    </button> */}
                </div>
            </div>
        </div>
    );
};

export default AppTopbar;
