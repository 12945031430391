const siteImageTemplate = (site, size = '24px') => {
  return (
    <>
      {site === '888Poker' ? <img alt={site} src={`assets/layout/images/network/${site}.png`} style={{ width: size }} /> : ''}
      {site === 'PartyPoker' ? <img alt={site} src={`assets/layout/images/network/${site}.png`} style={{ width: size }} /> : ''}
      {site === 'GGNetwork' ? <img alt={site} src={`assets/layout/images/network/${site}.png`} style={{ width: size }} /> : ''}
      {site === 'PokerStars' ? <img alt={site} src={`assets/layout/images/network/${site}.png`} style={{ width: size }} /> : ''}
      {site === 'WPN' ? <img alt={site} src={`assets/layout/images/network/${site}.png`} style={{ width: size }} /> : ''}
      {site === 'PokerStars.ES' ? <img alt={site} src={`assets/layout/images/network/${site}.png`} style={{ width: size }} /> : ''}
      {site === 'Winamax.FR' ? <img alt={site} src={`assets/layout/images/network/${site}.png`} style={{ width: size }} /> : ''}
      {site === 'iPoker' ? <img alt={site} src={`assets/layout/images/network/${site}.png`} style={{ width: size }} /> : ''}
      {site === '888Poker.PT' ? <img alt={site} src={`assets/layout/images/network/${site}.png`} style={{ width: size }} /> : ''}
      {site === 'Chico' ? <img alt={site} src={`assets/layout/images/network/${site}.png`} style={{ width: size }} /> : ''}
    </>
  )
};

export { siteImageTemplate }
