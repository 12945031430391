import { Badge } from 'primereact/badge';
import { Checkbox } from 'primereact/checkbox';
import React, { useState } from 'react';

function FilterCheckBox (props) {
  const [selectedItems, setselectedItems] = useState([])

  const onChange = (event) => {
    let _selectedItems = [...selectedItems]

    if (event.checked) {
      _selectedItems.push(event.value)
    } else {
      _selectedItems = _selectedItems.filter((item) => item.key !== event.value.key)
    }

    setselectedItems(_selectedItems)
    _selectedItems = _selectedItems.map((item) => {
      return item.name
    });
    props.handlerFilterValue(_selectedItems, props.filterByField)
  }

    return (
        <>
            {!props.withBadge && props.items.map((item) => {
                return (
                    <div key={item.key} className="flex align-items-center pt-2">
                        <Checkbox inputId={item.key} name="item" value={item} onChange={onChange}
                            checked={selectedItems.some((it) => it.key === item.key)} />
                        <label htmlFor={item.key} className="ml-2">
                            {item.name}
                        </label>
                    </div>
                );
            })}

            {props.withBadge && props.items.map((item, index) => {
                return (
                    <div key={item.key} className="flex align-items-center pt-2">
                        <Checkbox inputId={item.key} name="item" value={item} onChange={onChange}
                            checked={selectedItems.some((it) => it.key === item.key)} disabled={selectedItems.some((it) => it.key !== item.key)} />
                        <label htmlFor={item.key} className="ml-2">
                            {index === 0 && <Badge style={{ width: '60px' }} value={item.name} severity="danger" />}
                            {index === 1 && <Badge style={{ width: '60px' }} value={item.name} severity="warning" />}
                            {index === 2 && <Badge style={{ width: '60px' }} value={item.name} severity="success" />}
                        </label>
                    </div>
                );
            })}
        </>
    );
}

export default FilterCheckBox
