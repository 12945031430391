const isObject = (obj) => {
  return typeof obj === 'object'
}

const isEmpty = (obj) => {
  return obj === ''
}

const isNull = (obj) => {
  return obj === null
}

const isUndefined = (elem) => {
  return typeof elem === 'undefined'
}

const isArray = (elem) => {
  let isArray = false
  if (Array.isArray(elem)) {
    isArray = true
  }
  return isArray
}

const isArrayEmpty = (elem) => {
  let isArrayEmpty = false
  if (Array.isArray(elem) && !elem.length && !isUndefined(elem)) {
    isArrayEmpty = true
  }
  return isArrayEmpty
}

const containsString = (value) => {
  const regex = /[A-Za-z]+/i
  return value.match(regex)
}

export { containsString, isArray, isArrayEmpty, isEmpty, isNull, isObject, isUndefined }
