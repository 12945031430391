/**
 *
 * Verifica o valor informado e retorna o minimo ou maximo de
 * forma que não ultrapasse o valor permitido menor e maior
 *
 * @param {number} value
 * @param {number} min
 * @param {number} max
 * @returns {number}
 */
export const formatValueByMinAndMax = (value, min, max) => {
    if(value < min) return ''
    if(value > max) return max
    return value
}
