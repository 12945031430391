// format number to US dollar
const usDollar = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD'// format number to US dollar

});

const brReal = new Intl.NumberFormat('pt-BR', {
  style: 'currency',
  currency: 'BRL'
});

const percent = new Intl.NumberFormat('pt-BR', {
  style: 'percent',
});

export { usDollar, brReal, percent }
