import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { DateTime } from 'luxon'
import { Button } from 'primereact/button'
import { Calendar } from 'primereact/calendar'
import { Dialog } from 'primereact/dialog'
import { Divider } from 'primereact/divider'
import { Dropdown } from 'primereact/dropdown'
import { InputText } from 'primereact/inputtext'
import React, { useCallback, useEffect, useState } from 'react'
import PhoneInput from 'react-phone-number-input'
import AppMesagens from '../AppMesagens'
import { tratarExcecao } from '../helpers/core/util/ExceptionUtil'
import { teamImageTemplate } from '../helpers/core/util/TeamUtil'
import { useAuth } from '../hooks/useAuth'
import MyLobbyService from '../service/MyLobbyService'
import { subscriptionType } from './SubscriptionType'
import { isArrayEmpty, isUndefined } from '../helpers/core/util/ObjectUtil'
import MensagemLista from '../helpers/core/message/MensagemLista'
import EnumTipoMensagem from '../helpers/core/enumerator/EnumTipoMensagem'

const UsersDialog = ({ showDialog, hideDialog, selectedUser, teams }) => {
    const { user } = useAuth();
    const [loading, setLoading] = useState(false);
    const [subscriptionExpireAt, setSubscriptionExpireAt] = useState(null);
    const [subscriptions, setSubscriptions] = useState([]);
    const [subscription, setSubscription] = useState(null);
    const [profiles, setProfiles] = useState({});
    const [profile, setProfile] = useState();
    const [mensagens, setMensagens] = useState('');
    const [showDialogTeam, setShowDialogTeam] = useState(false);
    const [showConfirmUnlinkStraight, setShowConfirmUnlinkStraight] = useState(false);
    const [team, setTeam] = useState(null);
    const [straightName, setStraightName] = useState('');

    const handleUser = (selectedUser) => {
        let _subscriptionExpireAt = selectedUser?.subscriptionExpireAt !== null ? DateTime.fromISO(selectedUser?.subscriptionExpireAt).toJSDate() : null;
        let _subscription = selectedUser?.subscription !== null ? selectedUser?.subscription?.name : null;
        let _profile = selectedUser?.profile !== null ? selectedUser?.profile : null;
        let _team = selectedUser?.team !== null ? selectedUser?.team : null;
        setSubscriptionExpireAt(_subscriptionExpireAt);
        setSubscription(_subscription);
        setProfile(_profile);
        setTeam(_team);
    }

    const loadProfiles = useCallback(async () => {
        MyLobbyService.getProfiles()
            .then((response) => {
                return response.data;
            })
            .then((data) => {
                setProfiles(data);
            })
            .catch((error) => {
                let mensagem = tratarExcecao(error);
                setMensagens(mensagem);
            })
            .finally(() => {
            });
    }, []);

    const loadSubscriptions = useCallback(async () => {
        MyLobbyService.getSubscriptions().then((response) => {
            return response.data;
        }).then((data) => {
            setSubscriptions(data);
        }).catch((error) => {
            let mensagem = tratarExcecao(error);
            setMensagens(mensagem);
        }).finally(() => { });
    }, []);

    const desvincularJogador = useCallback(async (data) => {
        setLoading(true);
        MyLobbyService.putReta(data)
            .then((data) => {
                const mensagem = new MensagemLista();
                mensagem.simpleMensagem('Jogador desvinculado com sucesso', EnumTipoMensagem.SUCCESS);
                setMensagens(mensagem);
            })
            .catch((error) => {
                setLoading(false);
                let mensagem = tratarExcecao(error);
                setMensagens(mensagem);
            })
            .finally(() => {
                setLoading(false);
                setShowConfirmUnlinkStraight(false);
                hideDialog();
            });
    }, [hideDialog]);

    useEffect(() => {
        loadProfiles();
        loadSubscriptions();
    }, [loadProfiles, loadSubscriptions]);

    useEffect(() => {
        handleUser(selectedUser);
    }, [selectedUser]);

    const saveUsers = () => {
        let _user = {
            ...selectedUser,
            profile: profile,
            subscription: subscription,
            teamName: team?.name
        }
        if ((profile === "Team Player" || profile === "Manager") && team === null) {
            setShowDialogTeam(true);
        } else {
            hideDialog(_user);
        }
    };

    const onSubscription = (event) => {
        let _subscription = event.value;
        setSubscription(_subscription);

    };

    const onProfile = (event) => {
        let _profile = event.value;
        switch (_profile) {
            case "Team Player": case "Manager":
                setShowDialogTeam(true);
                break;
            default:
                setTeam(null);
        }
        setProfile(_profile);
    };

    const onTeam = (event) => {
        let name = event.value;
        let _team;
        // nao tem nenhum time cadastrado ainda
        if (team === null) {
            _team = {
                name
            }
        } else {
            // tem time
            _team = {
                ...team,
                name
            }
        }
        setTeam(_team);
        setShowDialogTeam(false);
    }

    const onHideDialogTeam = () => {
        setShowDialogTeam(false);
    }

    const onConfirmUnlinkStraightFooter = () => {
        setShowConfirmUnlinkStraight(true);
        setStraightName('');
    }

    const subscriptionOptionTemplate = (option) => {
        return (
            <div>{option.name} - $ {option.value}</div>
        );
    }

    const teamsOptionTemplate = (option) => {
        return (
            <React.Fragment>
                <div className="flex align-content-center flex-wrap">
                    <div className="flex align-items-center justify-content-center gap-3">
                        {option.logo !== "/" && <img alt={option.nickname} src={option.logo} width="24px" />}
                        {option.logo === "/" && <i className="pi pi-image" style={{ 'fontSize': '1.5em' }}></i>}
                        <span>{option.name}</span>
                    </div>
                </div>
            </React.Fragment>
        );
    }

    const usersDialogFooter = () => {
        return (
            <React.Fragment>
                <Button
                    label="Desvincular reta"
                    className="p-button-text p-button-danger"
                    icon={<FontAwesomeIcon icon={solid('xmark')} className="mr-2" />}
                    onClick={onConfirmUnlinkStraightFooter}
                    loading={loading}
                    disabled={!selectedUser?.straights[0]?.line || selectedUser?.profile !== "Team Player"}
                />
                <Button label="Salvar" icon="pi pi-check" onClick={saveUsers} disabled={!selectedUser?.actived} />
            </React.Fragment>
        );
    };

    const headerUsersDialog = () => {
        return (
            <React.Fragment>
                <div className="flex align-content-center flex-wrap">
                    <div className="flex align-items-center justify-content-center">
                        {team !== null && <span className="mr-2">{teamImageTemplate(selectedUser, true)}</span>}
                        {team === null && <span className="mr-2">{subscriptionType(selectedUser?.profileCard)}</span>}
                        <span>{profile === 'Single Player' ? 'Jogador' : ''}</span>
                        <span>{profile === 'Team Player' ? 'Jogador de Time' : ''}</span>
                        <span>{profile === 'Manager' ? 'Gerente de Time' : ''}</span>
                        <span>{profile === 'Owner' || profile === 'Suporte' ? 'Administrador' : ''}</span>
                    </div>
                </div>
            </React.Fragment>
        );
    };

    const confirmUnlinkStraightFooter = () => {
        return (
            <div className="flex justify-content-end flex-wrap">
                <Button label="Deletar" icon="pi pi-trash" onClick={deleteUnlinkStraight} disabled={loading || straightName !== selectedUser?.straights[0]?.line} />
            </div>
        );
    };

    const deleteUnlinkStraight = () => {
        if (straightName !== null || !isUndefined(straightName)) {
            let _straights = user?.straights?.find((reta) => reta.line === selectedUser?.straights[0]?.line);
            let _deleteUserStraight = _straights?.straightsPlayers?.filter((straightPlayer) => straightPlayer.user.email !== selectedUser?.email);
            let data = {};
            if (isArrayEmpty(_deleteUserStraight)) {
                data = {
                    line: selectedUser?.straights[0]?.line,
                    sub: selectedUser?.straights[0]?.sub,
                    straightsTournaments: [],
                    user: {
                        email: user?.email,
                        accessToken: user?.accessToken
                    }
                };
            } else {
                data = {
                    line: selectedUser?.straights[0]?.line,
                    sub: selectedUser?.straights[0]?.sub,
                    straightsPlayers: _deleteUserStraight,
                    straightsTournaments: [],
                    user: {
                        email: user?.email,
                        accessToken: user?.accessToken
                    }
                };
            }
            desvincularJogador(data);
        }
    };


    return (
        <React.Fragment>
            {mensagens !== '' ? <AppMesagens mensagens={mensagens} position="bottom-center" /> : ''}
            <Dialog header={headerUsersDialog} visible={showDialog} style={{ width: '50vw' }} onHide={hideDialog} footer={usersDialogFooter} >
                <div className="card p-fluid">
                    <div className="formgrid grid">
                        <div className="field col-5">
                            <label htmlFor="nome">Nome completo</label>
                            <div className="p-inputgroup">
                                <span className="p-inputgroup-addon">
                                    {subscriptionType(selectedUser?.profileCard)}
                                </span>
                                <InputText id="nome" defaultValue={selectedUser?.name} disabled />
                            </div>
                        </div>
                        <div className="field col-4">
                            <label htmlFor="email">E-mail</label>
                            <InputText htmlFor="email" defaultValue={selectedUser?.email} disabled />
                        </div>
                        <div className="field col-3">
                            <label htmlFor="celular">Celular</label>
                            <PhoneInput value={selectedUser?.phoneNumber} className={"input-phone-number w-fit p-disabled"} countrySelectProps={{ unicodeFlags: true }} disabled onChange={() => { }} />
                        </div>
                        <div className="field col-4">
                            <label htmlFor="plano">Plano</label>
                            <Dropdown id="plano" value={subscription} options={subscriptions} itemTemplate={subscriptionOptionTemplate} onChange={onSubscription} optionLabel="name" optionValue="name" placeholder="--selecione--" disabled={profile !== 'Manager' && profile !== 'Single Player'} />
                        </div>
                        <div className="field col-4">
                            <label htmlFor="dataRenovacao">Data Renovação</label>
                            <Calendar id="dataRenovacao" value={subscriptionExpireAt} showTime showSeconds showIcon icon={<FontAwesomeIcon icon={solid('calendar-xmark')} />} disabled />
                        </div>
                        <div className="field col-4">
                            <label htmlFor="perfil">Perfil</label>
                            <Dropdown id="perfil" value={profile} options={profiles} onChange={onProfile} optionLabel="name" optionValue="name" placeholder="--selecione--" disabled={!selectedUser?.actived || user.profile !== "Owner"} />
                        </div>
                        <div className={team !== null ? "formgrid grid" : "hidden"}>
                            <Divider className={profile === 'Manager' ? "my-5" : "hidden"} align="center">
                                <span className="p-tag">Dados do Time</span>
                            </Divider>
                            <div className={profile === 'Manager' ? "field col" : "hidden"}>
                                <label htmlFor="time">Time</label>
                                <InputText htmlFor="time" defaultValue={team?.name} disabled />
                            </div>
                            <div className={profile === 'Manager' ? 'field col' : 'hidden'} >
                                <label htmlFor="jogadores">Jogadores</label>
                                <InputText htmlFor="jogadores" defaultValue={team?.numbersOfPlayers} disabled />
                            </div>
                            <div className={profile === 'Manager' ? 'field col' : 'hidden'} >
                                <label htmlFor="disponives">Jogadores disponíves</label>
                                <InputText htmlFor="disponives" defaultValue={team?.numbersOfAvailablePlayers} disabled />
                            </div>
                            <div className={profile === 'Manager' ? 'field col' : 'hidden'} >
                                <label htmlFor="ativos">Jogadores ativos</label>
                                <InputText htmlFor="ativos" defaultValue={team?.numbersOfActivedPlayers} disabled />
                            </div>
                        </div>
                        <div className={profile === 'Team Player' ? "formgrid grid" : "hidden"}>
                            <Divider className="my-5" align="center">
                                <span className="p-tag">Dados do Jogador</span>
                            </Divider>
                            <div className="field col-4">
                                <label htmlFor="time">Time</label>
                                <InputText htmlFor="time" defaultValue={team?.name} disabled />
                            </div>
                            <div className="field col-3">
                                <label htmlFor="grupoJogador">Grupo</label>
                                <InputText htmlFor="grupoJogador" disabled={user.profile !== 'Manager'} />
                            </div>
                            <div className="field col-2">
                                <label htmlFor="retaJogador">Reta</label>
                                <InputText htmlFor="retaJogador" defaultValue={selectedUser?.straights[0]?.line} disabled />
                            </div>
                            <div className="field col-2">
                                <label htmlFor="subretaJogador">Sub-Reta</label>
                                <InputText htmlFor="subretaJogador" defaultValue={selectedUser?.straights[0]?.sub} disabled />
                            </div>
                            <div className="field col-1">
                                <label htmlFor="dealJogador">Deal %</label>
                                <InputText htmlFor="dealJogador" disabled={user.profile !== 'Manager'} />
                            </div>
                        </div>
                    </div>
                </div>
            </Dialog>
            <Dialog visible={showDialogTeam} style={{ width: '20vw' }} onHide={onHideDialogTeam} showHeader={false} >
                <div className="m-4 p-fluid">
                    <div className="formgrid grid">
                        <div className="field col">
                            <label htmlFor="selecioneTime">Selecione um Time</label>
                            <Dropdown htmlFor="selecioneTime" value={team} options={teams} onChange={onTeam} optionLabel="name" optionValue="name" itemTemplate={teamsOptionTemplate} placeholder="--selecione--" />
                        </div>
                    </div>
                </div>
                <div className="flex justify-content-center flex-wrap">
                    <small>ou 'ESC' para cancelar</small>
                </div>
            </Dialog>
            <Dialog visible={showConfirmUnlinkStraight} style={{ width: '16vw' }} onHide={() => setShowConfirmUnlinkStraight(false)} footer={confirmUnlinkStraightFooter}>
                <div className="card p-fluid">
                    <div className="formgrid grid">
                        <div className="field col">
                            <label htmlFor="straightName">Confirme o nome para excluir</label>
                            <InputText id="straightName" value={straightName} onChange={(e) => setStraightName(e.target.value)} />
                            <small>
                                * Utilize o nome: <span className="font-bold text-red-500 select-all">{selectedUser?.straights[0]?.line}</span> para confirmar a exclusão da reta
                            </small>
                        </div>
                    </div>
                </div>
            </Dialog>
        </React.Fragment>
    );
};

export default UsersDialog