import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import React, { useCallback, useEffect, useState } from 'react';
import MyLobbyService from '../service/MyLobbyService';

import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DateTime } from 'luxon';
import { FilterMatchMode } from 'primereact/api';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { InputSwitch } from 'primereact/inputswitch';
import AppMesagens from '../AppMesagens';
import '../assets/phoneinput.styles.scss';
import { tratarExcecao } from '../helpers/core/util/ExceptionUtil';
import { isUndefined } from '../helpers/core/util/ObjectUtil';
import { teamImageTemplate } from '../helpers/core/util/TeamUtil';
import { useAuth } from '../hooks/useAuth';
import { subscriptionType } from './SubscriptionType';
import TastinDaysDialog from './TastinDaysDialog';
import UsersDialog from './UsersDialog';
import { classNames } from 'primereact/utils';

const UsersAccount = () => {
    const { user } = useAuth();
    const [users, setUsers] = useState([{}]);

    const [editUserDialog, setEditUserDialog] = useState(false);
    const [rows, setRows] = useState(10);
    const [totalRecords, setTotalRecords] = useState(0);
    const [editTastinDaysDialog, setEditTastinDaysDialog] = useState(false);
    const [selectedUser, setSelectedUser] = useState();
    const [mensagens, setMensagens] = useState('');
    const [teams, setTeams] = useState([]);
    const [showConfirmDialogActiveUser, setShowConfirmDialogActiveUser] = useState(false);

    const [lazyState, setlazyState] = useState({
        page: 0,
        pageCount: 0,
        first: 0,
        rows: rows,
        sortField: null,
        sortOrder: null,
        multiSortMeta: [],
        filters: {
            global: { value: null, matchMode: FilterMatchMode.CONTAINS }
        },
        user: {
            email: user?.email,
            accessToken: user?.accessToken
        }
    });

    const [globalFilterValue, setGlobalFilterValue] = useState('');
    const [loading, setLoading] = useState(true);

    const loadLazyUsers = useCallback(async () => {
        setLoading(true);
        MyLobbyService.getUsers({ lazyEvent: JSON.stringify(lazyState) })
            .then((response) => {
                return response.data;
            })
            .then((data) => {
                setUsers(data.users);
                setTotalRecords(data.totalUsers);
            })
            .catch((error) => {
                let mensagem = tratarExcecao(error);
                setMensagens(mensagem);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [lazyState]);

    const loadTeams = useCallback(async () => {
        MyLobbyService.getTeams({
            email: user?.email,
            accessToken: user?.accessToken
        })
            .then((response) => {
                return response.data;
            })
            .then((data) => {
                setTeams(data);
            })
            .catch((error) => {
                let mensagem = tratarExcecao(error);
                setMensagens(mensagem);
            })
            .finally(() => {
            });
    }, [user]);

    const saveUser = useCallback(async (_user) => {
        setLoading(true);
        let user_ = {
            email: _user?.email,
            actived: _user?.actived,
            profile: _user?.profile,
            teamName: _user?.teamName,
            subscription: _user?.subscription?.name,
            tastinDays: _user?.tastinDays,
            user: {
                email: user?.email,
                accessToken: user?.accessToken
            }
        }
        MyLobbyService.patchUser(user_)
            .then((response) => {
                return response.data;
            })
            .then((data) => {
            })
            .catch((error) => {
                let mensagem = tratarExcecao(error);
                setMensagens(mensagem);
            })
            .finally(() => {
                loadLazyUsers();
                setLoading(false);
            });
    }, [loadLazyUsers, user]);

    useEffect(() => {
        loadLazyUsers();
        loadTeams();
    }, [loadLazyUsers, loadTeams]);

    const onUserDeactived = () => {
        selectedUser.actived = false;
        selectedUser.subscription = selectedUser?.subscription?.name;
        saveUser(selectedUser);
    };

    const onFilterChange = (value) => {
        let _filters = { ...lazyState.filters };

        let global = _filters.global;
        global.value = value;
        _filters.global = global;
        lazyState.filters = _filters;
    };

    const onGlobalFilterChange = (event) => {
        const value = event.target.value;
        setGlobalFilterValue(value);
        onFilterChange(value);
        setLoading(true);
        setlazyState(lazyState);
        loadLazyUsers();
    };

    const onPage = (event) => {
        event.filters = { global: lazyState.filters.global };
        event.filtersMenu = lazyState.filtersMenu;
        event.user = {
            email: user?.email,
            accessToken: user?.accessToken
        };
        setLoading(true);
        setRows(event.rows);
        setlazyState(event);
    };

    const onSort = (event) => {
        event.page = lazyState.page;
        event.pageCount = lazyState.pageCount;
        event.filters = { global: lazyState.filters.global };
        event.filtersMenu = lazyState.filtersMenu;
        event.user = {
            email: user?.email,
            accessToken: user?.accessToken
        };
        setLoading(true);
        setRows(event.rows);
        setlazyState(event);
    };

    const onFilter = (event) => {
        event['first'] = 0;
        event.user = {
            email: user?.email,
            accessToken: user?.accessToken
        };
        setLoading(true);
        setlazyState(event);
    };

    const onEditTastinDaysDialog = (selectedUser) => {
        setSelectedUser(selectedUser);
        setEditTastinDaysDialog(false);
        if (!isUndefined(selectedUser)) {
            saveUser(selectedUser);
        }
    };

    const onEditUserDialog = (selectedUser) => {
        setSelectedUser(selectedUser);
        setEditUserDialog(false);
        if (!isUndefined(selectedUser)) {
            saveUser(selectedUser);
        }
        loadLazyUsers();
    };

    const showConfirmDialogActive = (_user) => {
        if (_user.actived) {
            if (_user.team !== null) {
                setShowConfirmDialogActiveUser(true);
                setSelectedUser(_user);
                return;
            } else {
                _user.actived = false;
            }
        } else {
            _user.actived = true;
        }
        _user.subscription = _user?.subscription?.name;
        saveUser(_user);
    };

    const renderHeaderUsers = () => {
        return (
            <div className="flex justify-content-between align-items-center">
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Procurar" />
                </span>
                <div>
                    <span className="text-base">{user?.team !== null ? `${user?.team.numbersOfActivedPlayers} jogadore(s) ativo(s). Disponíveis ${user?.team.numbersOfAvailablePlayers} de ${user?.team.numbersOfPlayers}` : ''}</span>
                </div>
            </div>
        );
    };

    const renderMensagemDialog = () => {
        return (
            <div>
                <span> O jogador <span className="font-bold">{selectedUser?.name} </span>
                    não irá fazer mais parte do seu time.
                </span>
                <br /> <br />Deseja confirma?
            </div>
        );
    };

    const nameBodyTemplate = (user) => {
        const personalizedServices = user?.personalizedServices?.map((priorites) => {
            let icon = "";
            switch (priorites.nameService) {
                case "Priorities":
                    icon = "pi pi-verified"
                    break;
                case "Blinds Entry":
                    icon = "pi pi-database"
                    break;
                default:
                    icon = "pi pi-question-circle"
                    break;
            }
            icon = classNames("ml-2", icon, "text-yellow-300");
            return {
                icon,
                name: priorites.nameService
            }
        });
        return (
            <React.Fragment>
                <span> {user.name} {personalizedServices?.map((item, i) => {
                    return (<i key={item.name || i} className={item.icon} title={item.name} />)
                })}
                </span>
            </React.Fragment>
        );
    }

    const planoBodyTemplate = (user) => {
        const subscription = user.subscription == null ? "-" : user.subscription.name;
        return (
            <React.Fragment>
                <span> {subscription} </span>
            </React.Fragment>
        );
    };

    const planoExpiraEmBodyTemplate = (user) => {
        const dataParaExpira = user?.subscriptionExpireAt !== null ? DateTime.fromISO(user.subscriptionExpireAt) : null;
        const diasPraExpirar = user?.subscriptionExpiresInDays === null ? "" : user.subscriptionExpiresInDays < 0 ? " (plano expirado)" : user.subscriptionExpiresInDays === 0 ? " (expira hoje)" : " resta " + user.subscriptionExpiresInDays + " dia(s)";
        return (
            <React.Fragment>
                <span>{dataParaExpira !== null ? dataParaExpira.toFormat("dd/MM/yyyy") + ` às ` + dataParaExpira.toFormat("T") : "-"} </span>
                <span className="font-semibold"> {diasPraExpirar} </span>
            </React.Fragment>
        );
    };

    const profileBodyTemplate = (user) => {
        return (
            <div>
                {subscriptionType(user.profileCard)}
            </div>
        );
    };

    const activedBodyTemplate = (user) => {
        return (
            <React.Fragment>
                <FontAwesomeIcon icon={user.actived ? solid("user-check") : solid("user-xmark")} className={user.actived ? "text-indigo-400" : "text-red-400"} />
            </React.Fragment>
        );
    };

    const countryBodyTemplate = (user) => {
        const countryCode = user.phoneNumberRegion !== null ? <img alt='flag' src='assets/demo/flags/flag_placeholder.png' className={`flag flag-${user?.phoneNumberRegion?.toLowerCase()}`} width={30} /> : "-";
        return (
            <React.Fragment>
                {countryCode}
            </React.Fragment>
        );
    };

    const teamBodyTempalte = (user) => {
        return teamImageTemplate(user);
    };

    const ultimoAcessoBodyTemplate = (user) => {
        const dataUltimoAcesso = user.lastAccess !== null && DateTime.fromISO(user.lastAccess).isValid ? DateTime.fromISO(user.lastAccess) : null;
        return (
            <React.Fragment>
                <span>{dataUltimoAcesso !== null ? dataUltimoAcesso.toFormat("dd/MM/yyyy") + ` às ` + dataUltimoAcesso.toFormat("T") : "-"} </span>
            </React.Fragment>
        );
    };

    const conectadoBodyTemplate = (user) => {
        const conectado = user.connected;
        return (
            <React.Fragment>
                <span>{conectado ? <span className="material-symbols-outlined text-2xl text-green-400" title="Online">person</span> : <span className="material-symbols-outlined text-2xl text-red-400" title="Offline">person_off</span>}</span>
            </React.Fragment>
        );
    };

    const registrouEmBodyTemplate = (user) => {
        const dataRegistro = user.registerDate !== null ? DateTime.fromISO(user.registerDate) : null;
        return (
            <React.Fragment>
                <span>{dataRegistro !== null ? dataRegistro.toFormat("dd/MM/yyyy") : "-"} </span>
            </React.Fragment>
        );
    };

    const editUser = (user) => {
        setEditUserDialog(true);
        setSelectedUser(user);
    };

    const editTastinDays = (user) => {
        setEditTastinDaysDialog(true);
        setSelectedUser(user);
    };

    const editBodyTemplate = (user) => {
        return (
            <React.Fragment>
                <div className="hidden sm:flex flex align-items-center">
                    <Button icon={<FontAwesomeIcon icon={solid('pen-to-square')} />} className="p-button-text p-button-primary" onClick={() => editUser(user)} />
                    <Button icon={<FontAwesomeIcon icon={regular('calendar-plus')} />} className="p-button-text p-button-warning mr-2" onClick={() => editTastinDays(user)} disabled={user.profileCard !== 'fplayer'} />
                    {/* <InputSwitch checked={user.actived} onChange={(e) => onUserActived(user, e.value)} /> */}
                    <InputSwitch checked={user.actived} onChange={() => showConfirmDialogActive(user)} />
                </div>
            </React.Fragment>
        );
    };

    return (
        <React.Fragment>
            {mensagens !== '' ? <AppMesagens mensagens={mensagens} position="bottom-center" /> : ''}
            <div className="card">
                <DataTable
                    value={users}
                    size="small"
                    lazy
                    dataKey="email"
                    paginator
                    paginatorPosition="both"
                    header={renderHeaderUsers}
                    first={lazyState.first}
                    rows={rows}
                    rowsPerPageOptions={[5, 10, 25, 50]}
                    totalRecords={totalRecords}
                    onPage={onPage}
                    onSort={onSort}
                    sortField={lazyState.sortField}
                    sortOrder={lazyState.sortOrder}
                    removableSort
                    onFilter={onFilter}
                    filters={lazyState.filters}
                    loading={loading}
                    className="p-datatable-striped"
                    stripedRows
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="Total de {totalRecords} jogadores"
                >
                    <Column body={editBodyTemplate}></Column>
                    <Column field="name" header="Nome" body={nameBodyTemplate} sortable />
                    <Column field="email" header="E-mail" />
                    <Column field="subscription.name" header="Plano" body={planoBodyTemplate} sortable sortField="subscriptionName" />
                    <Column field="subscriptionExpireAt" header="Plano expira em" body={planoExpiraEmBodyTemplate} sortable />
                    <Column field="profile" header="Perfil" body={profileBodyTemplate} sortable/>
                    <Column field="actived" header="Status" body={activedBodyTemplate} sortable/>
                    <Column field="phoneNumberRegion" header="País" body={countryBodyTemplate} />
                    <Column field="idteam.name" header="Time" body={teamBodyTempalte} sortable sortField="teamName" />
                    <Column field="lastAccess" header="Último acesso" body={ultimoAcessoBodyTemplate} />
                    <Column field="connected" header="Conectado" body={conectadoBodyTemplate} className="text-center" sortable />
                    <Column field="register" header="Registrou em" body={registrouEmBodyTemplate} sortable sortField="incluidoEm" />
                </DataTable>
            </div>
            <UsersDialog showDialog={editUserDialog} hideDialog={onEditUserDialog} selectedUser={selectedUser} teams={teams} />
            <TastinDaysDialog showDialog={editTastinDaysDialog} hideDialog={onEditTastinDaysDialog} selectedUser={selectedUser} />
            <ConfirmDialog visible={showConfirmDialogActiveUser} style={{ width: '20vw' }} onHide={() => setShowConfirmDialogActiveUser(false)} closable={false}
                message={renderMensagemDialog} header={"Confirmação"} icon="pi pi-exclamation-triangle" accept={onUserDeactived} />
        </React.Fragment>
    );
};

export default UsersAccount;
