import { InputNumber } from "primereact/inputnumber"
import React from "react"
import { formatValueByMinAndMax } from "../../helpers/GetValueEntryMinOrMax"

const MIN_INPUT_NUMBER_VALUE = 1
const MAX_INPUT_NUMBER_VALUE = 6

const ThreeStepForm = ({ setActiveIndex, activeIndex, dispatch, state }) => {

    const handleTextSite = (inputProps, value) => {
        dispatch({
            type: 'change-structure-punctuation',
            field: inputProps.target.name,
            payload: formatValueByMinAndMax(
                value,
                MIN_INPUT_NUMBER_VALUE,
                MAX_INPUT_NUMBER_VALUE
            ),
        })
    }

    /*const handleNextStepForm = e => {
        e.preventDefault();
        setActiveIndex(activeIndex + 1)
    }

    const backStepForm = () => {
        setActiveIndex(activeIndex - 1)
    }*/

    return (
        <div className="mb-8">
            <h4 className="mb-6">Qual pontuação para Estrutura de Torneio? (1 a 6 pontos)</h4>
            <div className="mb-2">
                <div className="field grid">
                    <label className="col-fixed" style={{ width: "14%" }}>Regular (no-bounty)</label>
                    <div className="col-2 md:col-2 p-inputgroup">
                        <InputNumber
                            min={MIN_INPUT_NUMBER_VALUE}
                            max={MAX_INPUT_NUMBER_VALUE}
                            required
                            placeholder={`${MIN_INPUT_NUMBER_VALUE} à ${MAX_INPUT_NUMBER_VALUE} pontos`}
                            suffix=" pontos"
                            name="regular"
                            value={state.structures.regular}
                            onChange={e => handleTextSite(e.originalEvent, e.value)}
                        />
                        <span className="p-inputgroup-addon text-gray-900 bg-yellow-300 border-yellow-300">
                            <i className="pi pi-sort-amount-up"></i>
                        </span>
                    </div>
                </div>
                <div className="field grid">
                    <label className="col-fixed" style={{ width: "14%" }}>Bounty</label>
                    <div className="col-2 md:col-2 p-inputgroup">
                        <InputNumber
                            min={MIN_INPUT_NUMBER_VALUE}
                            max={MAX_INPUT_NUMBER_VALUE}
                            required
                            placeholder={`${MIN_INPUT_NUMBER_VALUE} à ${MAX_INPUT_NUMBER_VALUE} pontos`}
                            suffix=" pontos"
                            name="bounty"
                            value={state.structures.bounty}
                            onChange={e => handleTextSite(e.originalEvent, e.value)}
                        />
                        <span className="p-inputgroup-addon text-gray-900 bg-yellow-300 border-yellow-300">
                            <i className="pi pi-sort-amount-up"></i>
                        </span>
                    </div>
                </div>

            </div>

            {/* <div className="flex gap-2 justify-content-end">
                <Button
                    type="button"
                    label="Voltar"
                    className="p-button-outlined border-yellow-300 text-yellow-300"
                    onClick={() => backStepForm()}
                />
                <Button
                    type="submit"
                    label="Próximo"
                    className="bg-yellow-300 border-yellow-300 text-gray-900"
                />
            </div> */}
        </div>
    );
};

export default ThreeStepForm
