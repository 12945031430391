import { Toast } from 'primereact/toast'
import React, { useEffect, useRef } from 'react'
import MensagemLista from './helpers/core/message/MensagemLista'

const AppMesagens = (props) => {
  const toast = useRef();

  const showMessage = (mensagens) => {
    toast.current.clear();
    if (mensagens instanceof MensagemLista) {
      mensagens = mensagens.mensagens
    }
    toast.current.show(
      mensagens?.map((mensagem) => {
        return {
          sticky: false,
          severity: mensagem.tipoMensagem,
          summary: mensagem.resumo,
          detail: mensagem.detalhe,
          closable: true,
          life: 5000
        };
      })
    );
  };

  useEffect(() => {
    showMessage(props.mensagens)
  }, [props?.mensagens])

  return (
    <div>
      <Toast ref={toast} position={props?.position} className="z-5" />
    </div>
  )
}

export default AppMesagens
