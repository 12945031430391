import { isUndefined } from '../util/ObjectUtil'

export default class MensagemLista {
  constructor () {
    this.mensagens = []
  }

  fullMensagem (detalhe, resumo, argumentos, tipoMensagem) {
    const mensagem = {
      detalhe: !isUndefined(detalhe) ? detalhe : null,
      resumo: !isUndefined(resumo) ? resumo : null,
      argumentos: !isUndefined(argumentos) ? argumentos : [],
      tipoMensagem: tipoMensagem?.severity
    }
    this.mensagens.push(mensagem)
  };

  simpleMensagem (detalhe, tipoMensagem) {
    const mensagem = {
      detalhe: !isUndefined(detalhe) ? detalhe : null,
      resumo: tipoMensagem?.summary,
      tipoMensagem: tipoMensagem?.severity
    }
    this.mensagens.push(mensagem)
  };
}
