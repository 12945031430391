import React from 'react';
import { Avatar } from 'primereact/avatar';
import { InfoComponent } from './PlayerInfoData';
import { Divider } from 'primereact/divider';

export const PlayerTeamData = ({ user }) => {
    return (
        <div className="w-full bg-gray-900 h-[100%] p-4 border-round">
            <p className="text-xl font-bold">Dados de Time</p>
            <Divider />
            <div className="flex flex-column gap-2">
                <div className="flex align-items-center gap-4">
                    <Avatar
                        label={user.initialLetterName}
                        image="https://cdn.discordapp.com/attachments/1160251800369238136/1163862466581766276/image.png?ex=65411e63&is=652ea963&hm=c91aa34f24d4e99e7258d70a03fa64188ca8c3632023b5bd3b49bcd6b7405e66&"
                        className="text-white h-8rem w-8rem align-self-center"
                        shape="circle"
                    />
                    <h3>Like a Boss Team</h3>
                </div>

                <div className="flex gap-4 flex-column">
                    <InfoComponent isHome label="Grupo" text={user.teamName ?? 'N/A'} />
                    <div className="flex gap-2">
                        <InfoComponent isHome reta label="Reta" text={user.teamName ?? 'N/A'} />
                        <InfoComponent isHome reta label="Sub." text={user.teamName ?? 'N/A'} />
                        <InfoComponent isHome reta label="Deal" text={user.teamName ?? 'N/A'} />
                    </div>
                </div>
            </div>
        </div>
    );
};
