import { useInterval } from 'primereact/hooks'
import React, { createContext, useCallback, useContext, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { isArrayEmpty } from '../helpers/core/util/ObjectUtil'
import MyLobbyService from '../service/MyLobbyService'
import { getValue, useLocalStorage } from './useLocalStorage'

const AuthContext = createContext()

export const AuthProvider = ({ children, userData }) => {
    const [user, setUser] = useLocalStorage('user', userData);
    const [active, setActive] = useState(false);
    const [connectedWs, setConnectedWs] = useState(false);
    const navigate = useNavigate();

    // eslint-disable-next-line
    const login = useCallback(async (data) => {
        setActive(true);
        setConnectedWs(false);
        profile(data);
        navigate('/', { replace: true });
    });

    const logout = () => {
        setUser({});
        navigate('/login', { replace: true });
        disableValidation();
        setConnectedWs(true);
    };

    const disableValidation = () => {
        setActive(false);
    };

    const enableConnectionWs = () => {
        setConnectedWs(false);
    }

    const getProfile = (data) => {
        let _defaultPermission = data?.profileCard === 'owner' || data?.profileCard === 'manager' || data?.profileCard === 'support';
        let _ownerAndSupport = data?.profileCard === 'owner' || data?.profileCard === 'support';
        let _isTeam = data?.profileCard === 'tplayer' || data?.profileCard === 'manager';
        let blindsEntryFilter = !isArrayEmpty(data?.personalizedServices?.filter((personalizedService) => personalizedService.nameService === 'Blinds Entry' && personalizedService.actived));
        return {
            filter: true,
            straightFilter: !_defaultPermission,
            prioritiesFilter: _ownerAndSupport,
            blindsEntryFilter,
            mainMenu: true,
            adminMenu: _defaultPermission,
            playersMenu: _defaultPermission,
            settingsMenu: _ownerAndSupport,
            tableSelect: true,
            tableSelectStraightSub: _defaultPermission,
            tableSelectStraight: _defaultPermission,
            tableSelectDelete: _ownerAndSupport,
            tableSelectEdit: _ownerAndSupport,
            tableSelectDeleteAll: _ownerAndSupport,
            tableSelectAlarm: true,
            tableSelectFavorite: true,
            tableSelectBlindsEntry: true,
            tableSelectLateRegister: true,
            forTeam: _isTeam
        };
    };

    const profile = (data) => {
        let _data = { ...data }
        let usersView = getProfile(_data);
        _data.usersView = usersView;
        setUser(_data);
    };

    // eslint-disable-next-line
    const userMemo = useMemo(() => ({
        user,
        login,
        connectedWs,
        logout,
        disableValidation,
        enableConnectionWs,
        profile
    }));

    const validaSiteToken = useCallback(async () => {
        MyLobbyService.postValidateToken({
            email: user?.email,
            accessToken: user?.accessToken
        }).then((response) => {
            return response.data;
        }).then((data) => {
            let _user = getValue('user');
            _user = JSON.parse(_user);
            if (data?.accessToken === null || Reflect.ownKeys(_user).length === 0 || data?.accessToken !== _user.accessToken) {
                //logout();
                setUser({});
                setActive(false);
                navigate('/login', { replace: true });
            }
            //refreshUserData(data);
        }).catch((error) => {
            setUser({});
            setActive(false);
            navigate('/login', { replace: true });
            //logout();
        });
    }, [user, setUser, navigate]);

    /*const refreshUserData = (data) => {
        if (user !== null) {
            let _at = user.at;
            let dateTime = DateTime.fromMillis(_at);
            let timeWithMinutes = dateTime.plus({ minutes: 5 });
            timeWithMinutes = timeWithMinutes.diff(DateTime.now(), 'minutes');
            if (timeWithMinutes.values?.minutes <= 0) {
                profile(data);
            }
        }
    };*/

    useInterval(validaSiteToken, 30000, active);

    return <AuthContext.Provider value={userMemo}>{children}</AuthContext.Provider>
}

export const useAuth = () => {
    return useContext(AuthContext)
}
