import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'primereact/button';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { Dialog } from 'primereact/dialog';
import React, { useState } from 'react';

const TastinDaysDialog = ({ showDialog, hideDialog, selectedUser }) => {
    const [confirmDegustacaoDialog, setConfirmDegustacaoDialog] = useState(false);
    const [diasDegustacao, setDiasDegustacao] = useState(null);
    const [mensagemConfirmacaoDegustacao, setMensagemConfirmacaoDegustacao] = useState("");

    const confirmaDegustacao = () => {
        setConfirmDegustacaoDialog(false);
        let _player = {
            ...selectedUser,
            tastinDays: diasDegustacao
        }
        hideDialog(_player);
    };

    const onDiasDegustacao = (dias) => {
        setDiasDegustacao(dias);
        setConfirmDegustacaoDialog(true);
        const mensagem = `Confirmar ${dias} dia(s) de degustação?`;
        setMensagemConfirmacaoDegustacao(mensagem);
    };

    return (
        <React.Fragment>
            <Dialog header="Oferecer Degustação" visible={showDialog} style={{ width: '25vw' }} onHide={hideDialog} >
                <span className="text-base">Ofereça à <span className="font-semibold">{selectedUser?.name}</span> mais alguns dias de Degustação!</span>
                <div className="flex justify-content-center flex-wrap gap-2 mt-4">
                    {selectedUser?.subscriptionExpiresInDays <= 0 || selectedUser?.subscriptionExpiresInDays === null ?
                        <div>
                            <Button label="7 Dias" icon={<FontAwesomeIcon icon={regular('calendar')} className="mr-2" />} className="p-button-text p-button-warning" onClick={() => onDiasDegustacao(7)} />
                            <Button label="15 Dias" icon={<FontAwesomeIcon icon={solid('calendar-days')} className="mr-2" />} className="p-button-text p-button-info" onClick={() => onDiasDegustacao(15)} />
                            <Button label="30 Dias" icon={<FontAwesomeIcon icon={solid('calendar')} className="mr-2" />} className="p-button-text p-button-success" onClick={() => onDiasDegustacao(30)} />
                        </div>
                        : <div>
                            <span className="text-base">Jogador ainda não habilitado. Aguarde o termino da degustação!</span>
                        </div>}
                </div>
            </Dialog>
            <ConfirmDialog
                visible={confirmDegustacaoDialog}
                onHide={() => setConfirmDegustacaoDialog(false)}
                message={mensagemConfirmacaoDegustacao}
                header="Confirmar Degustação"
                accept={confirmaDegustacao}
                reject={() => setConfirmDegustacaoDialog(false)}
                icon="pi pi-exclamation-triangle"
            />
        </React.Fragment>
    )
}

export default TastinDaysDialog;