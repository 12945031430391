import { InputNumber } from "primereact/inputnumber";
import { ProgressSpinner } from 'primereact/progressspinner';
import React, { useCallback, useEffect, useState } from "react";
import { formatValueByMinAndMax } from "../../helpers/GetValueEntryMinOrMax";
import { tratarExcecao } from "../../helpers/core/util/ExceptionUtil";
import MyLobbyService from "../../service/MyLobbyService";
import AppMesagens from "../../AppMesagens";

const MIN_INPUT_NUMBER_VALUE = 1
const MAX_INPUT_NUMBER_VALUE = 6

const OneStepForm = ({ dispatch, state }) => {

    const [mensagens, setMensagens] = useState('');
    const [network, setNetwork] = useState([]);

    const loadSites = useCallback(async () => {
        MyLobbyService.getNetworks()
            .then((response) => {
                return response.data;
            })
            .then((data) => {
                data = data.map((e) => {
                    return { ...e, isClicked: false, className: 'surface-600' };
                });
                let sites = data?.map(site => {
                    return {
                        name: site?.name,
                        nickName: site?.nickName
                    }
                });
                setNetwork(sites);
            })
            .catch((error) => {
                let mensagem = tratarExcecao(error);
                setMensagens(mensagem);
            });
    }, []);

    const handleTextSite = inputProps => {
        dispatch({
            payload: inputProps,
            type: 'change-site-punctuation'
        })
    }

    const getSiteByFormState = (name) => {
        let value = ''
        value = state?.networks?.collection?.find(siteState => {
            return siteState.name === name
        })?.value
        return value ?? ''
    }

    useEffect(() => {
        loadSites();
    }, [loadSites]);

    return (
        <>
            {mensagens !== '' ? <AppMesagens mensagens={mensagens} position="bottom-center" /> : ''}
            <div className="mb-8">
                <h4 className="mb-6">Qual pontuação para cada site? (1 a 6 pontos)</h4>
                <div style={{
                    display: 'grid',
                    gap: 16,
                    gridTemplateColumns: 'repeat(3, 1fr)',
                }}>
                    {
                        network.length ? network?.map(net => {
                            return (
                                <div
                                    key={net.name}
                                    className="flex gap-2 align-items-center"
                                >
                                    <img
                                        alt={net.name}
                                        src={`assets/layout/images/network/${net.nickName}.png`}
                                        style={{
                                            width: '38px',
                                            height: '38px',
                                        }}
                                    ></img>
                                    <div className="p-inputgroup" style={{ maxWidth: 200 }}>
                                        <InputNumber
                                            min={MIN_INPUT_NUMBER_VALUE}
                                            max={MAX_INPUT_NUMBER_VALUE}
                                            suffix=" pontos"
                                            placeholder={`${MIN_INPUT_NUMBER_VALUE} à ${MAX_INPUT_NUMBER_VALUE} pontos`}
                                            value={getSiteByFormState(net.name)}
                                            onChange={e => handleTextSite({
                                                name: net.name,
                                                value: formatValueByMinAndMax(e.value, 1, MAX_INPUT_NUMBER_VALUE),
                                                nickName: net.nickName
                                            })}
                                        />
                                        <span className="p-inputgroup-addon text-gray-900 bg-yellow-300 border-yellow-300">
                                            <i className="pi pi-sort-amount-up"></i>
                                        </span>
                                    </div>
                                </div>
                            )
                        }) : (
                            <div
                                className="flex justify-content-center"
                                style={{
                                    gridColumnStart: 1,
                                    gridColumnEnd: 4
                                }}
                            >
                                <ProgressSpinner />
                            </div>
                        )
                    }
                </div>

            </div>
        </>
    );
};

export default OneStepForm
