import { Button } from 'primereact/button'
import React, { useCallback, useEffect, useState } from 'react'
import AppMesagens from '../AppMesagens'
import { tratarExcecao } from '../helpers/core/util/ExceptionUtil'
// import { isArrayEmpty } from '../helpers/core/util/ObjectUtil';
import MyLobbyService from '../service/MyLobbyService'

const FilterNetwork = (props) => {
  const [loading, setLoading] = useState(false)
  const [mensagens, setMensagens] = useState('')
  const [network, setNetwork] = useState([])

  const loadSites = useCallback(async () => {
    setLoading(true)
    MyLobbyService.getNetworks()
      .then((response) => {
        return response.data
      })
      .then((data) => {
        data = data.map((e) => {
          return { ...e, isClicked: false, className: 'surface-600' }
        })
        setNetwork(data)
        setLoading(false)
      })
      .catch((error) => {
        const mensagem = tratarExcecao(error)
        setMensagens(mensagem)
        setLoading(false)
      })
  }, [])

  useEffect(() => {
    loadSites()
  }, [loadSites])

  const onClick = (event) => {
    const filterValueSelect = event.currentTarget.value
    const _filterNetwork = network?.map((network) => {
      if (network.name === filterValueSelect) {
        network.isClicked = !network.isClicked
        network.className = network.isClicked ? 'bg-red-300' : 'surface-600'
      }
      return network
    })
    setNetwork(_filterNetwork)
    const networks = []
    network?.forEach((net) => {
      if (net.isClicked) {
        networks.push(net.name)
      }
    })
    // networks = { field: props.filterByField, value: networks };
    props.handlerFilterValue(networks, props.filterByField)
  }

  const buttonClassName = (network) => {
    const classNameDefault = 'ml-1 mb-1 hover:bg-red-300 surface-border '
    return classNameDefault.concat(network?.className)
  }

    return (
        <>
            {mensagens !== '' ? <AppMesagens mensagens={mensagens} position="bottom-center" /> : ''}
            {network.map((net) => (
                <Button key={net.name} value={net.name} onClick={onClick} className={buttonClassName(net)} loading={loading}>
                    <img alt={net.name} src={`assets/layout/images/network/${net.nickName}.png`} style={{ width: '38px', height: '38px' }}></img>
                </Button>
            ))}
        </>
    );
};

export default FilterNetwork
