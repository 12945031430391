import { ProgressBar } from 'primereact/progressbar'
import { Suspense, useRef } from 'react'
import { Await, useLoaderData, useOutlet } from 'react-router-dom'
import { AuthProvider } from '../hooks/useAuth'
import { Messages } from 'primereact/messages'

export const AuthLayout = () => {
  const outlet = useOutlet()
  const msgs = useRef([
    { severity: 'error', summary: 'Error', detail: 'Something went wrong!', sticky: true, closable: false }
  ])
  const { userPromise } = useLoaderData()

  return (
    <Suspense fallback={<ProgressBar mode='indeterminate' style={{ height: '6px' }} />}>
      <Await
        resolve={userPromise}
        errorElement={<Messages ref={msgs} />}
        children={() => (
          <AuthProvider userData={{}}>{outlet}</AuthProvider>
        )}
      />
    </Suspense>
  )
}
