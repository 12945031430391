import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import React, { useCallback, useEffect, useState } from 'react';
import AppMesagens from '../AppMesagens';
import { tratarExcecao } from '../helpers/core/util/ExceptionUtil';
import { isArrayEmpty } from '../helpers/core/util/ObjectUtil';
import { useAuth } from '../hooks/useAuth';
import MyLobbyService from '../service/MyLobbyService';

const PrioritiesPlayers = ({ showDialog, hideDialog, editMode }) => {
    const { user } = useAuth();
    const [mensagens, setMensagens] = useState('');

    const [personalizedServices, setPersonalizedServices] = useState([]);
    const [selectedPlayer, setSelectedPlayer] = useState(null);

    const loadPlayers = useCallback(async () => {
        MyLobbyService.getUsersPersonalizedServices({
            email: user?.email,
            accessToken: user?.accessToken
        })
            .then((response) => {
                return response.data;
            })
            .then((data) => {
                const _data = data.map((prioritie) => {
                    return !isArrayEmpty(prioritie.personalizedServices?.filter((personalizedService) => personalizedService.nameService === 'Priorities' && personalizedService.actived))
                        ? {
                            login: prioritie.login,
                            email: prioritie.email,
                            name: prioritie.name,
                        } : []
                });
                setPersonalizedServices(_data);
            })
            .catch((error) => {
                let mensagem = tratarExcecao(error);
                setMensagens(mensagem);
            })
            .finally(() => {
            });
    }, [user]);

    useEffect(() => {
        loadPlayers();
    }, [loadPlayers]);

    const onPlayerSelected = (e) => {
        setSelectedPlayer(e.value);
    };

    const savePriorities = () => {
        hideDialog(selectedPlayer);
    }

    const playersDialogFooter = () => {
        return (
            <React.Fragment>
                {!editMode && <Button label="Salvar" icon="pi pi-check" onClick={savePriorities} className="bg-yellow-300 border-yellow-300 text-gray-900" />}
                {editMode && <Button label="Selecionar" icon="pi pi-check" onClick={savePriorities} className="bg-yellow-300 border-yellow-300 text-gray-900" />}
            </React.Fragment>
        );
    };

    const personalizedServicesOptionTemplate = (option) => {
        if (isArrayEmpty(option)) {
            return (
                <span>*Nenhum jogador habilitado</span>
            );
        }
        return (
            <div>{option.name} ({option.email})</div>
        );
    }

    return (
        <React.Fragment>
            {mensagens !== '' ? <AppMesagens mensagens={mensagens} position="bottom-center" /> : ''}
            <Dialog visible={showDialog} style={{ width: '20vw' }} onHide={hideDialog} footer={playersDialogFooter} showHeader={false} >
                <div className="mt-6 p-fluid">
                    <div className="formgrid grid">
                        <div className="field col">
                            <Dropdown value={selectedPlayer} options={personalizedServices}
                                itemTemplate={personalizedServicesOptionTemplate}
                                onChange={onPlayerSelected} optionValue="email" optionLabel="email" filter showClear filterBy="login,email" />
                            <small>Para continuar, escolha um jogador. <br />Ou 'ESC' para cancelar</small>
                        </div>
                    </div>
                </div>
            </Dialog>
        </React.Fragment>
    );
};

export default PrioritiesPlayers