export const removeDuplicatesByProperty = (arr, property = 'id') => {
    const uniqueObjects = {};
    const result = [];

    for (const obj of arr) {
      const key = obj[property];
      if (!uniqueObjects[key]) {
        uniqueObjects[key] = true;
        result.push(obj);
      }
    }

    return result;
  }
