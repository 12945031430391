import { Button } from 'primereact/button'
import { useEventListener } from 'primereact/hooks'
import { InputText } from 'primereact/inputtext'
import { Password } from 'primereact/password'
import React, { useCallback, useEffect, useState } from 'react'
import { Navigate, useNavigate, useSearchParams } from 'react-router-dom'
import AppMesagens from '../AppMesagens'
import EnumTipoMensagem from '../helpers/core/enumerator/EnumTipoMensagem'
import MensagemLista from '../helpers/core/message/MensagemLista'
import { tratarExcecao } from '../helpers/core/util/ExceptionUtil'
import { useAuth } from '../hooks/useAuth'
import MyLobbyService from '../service/MyLobbyService'

export const Login = (props) => {
  const { user, login, connectedWs, enableConnectionWs } = useAuth();
  const [username, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const [mensagens, setMensagens] = useState('');
  const [loading, setLoading] = useState(false);
  const [bindKeyDown, unbindKeyDown] = useEventListener({
    type: 'keydown',
    listener: (e) => {
      if (e.code === 'Enter' || e.code === 'NumpadEnter') {
        validateAuthentication(e)
      }
    }
  });
  const navigate = useNavigate();
  const [queryParameters] = useSearchParams();

  useEffect(() => {
    bindKeyDown();

    return () => {
      unbindKeyDown();
    }
  }, [bindKeyDown, unbindKeyDown]);

  const validateAuthentication = useCallback(async (e) => {
    e.preventDefault();
    setLoading(true);
    MyLobbyService.postLogin({
      email: username,
      senha: password
    }).then((response) => {
      return response.data;
    }).then((data) => {
      setLoading(false);
      setMensagens('');
      login({
        at: data.accessToken?.split('-', 1)[0],
        ...data
      });
    }).catch((error) => {
      const mensagem = tratarExcecao(error);
      setMensagens(mensagem);
      setLoading(false);
    });
  }, [username, password, login]);

  useEffect(() => {
    if (queryParameters.get('tokenValid')) {
      const mensagem = new MensagemLista();
      const tokenValid = queryParameters.get('tokenValid');
      if (tokenValid === 'true') {
        mensagem.simpleMensagem('Utilize agora seu e-mail e senha', EnumTipoMensagem.INFO);
      } else {
        mensagem.simpleMensagem('Token expirado ou já validado', EnumTipoMensagem.ERRO);
      }
      setMensagens(mensagem);
    }
    if (connectedWs) {
      props.stompjs.deactivate();
      enableConnectionWs();
    }
  }, [queryParameters, props, connectedWs, enableConnectionWs]);

  if ((Reflect.ownKeys(user).length !== 0) && (user?.accessToken !== null) && ((user !== 'undefined'))) {
    return <Navigate to='/' replace />
  }

  return (
    <form autoComplete="on" onSubmit={validateAuthentication}>
      <div className='login-body'>
        {mensagens !== '' ? <AppMesagens mensagens={mensagens} position='bottom-center' /> : ''}
        <div className='login-wrapper'>
          <div className='login-panel gap-2'>
            <img src='assets/layout/images/logo.png' className='mb-5' alt='mylobby' width='250' />
            <div className='p-inputgroup'>
              <span className='p-inputgroup-addon bg-red-500 border-red-500'>
                <i className='pi pi-user text-white' />
              </span>
              <InputText name="login" placeholder='E-mail / Login' value={username} onChange={(e) => setUserName(e.target.value)} className='input-login-mylobby text-0 border-white' autoFocus autoComplete="username" />
            </div>
            <div className='p-inputgroup'>
              <span className='p-inputgroup-addon bg-red-500 border-red-500' style={{ height: '42px' }}>
                <i className='pi pi-key text-white' />
              </span>
              <Password name="password" placeholder='Senha' feedback={false} onChange={(e) => setPassword(e.target.value)} toggleMask className='border-white' autoComplete="password"/>
            </div>
            <Button label='Acessar' type="submit" loading={loading} />
            <button className='p-link forget-password text-white' type="button" onClick={(e) => { navigate('/retrieve_account', { replace: true }) }}>Não lembro minha senha</button>
            <div className="flex hidden md:block align-content-start flex-wrap gap-1 mt-6">
              {/* <p className='hidden md:block text-white'> */}
              <span className="text-white">Ainda não tem uma conta? </span><Button label="Cadastre-se" className='p-link text-red-400' link type="button" onClick={(e) => { navigate('/register_account', { replace: true }) }} />
              {/* </p> */}
            </div>
          </div>
          <div className='login-footer'>
            <h4 className='font-mylobby'>
              MY LO<span className='text-primary'>BB</span>Y
            </h4>
            <h6>Table select na sua mão</h6>
          </div>
          <h6 className='text-sm'>powered by <a href="https://mcp.tec.br" target="_blank" rel="noreferrer" className="text-color">MCP Tec.</a></h6>
        </div>
      </div>
    </form>
  )
}
