import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Avatar } from 'primereact/avatar';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Dialog } from 'primereact/dialog';
import { Divider } from 'primereact/divider';
import { InputText } from 'primereact/inputtext';
import React, { useCallback, useState } from 'react';
import AppMesagens from '../AppMesagens';
import EnumTipoMensagem from '../helpers/core/enumerator/EnumTipoMensagem';
import MensagemLista from '../helpers/core/message/MensagemLista';
import { tratarExcecao } from '../helpers/core/util/ExceptionUtil';
import { useAuth } from '../hooks/useAuth';
import MyLobbyService from '../service/MyLobbyService';

export const PlayerInfoCard = ({ isHome = false }) => {
    const { user, profile } = useAuth();

    const [mensagens, setMensagens] = useState('');
    const [editNameDialog, setEditNameDialog] = useState(false);
    const [completedName, setCompletedName] = useState('');

    const onChangeCompletedName = useCallback(async () => {
        const _user = {
            ...user,
            straights: null,
            settings: null,
            team: null,
            subscription: null,
            name: completedName,
            timeZone: {
                timeZoneFull: user.timeZoneFull,
                timeZoneGmt: user.timeZoneGmt
            }
        };
        MyLobbyService.patchUser(_user)
            .then((response) => {
                return response.data;
            })
            .then((data) => {
                profile(data);
                changeCompletedNameClean();
                const mensagem = new MensagemLista();
                mensagem.simpleMensagem('Nome alterado com sucesso', EnumTipoMensagem.INFO);
                setMensagens(mensagem);
            })
            .catch((error) => {
                let mensagem = tratarExcecao(error);
                setMensagens(mensagem);
            });
    }, [user, completedName, profile]);


    const changeCompletedNameClean = () => {
        setEditNameDialog(false);
        setCompletedName('');
    }

    return (
        <React.Fragment>
            {mensagens !== '' ? <AppMesagens mensagens={mensagens} position="bottom-center" /> : ''}
            <div className="flex md:flex-row flex-column align-items-center align-self-center xl:align-self-stretch w-fit">
                <div className="bg-gray-900 h-full w-screen md:w-18rem flex align-items-center flex-column p-4 border-round">
                    <p className="m-0 align-self-start  text-xl font-bold">Dados de Perfil</p>
                    <Divider />
                    <div className="flex align-items-center flex-column">
                        <Avatar label={user.initialLetterName} className="text-white h-8rem w-8rem" shape="circle" />

                        {/* {!isHome && <FileUpload mode="basic" accept="image/*" maxFileSize={1000000} chooseLabel="Alterar imagem" />} */}

                        <div className="flex align-items-center flex-wrap text-gray-400 mt-3">
                            {user.name}
                            {!isHome && <Button onClick={() => setEditNameDialog(true)} icon={<FontAwesomeIcon icon={solid('pen-to-square')} />} className="p-button-text p-button-primary" />}
                        </div>
                        <div className="flex align-content-center flex-wrap mt-2">
                            {/* <div className="flex align-items-stretch flex-wrap"> */}
                            <small className="text-gray-400 mr-2">({user.login})</small>
                            <i className="pi pi-info-circle" title="Seu login na MY LOBBY"></i>
                            {/* </div> */}
                        </div>

                        {user?.subscription && (<div className="bg-gray-900 border-round flex flex-column mt-4">
                            <div className="flex gap-2 align-items-center bg-gray-900 border-round">
                                <p className="m-0">Plano atual:</p>
                                <p className="m-0 text-red-400 uppercase font-bold">{user?.subscription?.name}</p>
                                {user?.subscription?.value > 0 && (<p className="font-bold bg-gray-800 w-4 p-2 ml-2 border-round">${user?.subscription?.value}</p>)}
                            </div>

                            <div className="flex mt-2">
                                <div>
                                    <p className="my-2">Seu plano expira em:</p>
                                    <Calendar value={new Date(user.subscriptionExpireAt)} disabled showTime dateFormat='dd/mm/yy às' />
                                </div>
                            </div>
                        </div>
                        )}
                    </div>
                </div>
            </div>
            <Dialog visible={editNameDialog} onHide={changeCompletedNameClean} showHeader={false}>
                <div className="flex gap-2 mt-5">
                    <InputText value={completedName} onChange={(e) => setCompletedName(e.target.value)} placeholder="Informe seu nome completo" autoFocus />
                    <Button label="Atualizar" icon="pi pi-check" onClick={onChangeCompletedName} disabled={completedName === ''} />
                </div>
                <div className="mt-2">
                    <small>
                        ou 'ESC' para cancelar
                    </small>
                </div>
            </Dialog>
        </React.Fragment>
    );
};
