export default class EnumSubscription {
    static #_OWNER = { name: "Owner", profile: "owner", icon: "user-tie", title: "Owner" }
    static #_MANAGER = { name: "Manager", profile: "manager", icon: "user-secret", title: "Gerente de Time" }
    static #_SUPPORT = { name: "Support", profile: "support", icon: "user-gear", title: "Suporte" }
    static #_MPLAYER = { name: "Single Player", profile: "mplayer", icon: "user-large", title: "Micro Player" }
    static #_LPLAYER = { name: "Single Player", profile: "lplayer", icon: "user-minus", title: "Low Player" }
    static #_HPLAYER = { name: "Single Player", profile: "hplayer", icon: "user-plus", title: "High Player" }
    static #_FPLAYER = { name: "Single Player", profile: "fplayer", icon: "user", title: "Free Player" }
    static #_TPLAYER = { name: "Team Player", profile: "tplayer", icon: "users", title: "Jogador de Time" }

    static get OWNER() { return this.#_OWNER }
    static get MANAGER() { return this.#_MANAGER }
    static get SUPPORT() { return this.#_SUPPORT }
    static get MPLAYER() { return this.#_MPLAYER }
    static get LPLAYER() { return this.#_LPLAYER }
    static get HPLAYER() { return this.#_HPLAYER }
    static get FPLAYER() { return this.#_FPLAYER }
    static get TPLAYER() { return this.#_TPLAYER }
}
