import EnumTipoMensagem from '../enumerator/EnumTipoMensagem'
import { isEmpty, isNull, isUndefined } from '../util/ObjectUtil'

const exception = (exception) => {
  if (isEmpty(exception?.response?.data)) {
    return [{ mensagem: exception?.status + ' - ' + exception?.statusText }]
  } else {
    return exception?.response?.data?.mensagens?.map((mensagem) => {
      const _mensagens = { ...mensagem, tipoMensagem: EnumTipoMensagem.ERRO.severity, resumo: isNull(mensagem?.resumo) ? EnumTipoMensagem.ERRO.summary : mensagem?.resumo }
      return _mensagens
    });
  }
}

export const tratarExcecao = (ex) => {
  if (isUndefined(ex?.response?.data)) {
    return [{ detalhe: ex?.message, tipoMensagem: EnumTipoMensagem.WARN.severity, resumo: EnumTipoMensagem.WARN.summary}]; 
  }
  return exception(ex);
}
