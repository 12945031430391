export default class EnumTipoMensagem {
  static #_SUCCESS = { severity: 'success', summary: 'Sucesso' }
  static #_INFO = { severity: 'info', summary: 'Info' }
  static #_WARN = { severity: 'warn', summary: 'Atenção' }
  static #_ERRO = { severity: 'error', summary: 'Erro' }

  static get ERRO() { return this.#_ERRO }
  static get INFO() { return this.#_INFO }
  static get WARN() { return this.#_WARN }
  static get SUCCESS() { return this.#_SUCCESS }
}
