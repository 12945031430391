import { InputNumber } from "primereact/inputnumber"
import React from "react"
import { formatValueByMinAndMax } from "../../helpers/GetValueEntryMinOrMax"

const MIN_INPUT_NUMBER_VALUE = 1
const MAX_INPUT_NUMBER_VALUE = 6

const FourStepForm = ({ setActiveIndex, activeIndex, dispatch, state }) => {

    const handleTextSite = (inputProps, value) => {
        dispatch({
            type: 'change-speed-punctuation',
            field: inputProps.target.name,
            payload: formatValueByMinAndMax(
                value,
                MIN_INPUT_NUMBER_VALUE,
                MAX_INPUT_NUMBER_VALUE
            ),
        })
    }

    return (
        <div className="mb-8">
            <h4 className="mb-6">Qual pontuação para Velocidade? (1 a 6 pontos)</h4>
            <div className="mb-2">
                <div className="field grid">
                    <label className="col-fixed" style={{ width: "12%" }}>Regular</label>
                    <div className="col-2 md:col-2 p-inputgroup">
                        <InputNumber
                            min={MIN_INPUT_NUMBER_VALUE}
                            max={MAX_INPUT_NUMBER_VALUE}
                            required
                            placeholder={`${MIN_INPUT_NUMBER_VALUE} à ${MAX_INPUT_NUMBER_VALUE} pontos`}
                            suffix=" pontos"
                            name="regular"
                            value={state.speed.regular}
                            onChange={e => handleTextSite(e.originalEvent, e.value)}
                        />
                        <span className="p-inputgroup-addon text-gray-900 bg-yellow-300 border-yellow-300">
                            <i className="pi pi-sort-amount-up"></i>
                        </span>
                    </div>
                </div>
                <div className="field grid">
                    <label className="col-fixed" style={{ width: "12%" }}>Turbo</label>
                    <div className="col-2 md:col-2 p-inputgroup">
                        <InputNumber
                            min={MIN_INPUT_NUMBER_VALUE}
                            max={MAX_INPUT_NUMBER_VALUE}
                            required
                            placeholder={`${MIN_INPUT_NUMBER_VALUE} à ${MAX_INPUT_NUMBER_VALUE} pontos`}
                            suffix=" pontos"
                            name="turbo"
                            value={state.speed.turbo}
                            onChange={e => handleTextSite(e.originalEvent, e.value)}
                        />
                        <span className="p-inputgroup-addon text-gray-900 bg-yellow-300 border-yellow-300">
                            <i className="pi pi-sort-amount-up"></i>
                        </span>
                    </div>
                </div>
                <div className="field grid">
                    <label className="col-fixed" style={{ width: "12%" }}>Hyper</label>
                    <div className="col-2 md:col-2 p-inputgroup" style={{ maxWidth: 200 }}>
                        <InputNumber
                            min={MIN_INPUT_NUMBER_VALUE}
                            max={MAX_INPUT_NUMBER_VALUE}
                            required
                            placeholder={`${MIN_INPUT_NUMBER_VALUE} à ${MAX_INPUT_NUMBER_VALUE} pontos`}
                            suffix=" pontos"
                            name="hyper"
                            value={state.speed.hyper}
                            onChange={e => handleTextSite(e.originalEvent, e.value)}
                        />
                        <span className="p-inputgroup-addon text-gray-900 bg-yellow-300 border-yellow-300">
                            <i className="pi pi-sort-amount-up"></i>
                        </span>
                    </div>
                </div>

            </div>

        </div>
    )
}

export default FourStepForm
