import { addLocale, locale } from 'primereact/api'
import { classNames } from 'primereact/utils'
import React, { useEffect, useRef, useState } from 'react'
import { Navigate, useLocation } from 'react-router-dom'
// import AppConfig from './AppConfig';
import AppFooter from './AppFooter'
// import AppRightPanel from './AppRightPanel';
import AppTopbar from './AppTopbar'
import ProfilePage from './pages/ProfilePage'
// import TableDemo from './components/TableDemo';
import 'primeflex/primeflex.css'
import 'primeicons/primeicons.css'
import 'primereact/resources/primereact.min.css'
import { Tooltip } from 'primereact/tooltip'
import './App.scss'
import AlarmWebsocketDialog from './components/AlarmWebsocketDialog'
import Tournaments from './components/Tournaments'
import UsersAccount from './components/UsersAccount'
import { useAuth } from './hooks/useAuth'
import { AccountProfile } from './pages/AccountProfile'

const App = (props) => {
  const { user } = useAuth()
  const [resetActiveIndex, setResetActiveIndex] = useState(null)
  const [staticMenuMobileActive, setStaticMenuMobileActive] = useState(false)
  const [sidebarStatic, setSidebarStatic] = useState(true)
  const [sidebarActive, setSidebarActive] = useState(true)
  const [menuActive, setMenuActive] = useState(false)
  const [menuMode] = useState('sidebar')
  const [configActive, setConfigActive] = useState(false)
  const [inputStyle] = useState('filled')
  const [ripple] = useState(true)
  const [rightPanelActive, setRightPanelActive] = useState(true)
  // const [colorScheme, setColorScheme] = useState('dark');
  const [topbarScheme] = useState('dark')
  const [menuScheme] = useState('dark')
  // const [themeScheme, setThemeScheme] = useState('dark');
  // const [theme, setTheme] = useState('mylobby');
  const [searchActive, setSearchActive] = useState(false)
  const [topbarUserMenuActive, setTopbarUserMenuActive] = useState(false)
  // const [filterField, setFilterField] = useState(null);
  // const [filterValue, setFilterValue] = useState(null);
  const [filter, setFilter] = useState([])
  const copyTooltipRef = useRef()
  const location = useLocation()

  const [showDialogAlarm, setShowDialogAlarm] = useState(false);
  const [alarmTournaments, setAlarmTournaments] = useState([]);

  const menu = [
    {
      label: 'Reta',
      icon: 'pi pi-sort-alt',
      isActive: false,
      isCustomMenu: true,
      isButtonFilter: false,
      filterByField: 'straight',
      isVisible: true,
      showClearFilter: true,
    },
    {
      label: 'Alarme',
      icon: 'pi pi-bell',
      isActive: false,
      isCustomMenu: true,
      isButtonFilter: false,
      filterByField: 'alarm',
      isVisible: true,
      showClearFilter: false
    },
    {
      label: 'Favoritos',
      icon: 'pi pi-star',
      isActive: false,
      isCustomMenu: true,
      isButtonFilter: false,
      filterByField: 'favorites',
      isVisible: true,
      showClearFilter: false
    },
    {
      label: 'Prioridades',
      icon: 'pi pi-verified',
      isActive: false,
      isCustomMenu: true,
      isButtonFilter: false,
      filterByField: 'priorities',
      isVisible: user?.usersView?.prioritiesFilter,
      showClearFilter: false
    },
    {
      label: 'Sites',
      icon: 'pi pi-th-large',
      isActive: false,
      isCustomMenu: true,
      isButtonFilter: true,
      filterByField: 'network',
      isVisible: true,
      showClearFilter: false
    },
    {
      label: 'Semana',
      icon: 'pi pi-calendar-plus',
      isActive: false,
      isCustomMenu: true,
      isButtonFilter: false,
      filterByField: 'weekScheduledStartDate',
      isVisible: true,
      showClearFilter: false
    },
    {
      label: 'Fim do Torneio',
      icon: 'pi pi-history',
      isActive: false,
      isCustomMenu: true,
      isButtonFilter: false,
      filterByField: 'duration',
      isVisible: true,
      showClearFilter: false
    },
    {
      label: 'Buy-in',
      icon: 'pi pi-dollar',
      isActive: false,
      isCustomMenu: true,
      isButtonFilter: false,
      filterByField: 'buyin',
      isVisible: true,
      showClearFilter: false
    },
    {
      label: 'Field',
      icon: 'pi pi-users',
      isActive: false,
      isCustomMenu: true,
      isButtonFilter: false,
      filterByField: 'totalEntrants',
      isVisible: true,
      showClearFilter: false
    },
    {
      label: 'Garantido',
      icon: 'pi pi-chart-line',
      isActive: false,
      isCustomMenu: true,
      isButtonFilter: false,
      filterByField: 'guarantee',
      isVisible: true,
      showClearFilter: false
    },
    {
      label: '1º Lugar',
      icon: 'pi pi-sitemap',
      isActive: false,
      isCustomMenu: true,
      isButtonFilter: false,
      filterByField: 'prize',
      isVisible: true,
      showClearFilter: false
    },
    {
      label: 'Late Register',
      icon: 'pi pi-question-circle',
      isActive: false,
      isCustomMenu: true,
      isButtonFilter: false,
      filterByField: 'lateRegister',
      isVisible: true,
      showClearFilter: false
    },
    {
      label: 'Speed',
      icon: 'pi pi-clock',
      isActive: false,
      isCustomMenu: true,
      isButtonFilter: false,
      filterByField: 'speed',
      isVisible: true,
      showClearFilter: false
    },
    {
      label: 'Estrutura Torneio',
      icon: 'pi pi-folder',
      isActive: false,
      isCustomMenu: true,
      isButtonFilter: false,
      filterByField: 'tournamentStructure',
      isVisible: true,
      isSubMenu: true,
      showClearFilter: false
    },
    {
      label: 'Ent. Blinds',
      icon: 'pi pi-database',
      isActive: false,
      isCustomMenu: true,
      isButtonFilter: false,
      filterByField: 'entBlinds',
      contentEmpty: false,
      isVisible: user?.usersView?.blindsEntryFilter,
      showClearFilter: false
    },
    {
      label: 'Max Late',
      icon: 'pi pi-stopwatch',
      isActive: false,
      isCustomMenu: true,
      isButtonFilter: false,
      filterByField: 'maxLate',
      isVisible: true,
      showClearFilter: false
    },
    {
      label: 'Jogadores por Mesa',
      icon: 'pi pi-user-plus',
      isActive: false,
      isCustomMenu: true,
      isButtonFilter: false,
      filterByField: 'playersPerTable',
      isVisible: true,
      showClearFilter: false
    }
  ]

  let menuClick
  let rightPanelClick
  let configClick
  let searchClick
  let topbarUserMenuClick

  addLocale('pt', {
    startsWith: 'Começa com',
    contains: 'Contém',
    notContains: 'Não contém',
    endsWith: 'Termina com',
    equals: 'Igual',
    notEquals: 'Diferente',
    noFilter: 'Sem filtro',
    filter: 'Filtro',
    lt: 'Menor que',
    lte: 'Menor que ou igual a',
    gt: 'Maior que',
    gte: 'Maior que ou igual a',
    dateIs: 'Data é',
    dateIsNot: 'Data não é',
    dateBefore: 'Date é anterior',
    dateAfter: 'Data é posterior',
    custom: 'Customizado',
    clear: 'Limpar',
    close: 'Fechar',
    apply: 'Aplicar',
    matchAll: 'Match All',
    matchAny: 'Match Any',
    addRule: 'Adicionar Regra',
    removeRule: 'Remover Regra',
    accept: 'Sim',
    reject: 'Não',
    choose: 'Escolha',
    upload: 'Upload',
    cancel: 'Cancelar',
    completed: 'Concluído',
    pending: 'Pendente',
    dayNames: ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'],
    dayNamesShort: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'],
    dayNamesMin: ['Do', 'Se', 'Te', 'Qa', 'Qi', 'Sx', 'Sa'],
    monthNames: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
    monthNamesShort: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
    chooseYear: 'Escolha Ano',
    chooseMonth: 'Escolha Mês',
    chooseDate: 'Escolha Data',
    prevDecade: 'Década Anterior',
    nextDecade: 'Década Seguinte',
    prevYear: 'Ano Anterior',
    nextYear: 'Ano Seguinte',
    prevMonth: 'Mês Anterior',
    nextMonth: 'Mês Seguinte',
    prevHour: 'Hora Anterior',
    nextHour: 'Hora Seguinte',
    prevMinute: 'Minuto Anterior',
    nextMinute: 'Minuto Seguinte',
    prevSecond: 'Segundo Anterior',
    nextSecond: 'Segundo Seguinte',
    am: 'am',
    pm: 'pm',
    today: 'Hoje',
    weekHeader: 'Sem',
    firstDayOfWeek: 0,
    dateFormat: 'dd/mm/yy',
    weak: 'Fraco',
    medium: 'Médio',
    strong: 'Forte',
    passwordPrompt: 'Digite uma senha',
    emptyFilterMessage: 'Nenhum resultado encontrado',
    searchMessage: '{0} resultados disponíveis',
    selectionMessage: '{0} itens selecionados',
    emptySelectionMessage: 'Nenhum item selecionado',
    emptySearchMessage: 'Nenhum resultado encontrado',
    emptyMessage: 'Nenhuma opção disponível',
    aria: {
      trueLabel: 'Verdadeiro',
      falseLabel: 'Falso',
      nullLabel: 'Não selecionado',
      star: '1 estrela',
      stars: '{star} estrelas',
      selectAll: 'Todos itens selecionados',
      unselectAll: 'Nenhum item selecionado',
      close: 'Fechar',
      previous: 'Anterior',
      next: 'Seguinte',
      navigation: 'Navegação',
      scrollTop: 'Rolar para Topo',
      moveTop: 'Mover para Topo',
      moveUp: 'Mover para Cima',
      moveDown: 'Mover para Baixo',
      moveBottom: 'Mover para Final',
      moveToTarget: 'Mover para Alvo',
      moveToSource: 'Mover para Fonte',
      moveAllToTarget: 'Mover Todos para Alvo',
      moveAllToSource: 'Mover Todos para Fonte',
      pageLabel: '{page}',
      firstPageLabel: 'Primeira Página',
      lastPageLabel: 'Última Página',
      nextPageLabel: 'Página Seguinte',
      previousPageLabel: 'Página Anterior',
      rowsPerPageLabel: 'Linhas por página',
      jumpToPageDropdownLabel: 'Pular para Menu da Página',
      jumpToPageInputLabel: 'Pular para Campo da Página',
      selectRow: 'Linha Selecionada',
      unselectRow: 'Linha Não Selecionada',
      expandRow: 'Linha Expandida',
      collapseRow: 'Linha Recolhida',
      showFilterMenu: 'Mostrar Menu de Filtro',
      hideFilterMenu: 'Esconder Menu de Filtro',
      filterOperator: 'Operador de Filtro',
      filterConstraint: 'Restrição de Filtro',
      editRow: 'Editar Linha',
      saveEdit: 'Salvar Editar',
      cancelEdit: 'Cancelar Editar',
      listView: 'Exibição em Lista',
      gridView: 'Exibição em Grade',
      slide: 'Deslizar',
      slideNumber: '{slideNumber}',
      zoomImage: 'Ampliar Imagem',
      zoomIn: 'Mais Zoom',
      zoomOut: 'Menos Zoom',
      rotateRight: 'Girar à Direita',
      rotateLeft: 'Girar à Esquerda'
    }
  });

  useEffect(() => {
    copyTooltipRef && copyTooltipRef.current && copyTooltipRef.current.updateTargetEvents()
    locale('pt')
  }, [location]);

  useEffect(() => {
    if (staticMenuMobileActive) {
      blockBodyScroll()
    } else {
      unblockBodyScroll()
    }
  }, [staticMenuMobileActive]);

  useEffect(() => {
    setResetActiveIndex(true)
    setMenuActive(false)
  }, [menuMode]);

  useEffect(() => {
    if (Reflect.ownKeys(user).length !== 0 && user?.accessToken !== null && user !== 'undefined') {
      props.stompjs.configure({
        //brokerURL: 'wss://ws.mylobby.com.br:8443/mylobby-ws/ws',
        brokerURL:
          process.env.REACT_APP_WEBSOCKET_PORT_API === ''
            ? `${process.env.REACT_APP_WEBSOCKET_HOST_API}${process.env.REACT_APP_WEBSOCKET_URL_API}`
            : `${process.env.REACT_APP_WEBSOCKET_HOST_API}:${process.env.REACT_APP_WEBSOCKET_PORT_API}${process.env.REACT_APP_WEBSOCKET_URL_API}`,
        connectHeaders: {
          user: JSON.stringify({
            email: user?.email,
            accessToken: user?.accessToken
          })
        },
        onConnect: () => {
          console.log('Connected %s', user.email);
          props.stompjs.subscribe('/user/queue/alarm', (alarmNotification) => {
            // eslint-disable-next-line
            addTorneioOnDialogAlarm(JSON.parse(alarmNotification.body));
          });
        },
        onDisconnect: () => {
          console.log('Disconnected %s', user.email);
        },
        onStompError: (frame) => {
          console.error('Broker reported error: ' + frame.headers.message)
          console.error('Additional details: ' + frame.body)
        },
        onWebSocketError: (error) => {
          console.error('Error with websocket', error)
          props.stompjs.deactivate()
          console.error('Disconnected')
        },

        //reconnectDelay: 10000,
        // debug: (str) => {
        //   console.log(str);
        // }
      });

      props.stompjs.activate();

      // client.deactivate();
    }
  }, [props, user]);

  const addTorneioOnDialogAlarm = (alarmNotification) => {
    setAlarmTournaments(alarmNotification);
    setShowDialogAlarm(true);
  };

  const onHideDialogAlarm = () => {
    setAlarmTournaments([]);
    setShowDialogAlarm(false);
  }

  const onMenuItemClick = (event) => {
    if (!event.item.items) {
      setResetActiveIndex(true)
      hideOverlayMenu()
    }
    if (!event.item.items && (isSlim() || isHorizontal())) {
      setMenuActive(false)
    }
  }

  const onMenuClick = (event) => {
    if (menuActive && event.target.className === 'layout-menu-container') {
      setResetActiveIndex(true)
      setMenuActive(false)
    }
    menuClick = true
  }

  /* const onMenuModeChange = (menuMode) => {
        setMenuMode(menuMode);
        if (menuMode === 'sidebar') {
            if (sidebarStatic) {
                setSidebarActive(true);
            }
        } else {
            setSidebarActive(false);
            if (topbarScheme !== menuScheme) {
                setMenuScheme(topbarScheme);
            }
        }
        if (topbarScheme === 'dark') {
            setThemeScheme('dark');
        }
    };

    const onColorSchemeChange = (scheme) => {
        setColorScheme(scheme);
        props.setColorScheme(scheme);
    };

    const onThemeSchemeChange = (scheme) => {
        setThemeScheme(scheme);
        setMenuScheme(scheme);
        setTopbarScheme(scheme);
    };

    const onTopbarSchemeChange = (scheme) => {
        setTopbarScheme(scheme);
    };

    const onMenuSchemeChange = (scheme) => {
        setMenuScheme(scheme);
    };

    const onThemeChange = (themeColor) => {
        setTheme(themeColor);
    }; */

  const blockBodyScroll = () => {
    if (document.body.classList) {
      document.body.classList.add('blocked-scroll')
    } else {
      document.body.className += ' blocked-scroll'
    }
  }

  const unblockBodyScroll = () => {
    if (document.body.classList) {
      document.body.classList.remove('blocked-scroll')
    } else {
      document.body.className = document.body.className.replace(new RegExp('(^|\\b)' + 'blocked-scroll'.split(' ').join('|') + '(\\b|$)', 'gi'), ' ')
    }
  }

  const onMenuButtonClick = (event) => {
    menuClick = true
    setTopbarUserMenuActive(false)
    setRightPanelActive(false)

    if (isMobile()) {
      setStaticMenuMobileActive((prevStaticMenuMobileActive) => !prevStaticMenuMobileActive)
      if (staticMenuMobileActive) {
        blockBodyScroll()
      } else {
        unblockBodyScroll()
      }
    }
    event.preventDefault()
  }

  const isMobile = () => {
    return window.innerWidth <= 991
  }

  const isHorizontal = () => {
    return menuMode === 'horizontal'
  }

  const isSlim = () => {
    return menuMode === 'slim'
  }

  const hideOverlayMenu = () => {
    setStaticMenuMobileActive(false)
    unblockBodyScroll()
  }

  /* const onRightPanelClick = () => {
        rightPanelClick = true;
    }; */

  const onRightPanelButtonClick = () => {
    setRightPanelActive((prevState) => !prevState)
    rightPanelClick = true
  }

  /* const onConfigClick = () => {
        configClick = true;
    };

    const onConfigButtonClick = () => {
        setConfigActive((prevConfigActive) => !prevConfigActive);
        configClick = true;
    }; */

  const onTopbarSearchToggle = () => {
    setSearchActive((prevState) => !prevState)
    searchClick = true
  }

  const onTopbarSearchClick = () => {
    searchClick = true
  }

  const onTopbarUserMenuClick = () => {
    setTopbarUserMenuActive((prevState) => !prevState)
    topbarUserMenuClick = true
  }

  /* const onInputStyleChange = (inputStyle) => {
        setInputStyle(inputStyle);
    };

    const onRippleChange = (e) => {
        PrimeReact.ripple = e.value;
        setRipple(e.value);
    }; */

  const onDocumentClick = () => {
    if (!searchClick && searchActive) {
      setSearchActive(false)
      searchClick = false
    }

    if (!topbarUserMenuClick && topbarUserMenuActive) {
      setTopbarUserMenuActive(false)
      topbarUserMenuClick = false
    }

    if (!rightPanelClick && rightPanelActive) {
      setRightPanelActive(false)
    }

    if (!configClick && configActive) {
      setConfigActive(false)
    }

    if (!menuClick) {
      if (isSlim() || isHorizontal()) {
        setResetActiveIndex(true)
        setMenuActive(false)
      }

      if (staticMenuMobileActive) {
        hideOverlayMenu()
      }

      unblockBodyScroll()
    }

    searchClick = false
    topbarUserMenuClick = false
    rightPanelClick = false
    configClick = false
    menuClick = false
  }

  const onSidebarMouseOver = () => {
    setSidebarActive(!isMobile())
  }

  const onSidebarMouseLeave = () => {
    setSidebarActive(false)
  }

  const onToggleMenu = (event) => {
    menuClick = true
    setSidebarStatic((prevState) => !prevState)

    event.preventDefault()
  }

  const onRootMenuItemClick = () => {
    setMenuActive((prevMenuActive) => !prevMenuActive)
  }

  const onFilters = (filters) => {
    setFilter(filters);
  }

  const layoutClassName = classNames(
    'layout-wrapper',
    {
      'layout-sidebar': menuMode === 'sidebar',
      'layout-static': menuMode === 'sidebar' && sidebarStatic,
      'layout-horizontal': menuMode === 'horizontal',
      'layout-rightpanel-active': rightPanelActive,
      'layout-slim': menuMode === 'slim',
      'layout-mobile-active': staticMenuMobileActive,
      'p-input-filled': inputStyle === 'filled',
      'p-ripple-disabled': !ripple
    },
    'layout-menu-' + menuScheme + ' layout-topbar-' + topbarScheme
  )

  if (Reflect.ownKeys(user).length === 0 || user?.accessToken === null || user === 'undefined') {
    return <Navigate to='/login' />
  }

  return (
    <div className={layoutClassName} onClick={onDocumentClick}>
      {user?.accessToken !== null ? <AlarmWebsocketDialog showDialog={showDialogAlarm} hideDialog={onHideDialogAlarm} alarmTournaments={alarmTournaments} /> : ''}
      <Tooltip ref={copyTooltipRef} target='.block-action-copy' position='bottom' content='Copied to clipboard' event='focus' />

      <AppTopbar
        topbarScheme={topbarScheme}
        onRightPanelButtonClick={onRightPanelButtonClick}
        searchActive={searchActive}
        onTopbarSearchToggle={onTopbarSearchToggle}
        onTopbarSearchClick={onTopbarSearchClick}
        topbarUserMenuActive={topbarUserMenuActive}
        onTopbarUserMenuClick={onTopbarUserMenuClick}
        menu={menu}
        menuActive={menuActive}
        onRootMenuItemClick={onRootMenuItemClick}
        mobileMenuActive={staticMenuMobileActive}
        onMenuItemClick={onMenuItemClick}
        menuMode={menuMode}
        sidebarStatic={sidebarStatic}
        sidebarActive={sidebarActive}
        onSidebarMouseOver={onSidebarMouseOver}
        onSidebarMouseLeave={onSidebarMouseLeave}
        onToggleMenu={onToggleMenu}
        onMenuButtonClick={onMenuButtonClick}
        resetActiveIndex={resetActiveIndex}
        onMenuClick={onMenuClick}
        onFilter={onFilters}
        filter={filter}
      />

      {/* <AppRightPanel onRightPanelClick={onRightPanelClick} /> */}

      {/* <AppConfig
                configActive={configActive}
                onConfigButtonClick={onConfigButtonClick}
                onConfigClick={onConfigClick}
                menuMode={menuMode}
                onMenuModeChange={onMenuModeChange}
                ripple={ripple}
                onRippleChange={onRippleChange}
                inputStyle={inputStyle}
                onInputStyleChange={onInputStyleChange}
                colorScheme={colorScheme}
                onColorSchemeChange={onColorSchemeChange}
                topbarScheme={topbarScheme}
                onTopbarSchemeChange={onTopbarSchemeChange}
                menuScheme={menuScheme}
                onMenuSchemeChange={onMenuSchemeChange}
                themeScheme={themeScheme}
                onThemeSchemeChange={onThemeSchemeChange}
                theme={theme}
                onThemeChange={onThemeChange}
            /> */}

      {location.pathname === '/profile' && <div className='layout-content-home'><ProfilePage /></div>}
      {location.pathname === '/user_profile' && <div className='layout-content-home'><AccountProfile /></div>}
      {location.pathname === '/user_account' && <div className='layout-content-home'>{<UsersAccount />}</div>}


      {location.pathname === '/' && (
        <div className='layout-main'>
          <div className='layout-content'>
            <Tournaments onFilter={onFilters} filter={filter} />
          </div>
        </div>
      )}

      <AppFooter />
    </div>
  )
}

export default App
