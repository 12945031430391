import React from 'react';
import { NetworksInfo } from '../components/NetworksInfo';
import { PlayerInfoCard } from '../components/PlayerInfoCard';
import { PlayerInfoData } from '../components/PlayerInfoData';
import { useAuth } from '../hooks/useAuth';

const ProfilePage = () => {
    const { user } = useAuth();

    return (
        <React.Fragment>
            <div className="w-full h-full flex gap-3 p-4 flex-column mt-8 xl:mt-0">
                <div className="flex gap-2 xl:flex-row flex-column">
                    <PlayerInfoCard isHome />
                    <PlayerInfoData isHome />
                </div>
                {user.usersView.forTeam && <NetworksInfo isHome />}
            </div>
        </React.Fragment>
    );
};

export default ProfilePage;
