import React from "react";

const teamImageTemplate = (user, empty = false, size = '24px') => {
    return (
        <React.Fragment>
            {user?.team !== null ? user?.team?.logo !== "/" ? <img alt={user?.team?.nickname} src={user?.team?.logo} style={{ width: size, height: size, border: "none" }} /> : empty ? "" : "-" : empty ? "" : "-"}
        </React.Fragment>
    )
};

export { teamImageTemplate };
