import React from 'react';
import { createBrowserRouter, createRoutesFromElements, defer, Route } from 'react-router-dom';
import App from './App';
import { AuthLayout } from './components/AuthLayout';
import { Login } from './pages/Login';
import { Client } from '@stomp/stompjs'
import RegisterAccount from './pages/RegisterAccount';
import RetrieveAccount from './pages/RetrieveAccount';

export const ctx = React.createContext();

const setColorScheme = () => { };

const client = new Client();

const getUserData = () =>
    new Promise((resolve) =>
        setTimeout(() => {
            const user = window.localStorage.getItem('user');
            resolve(user);
        }, 1000)
    );

const AppWrapper = createBrowserRouter(
    createRoutesFromElements(
        <Route element={<AuthLayout />} loader={() => defer({ userPromise: getUserData() })}>
            <Route path="/login" element={<Login colorScheme="dark" stompjs={client} />} />
            {/* <Route path="/error" element={<Error colorScheme={colorScheme} />} />
            <Route path="/notfound" element={<NotFound colorScheme={colorScheme} />} /> */}
            {/* <Route path="/access" element={<Access colorScheme={colorScheme} />} /> */}
            <Route path="/retrieve_account" element={<RetrieveAccount />} />
            <Route path="/register_account" element={<RegisterAccount />} />
            <Route path="*" element={<App setColorScheme={setColorScheme} stompjs={client} />} />
        </Route>
    )
);

export default AppWrapper;
