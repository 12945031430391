import { Button } from 'primereact/button'
import { Dialog } from 'primereact/dialog'
import { InputText } from 'primereact/inputtext'
import { Password } from 'primereact/password'
import React, { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import AppMesagens from '../AppMesagens'
import EnumTipoMensagem from '../helpers/core/enumerator/EnumTipoMensagem'
import MensagemLista from '../helpers/core/message/MensagemLista'
import { tratarExcecao } from '../helpers/core/util/ExceptionUtil'
import { useAuth } from '../hooks/useAuth'
import MyLobbyService from '../service/MyLobbyService'
import { DateTime } from 'luxon'

function RegisterAccount() {
  const navigate = useNavigate();
  const { disableValidation } = useAuth();
  const [fisrtNameLastName, setFisrtNameLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [mensagens, setMensagens] = useState('');
  const [subscriptionDialog, setSubscriptionDialog] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    disableValidation();
  }, [disableValidation]);

  const registerAccount = useCallback(
    async (_registerAccount) => {
      setLoading(true);
      setSubscriptionDialog(false);
      MyLobbyService.postRegisterAccount(
        _registerAccount
      ).then((response) => {
        setLoading(false)
        const mensagem = new MensagemLista();
        mensagem.simpleMensagem('Usuário cadastrado com sucesso', EnumTipoMensagem.SUCCESS);
        mensagem.simpleMensagem('Verifique sua caixa de e-mail', EnumTipoMensagem.INFO);
        setMensagens(mensagem);
        setFisrtNameLastName('');
        setEmail('');
        setPassword('');
        // navigate("/", { replace: true });
      }).catch((error) => {
        const mensagem = tratarExcecao(error);
        setMensagens(mensagem);
        setLoading(false);
      })
    }, []);

  const validateSubscription = (event) => {
    const subscription = event.currentTarget.value
    const _registerAccount = {
      name: fisrtNameLastName,
      senha: password,
      email,
      subscription,
      timeZone: {
        name: DateTime.now().zoneName,
        gmt: DateTime.now().toFormat('ZZ'),
      }
    }
    registerAccount(_registerAccount);
  };

  return (
    <div className='login-body'>
      {mensagens !== '' ? <AppMesagens mensagens={mensagens} position='bottom-center' /> : ''}
      <div className='login-wrapper'>
        <div className='login-panel gap-2'>
          <img src='assets/layout/images/logo.png' className='mb-5' alt='mylobby' width='250' />
          <div className='p-inputgroup'>
            <span className='p-inputgroup-addon bg-red-500 border-red-500' style={{ height: '42px' }}>
              <i className='pi pi-user text-white' />
            </span>
            <InputText value={fisrtNameLastName} placeholder='Nome completo' onChange={(e) => setFisrtNameLastName(e.target.value)} className='input-login-mylobby text-0 border-white' />
          </div>
          <div className='p-inputgroup'>
            <span className='p-inputgroup-addon bg-red-500 border-red-500' style={{ height: '42px' }}>
              <i className='pi pi-envelope text-white' />
            </span>
            <InputText value={email} placeholder='E-mail' onChange={(e) => setEmail(e.target.value)} className='input-login-mylobby text-0 border-white' />
          </div>
          <div className='p-inputgroup'>
            <span className='p-inputgroup-addon bg-red-500 border-red-500' style={{ height: '42px' }}>
              <i className='pi pi-key text-white' />
            </span>
            <Password value={password} placeholder='Senha' feedback={false} onChange={(e) => setPassword(e.target.value)} toggleMask />
          </div>
          <Button label='Cadastrar' loading={loading} onClick={() => setSubscriptionDialog(true)} />
          <p className='text-white'>
            Tem uma conta? <button className='p-link text-red-400' onClick={(e) => { navigate('/', { replace: true }) }}>Conecte-se</button>
          </p>
        </div>
        <div className='login-footer'>
          <h4 className='font-mylobby'>
            MY LO<span className='text-primary'>BB</span>Y
          </h4>
          <h6>Table select na sua mão</h6>
        </div>
        <h6 className='text-sm'>powered by <a href="https://mcp.tec.br" target="_blank" rel="noreferrer" className="text-color">MCP Tec.</a></h6>
      </div>
      <div>
        <Dialog header='' style={{ width: '60vw' }} visible={subscriptionDialog} onHide={() => setSubscriptionDialog(false)} modal>
          <div className='landing-body'>
            <div className='landing-wrapper'>
              <div id='pricing' className='landing-pricing-register'>
                <div className='grid'>
                  <div className='col-12 lg:col-3'>
                    <div className='pricing-card-register'>
                      <h2>Free</h2>
                      <span className='price-register'>$0</span>
                      <span className='time-register'>por 7 dias</span>
                      <ul>
                        <li><i className='pi pi-check-circle text-green-500 mr-2' />Acesso completo</li>
                      </ul><hr />
                      <Button label='Assinar' value='Free' className='p-3 w-8 mt-auto font-medium border-round-3xl' icon='pi pi-shopping-cart' onClick={validateSubscription} /><hr />
                    </div>
                  </div>
                  <div className='col-12 lg:col-3'>
                    <div className='pricing-card-register'>
                      <h2>Micro</h2>
                      <span className='price-register'>$11,99</span>
                      <span className='time-register'>por mês</span>
                      <ul>
                        <li><i className='pi pi-check-circle text-green-500 mr-2' />Buy-in até $16,50</li>
                      </ul><hr />
                      <Button label='Em breve' value='Micro' className='p-3 w-8 mt-auto font-medium border-round-3xl' icon='pi pi-shopping-cart' onClick={validateSubscription} disabled /><hr />
                    </div>
                  </div>
                  <div className='col-12 lg:col-3'>
                    <div className='pricing-card-register pro'>
                      {/* <span class="preferred-tag">MOST PREFERED</span> */}
                      <h2>Low</h2>
                      <span className='price-register'>$23,99</span>
                      <span className='time-register'>por mês</span>
                      <ul>
                        <li><i className='pi pi-check-circle text-green-500 mr-2' />Buy-in até $55,00</li>
                      </ul><hr />
                      <Button label='Em breve' value='Low' className='p-3 w-8 mt-auto font-medium border-round-3xl' icon='pi pi-shopping-cart' onClick={validateSubscription} disabled /><hr />
                    </div>
                  </div>
                  <div className='col-12 lg:col-3 prefered'>
                    <div className='pricing-card-register enterprise'>
                      <span className='prefered-tag-register'>MAIS PEDIDO</span>
                      <h2>High</h2>
                      <span className='price-register-prefered'>$34,90</span>
                      <span className='time-prefered-register'>por mês</span>
                      <ul>
                        <li>
                          <i className='pi pi-check-circle text-green-500 mr-2' />Todos os Buy-in
                        </li>
                      </ul>
                      <hr />
                      <Button label='Em breve' value='High' className='p-3 w-8 mt-auto font-medium border-round-3xl border-gray-700 bg-gray-700' icon='pi pi-shopping-cart' onClick={validateSubscription} disabled />
                      <hr />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Dialog>
      </div>
    </div>
  );
};

export default RegisterAccount
