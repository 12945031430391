import { useRef } from 'react'

/**
 *
 * Substitui o hook useDeferredValue
 *
 * @param {Function} fn
 * @param {number} timeout
 * @returns {() => void}
 */
export const useDebounce = (fn, timeout = 1000) => {
  const timeoutRef = useRef()

  /**
   * Retorna uma função passada por parametro (fn) e limpa o timeout se chamada novamente
   *
   * @param  {...any} params
   */
  const debouncedFinally = (...params) => {
    window.clearTimeout(timeoutRef.current)
    timeoutRef.current = window.setTimeout(() => {
      fn(...params)
    }, timeout)
  }

  return debouncedFinally
}
