import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "primereact/button";
import { ConfirmDialog } from "primereact/confirmdialog";
import { Divider } from "primereact/divider";
import React, { memo, useEffect, useReducer, useState } from "react";
import AppMesagens from "../../AppMesagens";
import EnumTipoMensagem from "../../helpers/core/enumerator/EnumTipoMensagem";
import MensagemLista from "../../helpers/core/message/MensagemLista";
import { tratarExcecao } from "../../helpers/core/util/ExceptionUtil";
import { isUndefined } from "../../helpers/core/util/ObjectUtil";
import { useAuth } from "../../hooks/useAuth";
import MyLobbyService from "../../service/MyLobbyService";
import PrioritiesPlayers from "../PrioritiesPlayers";
import FiveStepForm from "./FiveStepForm";
import FourStepForm from "./FourStepForm";
import OneStepForm from './OneStepForm';
import SevenStepForm from "./SevenStepForm";
import ThreeStepForm from "./ThreeStepForm";
import TwoStepForm from "./TwoStepForm";

const genericFormState = {
    networks: {
        label: 'Sites',
        collection: []
    },
    playerNumber: {
        label: 'Número de players',
        twoheadsup: '',
        threetofive: '',
        sixmax: '',
        fullring: ''
    },
    structures: {
        label: 'Estrutura',
        regular: '',
        bounty: '',
    },
    speed: {
        label: 'Velocidade',
        regular: '',
        turbo: '',
        hyper: '',
    },
    buyin: {
        label: 'Buy-in',
        collection: [
            {
                min: '',
                max: '',
                value: ''
            },
            {
                min: '',
                max: '',
                value: ''
            },
            {
                min: '',
                max: '',
                value: ''
            },
            {
                min: '',
                max: '',
                value: ''
            },
            {
                min: '',
                max: '',
                value: ''
            },
            {
                min: '',
                max: '',
                value: ''
            },
        ]
    },
    guarantee: {
        label: 'Garantido',
        collection: [
            {
                min: '',
                max: '',
                value: ''
            },
            {
                min: '',
                max: '',
                value: ''
            },
            {
                min: '',
                max: '',
                value: ''
            },
            {
                min: '',
                max: '',
                value: ''
            },
        ]
    },
    fields: {
        label: 'Field',
        collection: [
            {
                min: '',
                max: '',
                value: ''
            },
            {
                min: '',
                max: '',
                value: ''
            },
            {
                min: '',
                max: '',
                value: ''
            },
            {
                min: '',
                max: '',
                value: ''
            },
            {
                min: '',
                max: '',
                value: ''
            },
            {
                min: '',
                max: '',
                value: ''
            },
        ]
    },
}

const GetCaseByActiveIndex = ({ setShowFormPrioridades, showFormPrioridades }) => {

    const handleFormState = (state, action) => {
        switch (action.type) {
            case "change-site-punctuation":
                const indexSite = state?.networks?.collection?.findIndex(site => {
                    return site.name === action?.payload?.name
                });

                if (state?.networks?.collection[indexSite]) {
                    state?.networks?.collection?.splice(indexSite, 1, action.payload);
                } else {
                    state?.networks?.collection?.push(action.payload);
                }

                return state

            case "change-player-punctuation":

                return {
                    ...state,
                    playerNumber: {
                        ...state.playerNumber,
                        [action.field]: action.payload
                    }
                }

            case "change-structure-punctuation":

                return {
                    ...state,
                    structures: {
                        ...state.structures,
                        [action.field]: action.payload
                    }
                }

            case "change-speed-punctuation":

                return {
                    ...state,
                    speed: {
                        ...state.speed,
                        [action.field]: action.payload
                    }
                }

            case "change-buy-in-punctuation":

                let allBuyIn = state?.buyin?.collection;
                let getBuyIn = state?.buyin?.collection[action.index]
                getBuyIn = {
                    ...getBuyIn,
                    [action.field]: action.payload,
                }
                allBuyIn.splice(action.index, 1, getBuyIn)

                return {
                    ...state,
                    buyin: {
                        ...state.buyin,
                        collection: allBuyIn,
                    }
                }

            case "change-guarentee-in-punctuation":

                let allGuarentee = state?.guarantee?.collection;
                let getGuarentee = state?.guarantee?.collection[action.index]
                getGuarentee = {
                    ...getGuarentee,
                    [action.field]: action.payload,
                }
                allGuarentee.splice(action.index, 1, getGuarentee)

                return {
                    ...state,
                    guarantee: {
                        ...state.guarantee,
                        collection: allGuarentee,
                    }
                }

            case "change-field-in-punctuation":

                let allFields = state?.fields?.collection;
                let getField = state?.fields?.collection[action.index]
                getField = {
                    ...getField,
                    [action.field]: action.payload,
                }
                allFields.splice(action.index, 1, getField)

                return {
                    ...state,
                    fields: {
                        ...state.fields,
                        collection: allFields,
                    }
                }

            case "update-full":
                return action.payload;
            case "clean":
                action.payload = {
                    networks: {
                        label: 'Sites',
                        collection: []
                    },
                    playerNumber: {
                        label: 'Número de players',
                        twoheadsup: '',
                        threetofive: '',
                        sixmax: '',
                        fullring: ''
                    },
                    structures: {
                        label: 'Estrutura',
                        regular: '',
                        bounty: '',
                    },
                    speed: {
                        label: 'Velocidade',
                        regular: '',
                        turbo: '',
                        hyper: '',
                    },
                    buyin: {
                        label: 'Buy-in',
                        collection: [
                            {
                                min: '',
                                max: '',
                                value: ''
                            },
                            {
                                min: '',
                                max: '',
                                value: ''
                            },
                            {
                                min: '',
                                max: '',
                                value: ''
                            },
                            {
                                min: '',
                                max: '',
                                value: ''
                            },
                            {
                                min: '',
                                max: '',
                                value: ''
                            },
                            {
                                min: '',
                                max: '',
                                value: ''
                            },
                        ]
                    },
                    guarantee: {
                        label: 'Garantido',
                        collection: [
                            {
                                min: '',
                                max: '',
                                value: ''
                            },
                            {
                                min: '',
                                max: '',
                                value: ''
                            },
                            {
                                min: '',
                                max: '',
                                value: ''
                            },
                            {
                                min: '',
                                max: '',
                                value: ''
                            },
                        ]
                    },
                    fields: {
                        label: 'Field',
                        collection: [
                            {
                                min: '',
                                max: '',
                                value: ''
                            },
                            {
                                min: '',
                                max: '',
                                value: ''
                            },
                            {
                                min: '',
                                max: '',
                                value: ''
                            },
                            {
                                min: '',
                                max: '',
                                value: ''
                            },
                            {
                                min: '',
                                max: '',
                                value: ''
                            },
                            {
                                min: '',
                                max: '',
                                value: ''
                            },
                        ]
                    },
                }
                return action.payload;
            default:
                return state
        }
    }

    const [initialFormState] = useState({ ...genericFormState });
    const [formState, dispatch] = useReducer(handleFormState, initialFormState)
    const [activeIndex, setActiveIndex] = useState(0);
    const [mensagens, setMensagens] = useState('');
    const [showExcluirPrioridades, setShowExcluirPrioridades] = useState(false);
    const [selectedPlayerDialog, setSelectedPlayerDialog] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [loading, setLoading] = useState(false);
    const { user } = useAuth();
    const [playerSelected, setPlayerSelected] = useState(null);

    const handleNextStepForm = e => {
        e.preventDefault();
        setSelectedPlayerDialog(true);
        setEditMode(false);
    };

    const onSelectedPlayerDialog = (player) => {
        setPlayerSelected(player);
        setSelectedPlayerDialog(false);
        const data = {
            buyin: formState?.buyin?.collection ?? [],
            fields: formState?.fields?.collection ?? [],
            networks: formState?.networks?.collection?.map(site => {
                return {
                    name: site?.name,
                    value: site?.value
                }
            }) ?? [],
            playerNumber: formState?.playerNumber ?? null,
            speed: formState?.speed ?? null,
            structures: formState?.structures ?? null,
            player,
            user: {
                email: user?.email,
                accessToken: user?.accessToken
            }
        }
        if (!editMode && !isUndefined(player)) {
            setLoading(true);
            MyLobbyService.postPrioridades(data)
                .then((response) => {
                    const mensagem = new MensagemLista();
                    mensagem.simpleMensagem('Prioridades cadastrada com sucesso', EnumTipoMensagem.SUCCESS);
                    setMensagens(mensagem);
                    dispatch({
                        //payload: genericFormState,
                        type: 'clean'
                    });
                    setPlayerSelected(null);
                }).catch((error) => {
                    let mensagem = tratarExcecao(error);
                    setMensagens(mensagem);
                }).finally(() => {
                    setLoading(false);
                });
        } else {
            !isUndefined(player) && getPrioridadesByUser(player);
        }
    };

    const getPrioridadesByUser = (player) => {
        setLoading(true);
        dispatch({
            type: 'clean'
        });
        MyLobbyService.getUser({
            email: user?.email,
            accessToken: user?.accessToken,
            player
        }).then(response => {
            return response.data;
        }).then(data => {
            if (data.priorities == null) {
                const mensagem = new MensagemLista();
                mensagem.simpleMensagem('Nenhuma prioridade cadastrada para o jogador', EnumTipoMensagem.INFO);
                setMensagens(mensagem);
            } else {
                let formatStateForm = {}
                Object.keys(data.priorities).forEach(key => {
                    if (key === 'networks' || key === 'buyin' || key === 'fields') {
                        formatStateForm = {
                            ...formatStateForm,
                            [key]: {
                                collection: data.priorities[key].sort((a, b) => {
                                    return a?.min - b?.min;
                                }),
                            }
                        };
                    } else {
                        formatStateForm = {
                            ...formatStateForm,
                            [key]: data.priorities[key]
                        };
                    }
                });
                // considera os 6 campos para preenchimento do buyin
                let buyin = { ...formatStateForm.buyin };
                buyin.collection = buyin.collection.concat(...genericFormState.buyin.collection).slice(0, 6);
                formatStateForm = { ...formatStateForm, buyin }

                // considera os 6 campos para preenchimento do 'fields'
                let fields = { ...formatStateForm.fields };
                fields.collection = fields.collection.concat(...genericFormState.fields.collection).slice(0, 6);
                formatStateForm = { ...formatStateForm, fields }

                dispatch({
                    payload: formatStateForm,
                    type: 'update-full'
                });
            }
        }).catch(error => {
            let mensagem = tratarExcecao(error);
            setMensagens(mensagem);
        }).finally(() => {
            setLoading(false);
        });
    };

    const deletePriorities = () => {
        setLoading(true);
        MyLobbyService.deletePrioridades({
            email: user?.email,
            accessToken: user?.accessToken,
            player: playerSelected
        }).then(response => {
            const mensagem = new MensagemLista();
            mensagem.simpleMensagem("Prioridades excluída com sucesso", EnumTipoMensagem.SUCCESS);
            setMensagens(mensagem);
        }).catch(error => {
            let mensagem = tratarExcecao(error);
            setMensagens(mensagem);
        }).finally(() => {
            dispatch({
                type: 'clean'
            });
            setLoading(false);
            setPlayerSelected(null);
        });
    };

    const editPriorities = () => {
        setSelectedPlayerDialog(true);
        setEditMode(true);
    }

    useEffect(() => {
        dispatch({
            type: 'clean'
        });
    }, []);

    return (
        <React.Fragment>
            {mensagens !== '' ? <AppMesagens mensagens={mensagens} position="bottom-center" /> : ''}
            <div className="flex justify-content-end flex-wrap">
                <Button label="Editar" icon="pi pi-pencil" className="bg-yellow-300 border-yellow-300 text-gray-900 mt-2" onClick={editPriorities} loading={loading} />
            </div>
            <form id="form-multi-step" onSubmit={handleNextStepForm}>
                <Divider />
                <OneStepForm
                    setActiveIndex={setActiveIndex}
                    activeIndex={activeIndex}
                    dispatch={dispatch}
                    state={formState}
                />
                <Divider />
                <TwoStepForm
                    setActiveIndex={setActiveIndex}
                    activeIndex={activeIndex}
                    dispatch={dispatch}
                    state={formState}
                />
                <Divider />
                <ThreeStepForm
                    setActiveIndex={setActiveIndex}
                    activeIndex={activeIndex}
                    dispatch={dispatch}
                    state={formState}
                />
                <Divider />
                <FourStepForm
                    setActiveIndex={setActiveIndex}
                    activeIndex={activeIndex}
                    dispatch={dispatch}
                    state={formState}
                />
                <Divider />
                <FiveStepForm
                    setActiveIndex={setActiveIndex}
                    activeIndex={activeIndex}
                    dispatch={dispatch}
                    state={formState}
                />
                <Divider />
                <SevenStepForm
                    setActiveIndex={setActiveIndex}
                    activeIndex={activeIndex}
                    dispatch={dispatch}
                    state={formState}
                />

                <ConfirmDialog
                    visible={showExcluirPrioridades}
                    onHide={() => setShowExcluirPrioridades(false)}
                    message="Deseja excluir suas prioridades?"
                    header="Confirmação"
                    accept={deletePriorities}
                    reject={() => setShowExcluirPrioridades(false)}
                    icon="pi pi-exclamation-triangle"
                />

                <Divider align="center" className='mt-8'></Divider>
                <div>
                    <div className='flex gap-2'>
                        <sup>*</sup>
                        <p className='mb-0'>Pontuação:</p>
                        <p className='mb-0'>1-2 Baixa</p>
                        <p className='mb-0'>3-4 Média</p>
                        <p className='mb-0'>5-6 Alta</p>
                    </div>
                    <div className='flex gap-2'>
                        <sup>*</sup>
                        <p className='mb-0'>Preencha os 'campos' da esquerda para a direita de cima para baixo</p>
                    </div>
                    <div className='flex gap-2'>
                        <sup>*</sup>
                        <p className='mb-0'>Utilize 'TAB' para preenchimento correto</p>
                    </div>
                </div>

                <div className="mt-8 flex gap-2 justify-content-end">
                    <Button
                        type="button"
                        label="Excluir"
                        onClick={() => setShowExcluirPrioridades(true)}
                        className="p-button-text p-button-danger"
                        icon={<FontAwesomeIcon icon={solid('xmark')}
                            className="mr-2" />}
                        disabled={isUndefined(playerSelected) || playerSelected === null}
                    />
                    <Button
                        form="form-multi-step"
                        type="submit"
                        label="Selecionar jogador"
                        className="bg-yellow-300 border-yellow-300 text-gray-900"
                        icon="pi pi-check"
                    />
                </div>
            </form>
            <PrioritiesPlayers showDialog={selectedPlayerDialog} hideDialog={onSelectedPlayerDialog} editMode={editMode} />
        </React.Fragment>
    );
};

export default memo(GetCaseByActiveIndex)
