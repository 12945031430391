import { Howl } from 'howler'
import { Dialog } from 'primereact/dialog'
import { Divider } from 'primereact/divider'
import React, { memo } from 'react'
import { siteImageTemplate } from '../helpers/core/util/SiteUtil'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'

const AlarmWebsocketDialog = ({ showDialog, hideDialog, alarmTournaments }) => {

  // Setup the new Howl.
  const sound = new Howl({
    src: ['sounds/good_morning.mp3'],
    autoplay: false,
    loop: false,
    volume: 0.5
  });

  const AlarmeBellHeader = () => {
    return (
      <div className='flex align-items-center'>
        <i
          className='pi pi-bell mr-3' style={{
            fontSize: 22
          }} title='Alarmes'
        />
        <strong className=''>Alarme</strong>
      </div>
    )
  }

  const closeDialogAlarm = () => {
    hideDialog();
    sound.stop();
  };

  const openDialogAlarm = () => {
    sound.play();
  }

  return (
    <Dialog
      header={<AlarmeBellHeader />}
      visible={showDialog}
      onHide={() => closeDialogAlarm()}
      style={{ width: '22vw' }}
      position='top-right'
      onShow={() => openDialogAlarm()}
    >
      {
        alarmTournaments?.map((alarm, i) => {
          return (
            <div id={`${alarm.idnetwork}-${i}-${alarm?.hourScheduledStartDate}`} key={`${alarm.idnetwork}-${i}-${alarm?.hourScheduledStartDate}`}>
              <div
                className="card p-fluid mb-0 p-2"
              >
                <div className="flex align-items-center gap-3 mb-4">
                  <div className="surface-100 border-round h-full py-2 px-3 shadow-5">
                    {siteImageTemplate(alarm?.network)}
                  </div>
                  <div style={{ flex: 1 }} className="flex justify-content-end">
                    <div className="flex-1 px-4">
                      <label className='mb-2 block text-xs'>Torneio {alarm?.buyInWithCurrency}</label>
                      <p
                        title={alarm?.name}
                        className={`text-xl`}
                        style={{
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          maxWidth: 130
                        }}>{alarm?.name}</p>
                    </div>
                    <div className="px-4">
                      <label className='mb-2 block text-xs'>Horário</label>
                      <p className='text-xl'>{alarm?.hourScheduledStartDate}</p>
                    </div>
                  </div>
                </div>

                <div className='flex gap-4 justify-content-start align-items-center text-yellow-300'>
                  <div className='flex gap-41justify-content-center align-items-center'>
                    <i
                      className='pi pi-bell mr-3' style={{
                        fontSize: 22
                      }}
                    />
                    <span>{alarm?.alarmScheduledStartDate !== null ? 'Início do Torneio' : ''}</span>
                    <span>{alarm?.alarmMaxLate !== null ? 'Max. Late' : ''}</span>
                    {alarm?.alarmBlindsEntry !== null ?
                      <div>
                        Entrada de Blinds
                        <span className="ml-2 font-mylobby text-xl">{alarm?.alarmBlindsEntry} BB
                          <FontAwesomeIcon icon={solid('coins')} className="ml-1 text-yellow-300" size="sm" />
                        </span>
                      </div> : ''}
                  </div>
                </div>
              </div>
              <Divider className='mb-2' />
            </div>
          )
        })
      }
    </Dialog>
  )
}

export default memo(AlarmWebsocketDialog)
