import { regular, solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import EnumSubscription from "../helpers/core/enumerator/EnumSubscription";

export const subscriptionType = (profile) => {
    let userProfile = {
        icon: regular("user"),
        title: "Não definido"
    }
    switch (profile) {
        case EnumSubscription.OWNER.profile:
            userProfile.icon = solid("user-tie");
            userProfile.title = EnumSubscription.OWNER.name;
            break;
        case EnumSubscription.MANAGER.profile:
            userProfile.icon = solid("user-secret");
            userProfile.title = EnumSubscription.MANAGER.name;
            break;
        case EnumSubscription.SUPPORT.profile:
            userProfile.icon = solid("user-gear");
            userProfile.title = EnumSubscription.SUPPORT.name;
            break;
        case EnumSubscription.MPLAYER.profile:
            userProfile.icon = solid("user-large");
            userProfile.title = EnumSubscription.MPLAYER.name;
            break;
        case EnumSubscription.LPLAYER.profile:
            userProfile.icon = solid("user-minus");
            userProfile.title = EnumSubscription.LPLAYER.name;
            break;
        case EnumSubscription.HPLAYER.profile:
            userProfile.icon = solid("user-plus");
            userProfile.title = EnumSubscription.HPLAYER.name;
            break;
        case EnumSubscription.FPLAYER.profile:
            userProfile.icon = solid("user");
            userProfile.title = EnumSubscription.FPLAYER.name;
            break;
        case EnumSubscription.TPLAYER.profile:
            userProfile.icon = solid("users");
            userProfile.title = EnumSubscription.TPLAYER.name;
            break;
        default:
            break;
    }
    return (
        <React.Fragment>
            <FontAwesomeIcon icon={userProfile.icon} title={userProfile.title} />
        </React.Fragment>
    );
}