import { DateTime } from 'luxon';
import { Calendar } from 'primereact/calendar';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { InputSwitch } from 'primereact/inputswitch';
import { MultiSelect } from 'primereact/multiselect';
import React, { useCallback, useEffect, useState } from 'react';
import { isArray, isArrayEmpty, isUndefined } from '../helpers/core/util/ObjectUtil';
import { useAuth } from '../hooks/useAuth';
import FilterCheckBox from './FilterCheckBox';
import FilterNetwork from './FilterNetwork';
import FilterNumber from './FilterNumber';
// import FiltersHour from './FiltersHour';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { Tag } from 'primereact/tag';
import MyLobbyService from '../service/MyLobbyService';
import GetCaseByActiveIndex from './FormMultiStep/GetCaseByActiveIndex';
import { Tooltip } from 'primereact/tooltip';

const Filters = (props) => {
    const { user } = useAuth();
    const [disableFilterStraight, setDisableFilterStraight] = useState(true);
    const [filterWeek, setFilterWeek] = useState('');
    const [filterPlayersPerTable, setFilterPlayersPerTable] = useState('');
    const [filters] = useState([]);
    const [favorite, setFavorite] = useState(false);
    const [alarm, setAlarm] = useState(false);
    const [maxLate, setMaxLate] = useState(false);
    const [showFormPrioridades, setShowFormPrioridades] = useState(false);
    const [subs, setSubs] = useState([]);
    const [sub, setSub] = useState('');
    const [straights, setStraights] = useState([]);
    const [straight, setStraight] = useState('');
    const [managerTeam, setManagerTeam] = useState('');
    const [managerTeamFilter, setManagerTeamFilter] = useState([]);
    const [selectedLateRegister, setSelectedLateRegister] = useState([]);

    const prioridades = [
        { name: 'Alta', key: 'A' },
        { name: 'Média', key: 'M' },
        { name: 'Baixa', key: 'B' }
    ];
    const speeds = [
        { name: 'Regular', key: 'R' },
        { name: 'Turbo', key: 'Tur' },
        { name: 'Hyper', key: 'Hyp' }
    ];

    const tournamentStructures = [
        { name: 'Regular', key: 'Reg' },
        { name: 'Bounty', key: 'Bou' }
    ];

    const playersPerTable = [
        { name: '7 a 10 (full ring)', key: 'geSeven' },
        { name: '6-max', key: 'eqSix' },
        { name: '3 a 5-max', key: 'geThreeleFive' },
        { name: '2 (heads-up)', key: 'leTwo' }
    ];

    const lateRegisters = [
        { name: 'Late Register', key: 'late' },
        { name: 'Closed', key: 'end' },
        { name: 'Registering', key: 'reg' },
        { name: 'Completed', key: 'comp' },
        { name: 'LR+Registering', key: 'late,reg' }
    ]

    //const retas = ['--selecione--', /*...new Set(user?.straights.map(reta => reta.line))*/];

    //const rangeDiaInicial = 1; // menor dia para range de pesquisa
    //const rangeDiaFinal = 7; // maior dia para range de pesquisa

    const getStraightByManager = useCallback(async (manager) => {
        MyLobbyService.getUser({
            email: user?.email,
            accessToken: user?.accessToken,
            name: manager
        }).then(response => {
            return response.data;
        }).then(data => {
            setManagerTeam(data);
            setStraights([...new Set(data?.straights.map(reta => reta.line))]);
            setSubs([]);
        });
    }, [user]);

    const handlerFilterValue = (value, filterByField, event) => {
        let filterValueSelect = value;
        if (filterByField === 'weekScheduledStartDate') {
            setFilterWeek(filterValueSelect);
        }
        if (filterByField === 'playersPerTable') {
            setFilterPlayersPerTable(filterValueSelect);
            let _filterValueSelect = filterValueSelect.map((item) => {
                return item.key;
            });
            filterValueSelect = _filterValueSelect;
        }
        if (filterByField === 'favorites') {
            setFavorite(filterValueSelect);
        }
        if (filterByField === 'alarm') {
            setAlarm(filterValueSelect);
        }
        if (filterByField === 'maxLate') {
            setMaxLate(filterValueSelect);
        }
        if (filterByField === 'lateRegister' && !isUndefined(event)) {
            filterValueSelect = [...selectedLateRegister];

            if (event.checked) {
                filterValueSelect.push(event.value);
            } else {
                filterValueSelect = filterValueSelect.filter((item) => item.key !== event.value.key);
            }
            setSelectedLateRegister(filterValueSelect);
            filterValueSelect = !isArrayEmpty(filterValueSelect) ? filterValueSelect?.map((item) => {
                return item.key
            }) : null;
        }
        if (!isArray(filterValueSelect)) {
            filterValueSelect = filterValueSelect !== null ? [filterValueSelect] : null;
        }
        let filter = { field: filterByField, value: filterValueSelect };
        let _filters = filters;
        if (!isArrayEmpty(props.filter)) {
            _filters = [...props.filter];
        }
        if (!isArrayEmpty(_filters)) {
            let hasField = _filters.filter((fl) => fl.field === filterByField);
            if (isArrayEmpty(hasField)) {
                _filters.push(filter);
            } else {
                _filters = _filters.map((fl) => {
                    if (fl.field === filterByField) {
                        fl.value = filterValueSelect;
                    }
                    return fl;
                });
            }
        } else {
            _filters.push(filter);
        }
        props.onFilter(_filters);
    };

    useEffect(() => {
        let _managerTeamFilter = props.filter?.map((manager => {
            if (manager.field === 'manager') {
                return manager?.value;
            }
            return [];
        })).shift() ?? [];
        setManagerTeamFilter(_managerTeamFilter);
        if (!isArrayEmpty(_managerTeamFilter) && managerTeamFilter !== _managerTeamFilter) {
            setStraight('');
            getStraightByManager(_managerTeamFilter);
        }
        // eslint-disable-next-line
    }, [props.filter, getStraightByManager]);

    useEffect(() => {
        setDisableFilterStraight(user?.usersView?.straightFilter);
        if (!isArrayEmpty(user?.straights)) {
            setStraights([...new Set(user?.straights?.map(reta => reta.line))]);
        } else {
            setStraights([...new Set(managerTeam?.straights?.map(reta => reta.line))])
        }
    }, [user, managerTeam]);

    useEffect(() => {
        let filterField = props.onClearField;
        if (user?.usersView?.straightFilter) {
            let _reta = user?.straights[0]?.line;
            setStraight(_reta);
            buscaSubReta(_reta);
            setSub(user?.straights[0]?.sub);
        }
        if (props.onClearField !== null) {
            let _filters = props.filter;
            _filters.map((item) => {
                if (item.field === filterField) {
                    item.value = [];
                }
                return item;
            });
            if (filterField === 'straight') {
                setStraight('');
                setSub('');
                setManagerTeam('');
            }
            handlerFilterValue([], filterField);
            props.onClearFilter();
        }
        // eslint-disable-next-line
    }, [user, props.onClearField]);

    const handleMinDate = () => {
        return DateTime.fromISO(user?.settings?.minDateSearch).toJSDate();
    };

    const handleMaxDate = () => {
        return DateTime.fromISO(user?.settings?.maxDateSearch).toJSDate();
    };

    const gerarPrioridades = () => {
        setShowFormPrioridades(true);
    }

    /*const buscaReta = (selectedManagerTeam) => {
        setManagerTeam({ name: selectedManagerTeam });
        getStraightByManager(selectedManagerTeam);
    }*/

    const buscaSubReta = (selectedStraight) => {
        let _retas = [];
        if (!isArrayEmpty(user?.straights)) {
            _retas = user?.straights?.filter(({ line }) => line === selectedStraight)
        } else {
            _retas = managerTeam?.straights?.filter(({ line }) => line === selectedStraight)
        }
        let _subs = [];
        _retas?.map((reta) => {
            _subs.push(reta.sub);
            return _subs;
        });
        setStraight(selectedStraight);
        setSub('');
        setSubs([..._subs]);
    };

    const handleFilterStraight = (selectedStraight, filterByField) => {
        setSub(selectedStraight);
        let _filter = [];
        _filter.push(straight);
        _filter.push(selectedStraight);
        handlerFilterValue(_filter, filterByField);
    };

    const retaTemplate = (filterByField) => {
        return (
            <React.Fragment>
                <div className="flex flex-wrap gap-2">
                    <small>Reta: </small>
                    <Dropdown value={straight} options={straights} className="w-15rem" onChange={(e) => buscaSubReta(e.value)} disabled={disableFilterStraight} />
                    <small>Sub-reta: </small>
                    <Dropdown value={sub} options={subs} className="w-15rem" onChange={(e) => handleFilterStraight(e.value, filterByField)} disabled={disableFilterStraight} />
                </div>
            </React.Fragment>
        );
    };

    const alarmeTemplate = (filterByField) => {
        return (
            <React.Fragment>
                <div className="flex align-items-center flex-wrap">
                    <InputSwitch checked={alarm} className="mr-2" onChange={(e) => handlerFilterValue(e.value, filterByField)} />
                    <small> Mostrar alarme</small>
                </div>
            </React.Fragment>
        );
    };

    const favoritosTemplate = (filterByField) => {
        return (
            <React.Fragment>
                <div className="flex align-items-center flex-wrap">
                    <InputSwitch checked={favorite} className="mr-2" onChange={(e) => handlerFilterValue(e.value, filterByField)} />
                    <small> Mostrar favoritos</small>
                </div>
            </React.Fragment>
        );
    };

    const prioridadesTemplate = (filterByField) => {
        return (
            <React.Fragment>
                {user?.usersView?.prioritiesFilter && <FilterCheckBox filterByField={filterByField} items={prioridades} handlerFilterValue={handlerFilterValue} withBadge={true} />}

                <div className={user?.usersView?.prioritiesFilter ? 'mt-4 px-4' : ''}>
                    <Button
                        className='p-button-text w-full flex align-items-center justify-content-center bg-yellow-300 border-yellow-300 text-gray-900'
                        onClick={gerarPrioridades}
                        icon='pi pi-verified '
                        label='Gerar Prioridades'
                        visible={user?.usersView?.prioritiesFilter} />
                </div>
            </React.Fragment>
        );
    };

    const sitesTemplate = (filterByField) => {
        return (
            <React.Fragment>
                <FilterNetwork filterByField={filterByField} handlerFilterValue={handlerFilterValue} />
            </React.Fragment>
        );
    };

    const weekTemplate = (filterByField) => {
        return (
            <React.Fragment>
                <Calendar
                    value={filterWeek}
                    onChange={(e) => handlerFilterValue(e.value, filterByField)}
                    selectionMode="range"
                    showButtonBar
                    showIcon
                    minDate={handleMinDate()}
                    maxDate={handleMaxDate()}
                    readOnlyInput
                //style={{ width: '200px' }}
                // className="p-inputtext-sm"
                />
            </React.Fragment>
        );
    };

    // const horarioTemplate = (filterByField) => {
    //     return (
    //         <React.Fragment>
    //             <FiltersHour filterByField={filterByField} handlerFilterValue={handlerFilterValue} />
    //         </React.Fragment>
    //     );
    // };

    const fimTorneioTemplate = (filterByField) => {
        return (
            <React.Fragment>
                <FilterNumber locale="en-US" minFractionDigits={0} placeholder="Início|Fim" filterByField={filterByField} handlerFilterValue={handlerFilterValue} showButtons={false} min={0} max={23} type="range" />
            </React.Fragment>
        );
    };

    const buyInTemplate = (filterByField) => {
        return (
            <React.Fragment>
                <FilterNumber locale="en-US" minFractionDigits={2} placeholder="Min.|Max." filterByField={filterByField} handlerFilterValue={handlerFilterValue} showButtons={false} type="range" />
            </React.Fragment>
        );
    };

    const totalEntrantsTemplate = (filterByField) => {
        return (
            <React.Fragment>
                <FilterNumber locale="en-US" minFractionDigits={0} placeholder="Min.|Max." filterByField={filterByField} handlerFilterValue={handlerFilterValue} showButtons={false} type="range" />
            </React.Fragment>
        );
    };

    const guaranteeTemplate = (filterByField) => {
        return (
            <React.Fragment>
                <Tooltip target=".info-target-icon" />
                <div className="p-inputgroup mb-2">
                    <span className="p-inputgroup-addon bg-red-500 border-red-500">
                        <i className="pi pi-dollar font-bold"></i>
                    </span>
                    <FilterNumber locale="en-US" minFractionDigits={2} filterByField={filterByField} handlerFilterValue={handlerFilterValue} />
                </div>
                <i className="info-target-icon pi pi-info-circle"
                    data-pr-tooltip="Pressione ENTER pra Filtrar"
                    data-pr-position="bottom">
                </i>
            </React.Fragment>
        );
    };

    const prizeTemplate = (filterByField) => {
        return (
            <React.Fragment>
                <Tooltip target=".info-target-icon" />
                <div className="p-inputgroup mb-2">
                    <span className="p-inputgroup-addon bg-red-500 border-red-500">
                        <i className="pi pi-dollar font-bold"></i>
                    </span>
                    <FilterNumber locale="en-US" minFractionDigits={2} filterByField={filterByField} handlerFilterValue={handlerFilterValue} />
                </div>
                <i className="info-target-icon pi pi-info-circle"
                    data-pr-tooltip="Pressione ENTER pra Filtrar"
                    data-pr-position="bottom">
                </i>
            </React.Fragment>
        );
    };

    const speedTemplate = (filterByField) => {
        return (
            <React.Fragment>
                <FilterCheckBox filterByField={filterByField} items={speeds} handlerFilterValue={handlerFilterValue} />
            </React.Fragment>
        );
    };

    const tournamentStructureTemplate = (filterByField) => {
        return (
            <React.Fragment>
                <FilterCheckBox filterByField={filterByField} items={tournamentStructures} handlerFilterValue={handlerFilterValue} />
            </React.Fragment>
        );
    };

    const entBlindsTemplate = () => {
        return (
            <React.Fragment>
                <div className="flex flex-wrap gap-3">
                    <small>Regular</small>
                    <div>
                        <FilterNumber locale="en-US" minFractionDigits={0} useGrouping={false} placeholder="Min.|Max." filterByField='entBlindsRegular' handlerFilterValue={handlerFilterValue} showButtons={false} type="range" />
                    </div>
                </div>
                <div className="flex flex-wrap gap-3 mt-4">
                    <small>Bounty</small>
                    <div>
                        <FilterNumber locale="en-US" minFractionDigits={0} useGrouping={false} placeholder="Min.|Max." filterByField='entBlindsBounty' handlerFilterValue={handlerFilterValue} showButtons={false} type="range" />
                    </div>
                </div>
            </React.Fragment>
        );
    }

    const maxLateTemplate = (filterByField) => {
        return (
            <React.Fragment>
                <div className="flex align-items-center flex-wrap">
                    <InputSwitch checked={maxLate} className="mr-2" onChange={(e) => handlerFilterValue(e.value, filterByField)} />
                    <small> Último minuto</small>
                </div>
            </React.Fragment>
        );
    };

    const playersPerTableLateTemplate = (filterByField) => {
        return (
            <React.Fragment>
                <div className="flex align-items-center flex-wrap">
                    <MultiSelect value={filterPlayersPerTable} onChange={(e) => handlerFilterValue(e.value, filterByField)} options={playersPerTable} optionLabel="name" display="chip" maxSelectedLabels={3} className="w-full" />
                </div>
            </React.Fragment>
        );
    };

    const lateRegisterTemplate = (filterByField) => {
        return (
            <React.Fragment>
                {lateRegisters.map((item) => {
                    return (
                        <div key={item.key} className="flex align-items-center pt-2">
                            <Checkbox inputId={item.key} value={item} name="item" onChange={(e) => handlerFilterValue(e.value, filterByField, e)}
                                checked={selectedLateRegister.some((it) => it.key === item.key)} disabled={selectedLateRegister.some((it) => it.key !== item.key)} />
                            <label htmlFor={item.key} className="ml-2">
                                {item.key === 'late' && <Tag value="Late Register"></Tag>}
                                {item.key === 'end' && <Tag severity="danger" value="Closed"></Tag>}
                                {item.key === 'reg' && <Tag severity="info" value="Registering"></Tag>}
                                {item.key === 'comp' && <Tag severity="warning" value="Completed"></Tag>}
                                {item.key === 'late,reg' && (
                                    <>
                                        <Tag value="LR" /> <i className="pi pi-plus" style={{ fontSize: '0.65rem' }}></i> <Tag severity="info" value="Registering" />
                                    </>
                                )}
                            </label>
                        </div>
                    );
                })}
            </React.Fragment>
        );
    };

    const PrioridadesHeader = () => {
        return (
            <div className="flex align-items-center justify-content-between">
                <div className="flex align-items-center">
                    <i
                        className="pi pi-verified mr-3"
                        style={{
                            fontSize: 22
                        }}
                    ></i>
                    <strong className="">Prioridades</strong>
                </div>
            </div>
        );
    };

    return (
        <React.Fragment>
            <div className="layout-menu">
                {props.items.map((item, i) => {
                    return (
                        <div key={item.label || i} className={!item.isVisible ? "hidden" : ""}>
                            <ul key={item.label || i} className={item.className} role="menu">
                                <li key={item.label || i} className={item.menuitemClassName} role="menuitem">
                                    {item.link}
                                    {!item.contentEmpty && (
                                        <div className="grid pl-3 pt-3">
                                            <div className="col">
                                                {item.filterByField === 'straight' && retaTemplate(item.filterByField)}
                                                {item.filterByField === 'alarm' && alarmeTemplate(item.filterByField)}
                                                {item.filterByField === 'favorites' && favoritosTemplate(item.filterByField)}
                                                {item.filterByField === 'priorities' && prioridadesTemplate(item.filterByField)}
                                                {item.filterByField === 'network' && sitesTemplate(item.filterByField)}
                                                {item.filterByField === 'weekScheduledStartDate' && weekTemplate(item.filterByField)}
                                                {/* {item.filterByField === 'hourScheduledStartDate' && horarioTemplate(item.filterByField)} */}
                                                {item.filterByField === 'duration' && fimTorneioTemplate(item.filterByField)}
                                                {item.filterByField === 'buyin' && buyInTemplate(item.filterByField)}
                                                {item.filterByField === 'totalEntrants' && totalEntrantsTemplate(item.filterByField)}
                                                {item.filterByField === 'guarantee' && guaranteeTemplate(item.filterByField)}
                                                {item.filterByField === 'prize' && prizeTemplate(item.filterByField)}
                                                {item.filterByField === 'speed' && speedTemplate(item.filterByField)}
                                                {item.filterByField === 'tournamentStructure' && tournamentStructureTemplate(item.filterByField)}
                                                {item.filterByField === 'entBlinds' && entBlindsTemplate()}
                                                {item.filterByField === 'maxLate' && maxLateTemplate(item.filterByField)}
                                                {item.filterByField === 'playersPerTable' && playersPerTableLateTemplate(item.filterByField)}
                                                {item.filterByField === 'lateRegister' && lateRegisterTemplate(item.filterByField)}
                                                {/* {item.filterByField === 'entBlindsRegular' && entBlindsRegularTemplate(item.filterByField)} */}
                                                {/* {item.filterByField === 'entBlindsBounty' && entBlindsBountyTemplate(item.filterByField)} */}
                                            </div>
                                        </div>
                                    )}
                                </li>
                            </ul>
                        </div>
                    );
                })}
            </div>

            <Dialog
                header={<PrioridadesHeader />}
                visible={showFormPrioridades}
                onHide={() => setShowFormPrioridades(false)}
                style={{ width: '60vw' }}
            >
                <GetCaseByActiveIndex setShowFormPrioridades={setShowFormPrioridades} showFormPrioridades={showFormPrioridades} />
            </Dialog>
        </React.Fragment>
    );
};

export default Filters;
