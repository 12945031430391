import { Badge } from 'primereact/badge';
import { Button } from 'primereact/button';
import { Image } from 'primereact/image';
import { Ripple } from 'primereact/ripple';
import { classNames } from 'primereact/utils';
import React, { createRef, forwardRef, useCallback, useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import Filters from './components/Filters';
import { isArrayEmpty } from './helpers/core/util/ObjectUtil';

const AppSubmenu = forwardRef((props, ref) => {
    const [activeIndex, setActiveIndex] = useState(null);
    const [filterByField, setFilterByField] = useState(null);
    const [filter, setFilter] = useState([]);
    // const [filterNetwork, setFilterNetwork] = useState(null);

    const onMenuItemClick = (event, item, index) => {
        if (item.disabled || !item.isActive || !item.visibled) {
            event.preventDefault();
            return;
        }

        // execute command
        if (item.command) {
            item.command({ originalEvent: event, item });
            event.preventDefault();
        }

        if (item.items) {
            event.preventDefault();
        }

        if (props.root) {
            props.onRootMenuItemClick({
                originalEvent: event
            });
        } else {
            if (props.menuMode !== 'sidebar') {
                const ink = getInk(event.currentTarget);
                if (ink) {
                    removeClass(ink, 'p-ink-active');
                }
            }
        }

        setActiveIndex(index === activeIndex ? null : index);

        props.onMenuItemClick({
            originalEvent: event,
            item
        });
    };

    const onClearField = (filterField) => {
        setFilterByField(filterField);
    };

    const onClearFilter = () => {
        setFilterByField(null);
    };

    const onKeyDown = (event, item, index) => {
        if (event.key === 'Enter') {
            onMenuItemClick(event, item, index);
        }
    };

    const getInk = (el) => {
        for (let i = 0; i < el.children.length; i++) {
            if (typeof el.children[i].className === 'string' && el.children[i].className.indexOf('p-ink') !== -1) {
                return el.children[i];
            }
        }
        return null;
    };

    const removeClass = (element, className) => {
        if (element.classList) element.classList.remove(className);
        else element.className = element.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
    };

    const onMenuItemMouseEnter = (index) => {
        if (props.root && props.menuActive && isHorizontalOrSlim() && !isMobile()) {
            setActiveIndex(index);
        }
    };

    const isMobile = () => {
        return window.innerWidth <= 1025;
    };

    const isHorizontalOrSlim = useCallback(() => {
        return props.menuMode === 'horizontal' || props.menuMode === 'slim';
    }, [props.menuMode]);

    const visible = (item) => {
        return typeof item.visible === 'function' ? item.visible() : item.visible !== false;
    };

    const getLink = (item, index) => {
        const menuitemIconClassName = classNames('layout-menuitem-icon', item.icon);
        const content = (
            <React.Fragment>
                <i
                    style={{
                        fontSize: item.sizeIcon
                    }}
                    className={menuitemIconClassName}
                />
                <span className="layout-menuitem-text">{item.label}</span>
                <Button className="p-button-rounded p-button-plain p-button-text" icon="pi pi-filter-slash" visible={item.showClearFilter} onClick={(e) => onClearField(item.filterByField)} />
                {item.items && <i className="pi pi-fw pi-chevron-down  layout-submenu-toggler" />}
                {item.badge && <Badge value={item.badge} style={{ height: '100%' }} />}
                <Ripple />
            </React.Fragment>
        );
        const commonLinkProps = {
            style: item.style,
            className: classNames(item.className, 'p-ripple', {
                'p-disabled': item.disabled
            }),
            target: item.target,
            onClick: (e) => onMenuItemClick(e, item, index),
            onMouseEnter: () => onMenuItemMouseEnter(index),
            onKeyDown: (e) => onKeyDown(e, item, index)
        };

        if (item.to) {
            return (
                <NavLink to={item.to} {...commonLinkProps} className={({ isActive }) => classNames(commonLinkProps.className, isActive ? 'active-route' : undefined)}>
                    {content}
                </NavLink>
            );
        } else {
            return (
                <a href={item.url} rel="noopener noreferrer" tabIndex={item.url ? '' : 0} {...commonLinkProps}>
                    {content}
                </a>
            );
        }
    };

    const getItems = () => {
        const transitionTimeout = isHorizontalOrSlim() && !props.root ? { enter: 1000, exit: 450 } : isHorizontalOrSlim() && !isMobile() ? 0 : { enter: 1000, exit: 450 };
        const items = props.items.map((item, i) => {
            if (visible(item)) {
                const submenuRef = createRef();
                const menuitemClassName = classNames({
                    'layout-root-menuitem': props.root,
                    'active-menuitem': activeIndex === i && !item.disabled
                });
                const link = getLink(item, i);
                const tooltip = (
                    <div className="layout-menu-tooltip">
                        <div className="layout-menu-tooltip-arrow" />
                        <div className="layout-menu-tooltip-text">{item.label}</div>
                    </div>
                );

                if (!item.isCustomMenu) {
                    return (
                        <li key={item.label || i} className={menuitemClassName} role="menuitem">
                            {link}
                            {tooltip}
                            <CSSTransition nodeRef={submenuRef} classNames="p-toggleable-content" timeout={transitionTimeout} in={activeIndex === i} unmountOnExit>
                                <AppSubmenu ref={submenuRef} items={visible(item) && item.items} menuActive={props.menuActive} menuMode={props.menuMode} parentMenuItemActive={activeIndex === i} onMenuItemClick={props.onMenuItemClick} />
                            </CSSTransition>
                        </li>
                    );
                } else {
                    // monta o menu myLobby
                    return {
                        filterByField: item.filterByField,
                        link,
                        tooltip,
                        menuitemClassName,
                        className: props.className,
                        ...item
                    };
                }
            }

            return null;
        });

        return items;
    };

    useEffect(() => {
        if (props.resetActiveIndex && isHorizontalOrSlim()) {
            setActiveIndex(null);
        }
    }, [props.resetActiveIndex, isHorizontalOrSlim]);

    useEffect(() => {
        if (!props.menuActive && isHorizontalOrSlim()) {
            setActiveIndex(null);
        }
    }, [props.menuActive, isHorizontalOrSlim]);

    useEffect(() => {
        setFilter(props.filter);
    }, [props.filter])


    if (!props.items) {
        return null;
    }

    const items = getItems();

    // apenas para o menu myLobby
    const menuMyLobby = !isArrayEmpty(
        items.filter((item) => {
            return item.filterByField;
        })
    );

    return (
        <>
            {!menuMyLobby && (
                <ul ref={ref} className={props.className} role="menu">
                    {items}
                </ul>
            )}
            {menuMyLobby && <Filters items={items} onFilter={props.onFilter} filter={filter} onClearField={filterByField} onClearFilter={onClearFilter} />}
        </>
    );
});

const AppMenu = (props) => {
    // const navigate = useNavigate();

    const [filter, setFilter] = useState([]);

    const onSidebarMouseOver = () => {
        if (props.menuMode === 'sidebar' && !props.sidebarStatic) {
            props.onSidebarMouseOver();
        }
    };

    const onSidebarMouseLeave = () => {
        if (props.menuMode === 'sidebar' && !props.sidebarStatic) {
            setTimeout(() => {
                props.onSidebarMouseLeave();
            }, 250);
        }
    };

    useEffect(() => {
        setFilter(props.filter);
    }, [props.filter]);


    return (
        <div
            className={classNames('menu-wrapper', {
                'layout-sidebar-active': props.sidebarActive
            })}
            onClick={props.onMenuClick}
            onMouseOver={onSidebarMouseOver}
            onMouseLeave={onSidebarMouseLeave}
        >
            {/* <div className="flex justify-content-end">
                <div className="sidebar-logo">
                    <button className="sidebar-pin p-link" onClick={(event) => props.onToggleMenu(event)}>
                        <span className="pin"></span>
                    </button>
                </div>
            </div> */}

            <div className="flex justify-content-center pt-3 pb-2 pl-3 pr-3">
                <Image src="assets/layout/images/logo.png" width="200" />
            </div>
            {props.filterActive && (
                <div className="layout-menu-container">
                    <AppSubmenu
                        items={props.model}
                        className="layout-menu"
                        menuActive={props.menuActive}
                        onRootMenuItemClick={props.onRootMenuItemClick}
                        mobileMenuActive={props.mobileMenuActive}
                        onMenuItemClick={props.onMenuItemClick}
                        root
                        menuMode={props.menuMode}
                        parentMenuItemActive
                        resetActiveIndex={props.resetActiveIndex}
                        onFilter={props.onFilter}
                        filter={filter}
                    />
                </div>
            )}
        </div>
    );
};

export default AppMenu;
