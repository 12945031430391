import { InputNumber } from "primereact/inputnumber"
import React from "react"
import { formatValueByMinAndMax } from "../../helpers/GetValueEntryMinOrMax"

const MIN_INPUT_NUMBER_VALUE = 1
const MAX_INPUT_NUMBER_VALUE = 6

const SevenStepForm = ({ dispatch, state }) => {

    const handleChangeFieldValue = (value, name, index) => {
        dispatch({
            type: 'change-field-in-punctuation',
            field: name,
            payload: formatValueByMinAndMax(
                value,
                MIN_INPUT_NUMBER_VALUE,
                MAX_INPUT_NUMBER_VALUE
            ),
            index,
        })
    }

    const handleChangeField = (value, name, index) => {
        dispatch({
            type: 'change-field-in-punctuation',
            field: name,
            payload: value,
            index,
        })
    }

    return (
        <div className="mb-8">
            <h4 className="mb-6">Qual pontuação para cada Field? (1 a 6 pontos)</h4>
            {
                state?.fields?.collection?.map((el, i) => {
                    let verificacao = state?.fields?.collection?.filter(field => {
                            return field?.min === el.min && field?.max === el.max && el.min !== '' && el.max !== ''
                    }).length > 1;

                    let verificacaoPontuacao = state?.fields?.collection?.filter(field => {
                        return field?.value === el.value && el?.value !== ''
                    }).length > 1;

                    let verificaValorMin = Number(el?.min) !== '' && Number(el?.max) !== '' && Number(el?.min) > Number(el?.max)

                    if (i === 0) {
                        return (
                            <div key={i}>
                                <div className="field grid">
                                    <label className="col-fixed" style={{ width: "12%" }}>Valores:</label>
                                    <small>({i + 1})</small>
                                    <div className="col-2 md:col-2">
                                        <InputNumber
                                            min={MIN_INPUT_NUMBER_VALUE}
                                            placeholder="Min."
                                            inputClassName="md:w-5rem lg:w-6rem xl:w-9rem"
                                            name="min"
                                            required
                                            value={el.min}
                                            onChange={e => handleChangeField(
                                                e.value,
                                                e.originalEvent.target.name,
                                                i,
                                            )}
                                            mode="decimal" locale="pt-BR" minFractionDigits={0}
                                        />
                                    </div>
                                    <div className="col-2 md:col-2">
                                        <InputNumber
                                            min={MIN_INPUT_NUMBER_VALUE}
                                            placeholder="Max."
                                            inputClassName="md:w-5rem lg:w-6rem xl:w-9rem"
                                            name="max"
                                            required
                                            value={el.max}
                                            onChange={e => handleChangeField(
                                                e.value,
                                                e.originalEvent.target.name,
                                                i,
                                            )}
                                            mode="decimal" locale="pt-BR" minFractionDigits={0}
                                        />
                                    </div>
                                    <label className="col-fixed" style={{ width: "12%" }}>Pontuação:</label>
                                    <small>({i + 1})</small>
                                    <div className="col-2 md:col-2 p-inputgroup">
                                        <InputNumber
                                            min={MIN_INPUT_NUMBER_VALUE}
                                            max={MAX_INPUT_NUMBER_VALUE}
                                            placeholder={`${MIN_INPUT_NUMBER_VALUE} à ${MAX_INPUT_NUMBER_VALUE} pontos`}
                                            inputClassName="md:w-5rem lg:w-6rem xl:w-9rem"
                                            name="value"
                                            required
                                            suffix=" pontos"
                                            value={el.value}
                                            onChange={e => handleChangeFieldValue(
                                                e.value,
                                                e.originalEvent.target.name,
                                                i,
                                            )}
                                        />
                                        <span className="p-inputgroup-addon text-gray-900 bg-yellow-300 border-yellow-300">
                                            <i className="pi pi-sort-amount-up"></i>
                                        </span>
                                    </div>
                                    {
                                        verificacao &&
                                        <p className="text-red-100 mt-1 mb-0">Min. e Max duplicados</p>
                                    }
                                    {
                                        verificacaoPontuacao && !verificacao &&
                                        <p className="text-red-100 mt-1 mb-0">Mesmo valor de pontos</p>
                                    }
                                    {
                                        verificaValorMin && !verificacaoPontuacao && !verificacao &&
                                        <p className="text-red-100 mt-1 mb-0">Min. maior que Max</p>
                                    }
                                </div>
                            </div>
                        )
                    } else {
                        return (
                            <div key={i}>
                                <div className="field grid">
                                    <label className="col-fixed" style={{ width: "12%" }}></label>
                                    <small>({i + 1})</small>
                                    <div className="col-2 md:col-2">
                                        <InputNumber
                                            min={MIN_INPUT_NUMBER_VALUE}
                                            placeholder="Min."
                                            inputClassName="md:w-5rem lg:w-6rem xl:w-9rem"
                                            name="min"
                                            value={el.min}
                                            onChange={e => handleChangeField(
                                                e.value,
                                                e.originalEvent.target.name,
                                                i,
                                            )}
                                            mode="decimal" locale="pt-BR" minFractionDigits={0}
                                        />
                                    </div>
                                    <div className="col-2 md:col-2">
                                        <InputNumber
                                            min={MIN_INPUT_NUMBER_VALUE}
                                            placeholder="Max."
                                            inputClassName="md:w-5rem lg:w-6rem xl:w-9rem"
                                            name="max"
                                            value={el.max}
                                            onChange={e => handleChangeField(
                                                e.value,
                                                e.originalEvent.target.name,
                                                i,
                                            )}
                                            mode="decimal" locale="pt-BR" minFractionDigits={0}
                                        />
                                    </div>
                                    <label className="col-fixed" style={{ width: "12%" }}></label>
                                    <small>({i + 1})</small>
                                    <div className="col-2 md:col-2  p-inputgroup">
                                        <InputNumber
                                            min={MIN_INPUT_NUMBER_VALUE}
                                            max={MAX_INPUT_NUMBER_VALUE}
                                            placeholder={`${MIN_INPUT_NUMBER_VALUE} à ${MAX_INPUT_NUMBER_VALUE} pontos`}
                                            inputClassName="md:w-5rem lg:w-6rem xl:w-9rem"
                                            name="value"
                                            suffix=" pontos"
                                            value={el.value}
                                            onChange={e => handleChangeFieldValue(
                                                e.value,
                                                e.originalEvent.target.name,
                                                i,
                                            )}
                                        />
                                        <span className="p-inputgroup-addon text-gray-900 bg-yellow-300 border-yellow-300">
                                            <i className="pi pi-sort-amount-up"></i>
                                        </span>
                                    </div>
                                    {
                                        verificacao &&
                                        <p className="text-red-100 mt-1 mb-0">Min. e Max. duplicados</p>
                                    }
                                    {
                                        verificacaoPontuacao && !verificacao &&
                                        <p className="text-red-100 mt-1 mb-0">Mesmo valor de pontos</p>
                                    }
                                    {
                                        verificaValorMin && !verificacaoPontuacao && !verificacao &&
                                        <p className="text-red-100 mt-1 mb-0">Min. maior que Max.</p>
                                    }
                                </div>
                            </div>
                        )
                    }
                })
            }
        </div>
    )
}

export default SevenStepForm
