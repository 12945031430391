import { Button } from 'primereact/button'
import { useEventListener } from 'primereact/hooks'
import { InputText } from 'primereact/inputtext'
import React, { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import AppMesagens from '../AppMesagens'
import EnumTipoMensagem from '../helpers/core/enumerator/EnumTipoMensagem'
import MensagemLista from '../helpers/core/message/MensagemLista'
import { tratarExcecao } from '../helpers/core/util/ExceptionUtil'
import { useAuth } from '../hooks/useAuth'
import MyLobbyService from '../service/MyLobbyService'

function RetrieveAccount () {
  const navigate = useNavigate()
  const { disableValidation } = useAuth()
  const [mensagens, setMensagens] = useState('')
  const [email, setEmail] = useState('')
  const [loading, setLoading] = useState(false)

  const [bindKeyDown, unbindKeyDown] = useEventListener({
    type: 'keydown',
    listener: (e) => {
      if (e.code === 'Enter' || e.code === 'NumpadEnter') {
        retrieveAccount()
      }
    }
  })

  useEffect(() => {
    bindKeyDown()
    disableValidation()
    return () => {
      unbindKeyDown()
    }
  }, [bindKeyDown, unbindKeyDown, disableValidation])

  const retrieveAccount = useCallback(async () => {
    setLoading(true)
    MyLobbyService.postRetrieveAccount({ email })
      .then((response) => {
        setLoading(false)
        const mensagem = new MensagemLista()
        mensagem.simpleMensagem('Verifique sua caixa de e-mail', EnumTipoMensagem.SUCCESS)
        mensagem.simpleMensagem('Finalize o processo de recuperação de senha no e-mail enviado', EnumTipoMensagem.INFO)
        setMensagens(mensagem)
      })
      .catch((error) => {
        const mensagem = tratarExcecao(error)
        setMensagens(mensagem)
        setLoading(false)
      })
  }, [email])

  return (
    <div className='login-body'>
      {mensagens !== '' ? <AppMesagens mensagens={mensagens} position='bottom-center' /> : ''}
      <div className='login-wrapper'>
        <div className='login-panel gap-2'>
          <img src='assets/layout/images/logo.png' className='mb-5' alt='mylobby' width='250' />
          <div className='p-inputgroup'>
            <span className='p-inputgroup-addon bg-red-500 border-red-500' style={{ height: '42px' }}>
              <i className='pi pi-envelope text-white' />
            </span>
            <InputText value={email} placeholder='E-mail' onChange={(e) => setEmail(e.target.value)} className='input-login-mylobby text-0 border-white' autoFocus />
          </div>
          <Button label='Recuperar senha' loading={loading} onClick={retrieveAccount} />
          <p className='text-white'>
            Tem uma conta? <button className='p-link text-red-400' onClick={(e) => { navigate('/', { replace: true }) }}>Conecte-se</button>
          </p>
        </div>
        <div className='login-footer'>
          <h4 className='font-mylobby'>
            MY LO<span className='text-primary'>BB</span>Y
          </h4>
          <h6>Table select na sua mão</h6>
        </div>
        <h6 className='text-sm'>powered by <a href="https://mcp.tec.br" target="_blank" rel="noreferrer" className="text-color">MCP Tec.</a></h6>
      </div>

    </div>
  )
};

export default RetrieveAccount
