import { Menubar } from 'primereact/menubar';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';

const NavHome = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const items = [
    {
      label: 'Home',
      icon: 'pi pi-fw pi-home',
      // use command to navigate to page instead of href
      command: () => {
        // window.location = '/profile'
        navigate('/profile');
      },
      visible: user.usersView?.mainMenu
    },
    {
      label: 'Table Select',
      icon: 'pi pi-fw pi-table',
      command: () => {
        // window.location = '/'
        navigate('/');
      },
      visible: user.usersView?.mainMenu
    },
    {
      label: 'Administrativo',
      icon: 'pi pi-fw pi-desktop',
      items: [
        {
          label: user.usersView?._isTeam ? 'Jogador' : 'Usuários',
          icon: 'pi pi-fw pi-user',
          command: () => {
            // window.location = '/user_account'
            navigate('/user_account');
          },
          visible: user.usersView?.adminMenu

        },
        {
          label: 'Configurações',
          icon: 'pi pi-fw pi-cog',
          visible: user.usersView?.settingsMenu
        },
      ],
      visible: user.usersView?.adminMenu
    }
  ];

  return (
    <div className='flex justify-content-start' >
      <Menubar className="hidden lg:block border-none" model={items} />

      {/* <Menubar model={items} /> */}
    </div >
  )
}

export default NavHome;
