import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro'; // <-- import styles to be used
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DateTime } from 'luxon';
import { FilterMatchMode, PrimeIcons } from 'primereact/api';
import { Badge } from 'primereact/badge';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Column } from 'primereact/column';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { Divider } from 'primereact/divider';
import { Dropdown } from 'primereact/dropdown';
import { useEventListener, useInterval } from 'primereact/hooks';
import { InputNumber } from 'primereact/inputnumber';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { OverlayPanel } from 'primereact/overlaypanel';
import { Skeleton } from 'primereact/skeleton';
import { Tag } from 'primereact/tag';
import { TieredMenu } from 'primereact/tieredmenu';
import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import AppMesagens from '../AppMesagens';
import TimerNextBlind from '../components/TimerNextBlind';
import { removeDuplicatesByProperty } from '../helpers/RemoveDuplicateByProperty';
import { calculaFimRegistro, calculaFimTorneio } from '../helpers/TournamentsHelpers';
import EnumTipoMensagem from '../helpers/core/enumerator/EnumTipoMensagem';
import MensagemLista from '../helpers/core/message/MensagemLista';
import { tratarExcecao } from '../helpers/core/util/ExceptionUtil';
import { percent, usDollar } from '../helpers/core/util/NumberUtil';
import { containsString, isArrayEmpty, isEmpty, isNull, isUndefined } from '../helpers/core/util/ObjectUtil';
import { siteImageTemplate } from '../helpers/core/util/SiteUtil';
import { useAuth } from '../hooks/useAuth';
import MyLobbyService from '../service/MyLobbyService';

const Tournaments = (props) => {
    const { user } = useAuth();
    const [torneios, setTorneios] = useState([]);
    const [torneio, setTorneio] = useState();
    const [selectedTorneios, setSelectedTorneios] = useState(null);
    const [loading, setLoading] = useState(false);
    const [initialLoading, setInitialLoading] = useState(false);
    const [totalRecords, setTotalRecords] = useState(0);
    const [mensagens, setMensagens] = useState('');
    const [rows, setRows] = useState(25);
    const [globalFilterValue, setGlobalFilterValue] = useState('');
    // const [globalFilterValue, debouncedValue, setGlobalFilterValue] = useDebounce('', 5000);//useState('');
    const [withoutSchedulerStartDate, setWithoutSchedulerStartDate] = useState(false);
    const [torneiosRetasAndSub, setTorneiosRetasAndSub] = useState([]);
    const [lazyState, setlazyState] = useState({
        first: 0,
        rows: rows,
        sortField: null,
        sortOrder: null,
        multiSortMeta: [],
        filters: {
            global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            humanLateRegEndDate: { value: null, matchMode: FilterMatchMode.IN },
            withoutSchedulerStartDate
        },
        filtersMenu: [{ field: null, value: null }],
        page: 0,
        pageCount: 0,
        user: {
            email: user?.email,
            accessToken: user?.accessToken
        }
    });
    const [nameTournamentCustomized, setNameTournamentsCustomized] = useState('');
    const [observationTournamentsCustomized, setObservationTournamentsCustomized] = useState('');
    const [fieldCustomized, setFieldCustomized] = useState('');
    const [typeAvAbilityCustomized, setTypeAvAbilityCustomized] = useState('');
    const [prizePositionOneCustomized, setPrizePositionOneCustomized] = useState('');
    const [humanDurationDateCustomized, setHumanDurationDateCustomized] = useState('');
    const [humanLateRegEndDateMinCustomized, setHumanLateRegEndDateMinCustomized] = useState('');
    const [guaranteeCustomized, setGuaranteeCustomized] = useState('');
    const [hourTournamentCustomized, setHourTournamentCustomized] = useState('');
    const [hourTournamentOnly, setHourTournamentOnly] = useState('');
    const [speedCustomized, setSpeedCustomized] = useState('');
    const [tournamentStructureCustomized, setTournamentStructureCustomized] = useState(null);
    const [currencyCustomized, setCurrencyCustomized] = useState(null);
    const [initialChipCustomized, setInitialChipCustomized] = useState(null);
    const [minutesBlindCustomized, setMinutesBlindCustomized] = useState(null);
    const [structureLevelCustomized, setStructureLevelCustomized] = useState(null);
    const [nameBlindsEntry, setNameBlindsEntry] = useState('');
    const [confirmNameDeleteBlindsEntry, setConfirmNameDeleteBlindsEntry] = useState('');
    const optionsCurrency = [
        { type: 'USD', code: '$' },
        { type: 'CAD', code: 'CA$' },
        { type: 'GBP', code: '£' },
        { type: 'EUR', code: '€' },
        { type: 'CNY', code: 'CN¥' }
    ];
    //const humanLateRegEndDate = ['late', 'reg', 'comp', 'end', 'late,reg'];
    const [deleteTournament, setDeleteTournament] = useState('');
    const [deleteTournamentDialog, setDeleteTournamentDialog] = useState(false);
    const [deleteTournamentsDialog, setDeleteTournamentsDialog] = useState(false);
    const [customTournamentsDialog, setCustomTournamentsDialog] = useState(false);
    const [editCustomDialog, setEditCustomDialog] = useState(false);
    const [editBlindsEntryDialog, setEditBlindsEntryDialog] = useState(false);
    const [confirmNameBlindsEntryDialog, setConfirmNameBlindsEntryDialog] = useState(false);
    const [confirmDeleteBlindsEntryDialog, setConfirmDeleteBlindsEntryDialog] = useState(false);
    const [displayDiferenceLateReg, setDisplayDiferenceLateReg] = useState(false);
    const [displayFavoriteGold, setDisplayFavoriteGold] = useState(false);
    const [hasBlindsEntry, setHasBlindsEntry] = useState(false);
    const [, setSeconds] = useState(0);
    //const [blindsEntryStructure, setBlindsEntryStructure] = useState([]);
    const [blindsEntryStructures, setBlindsEntryStructures] = useState([]);
    const [structuresNames, setStructuresNames] = useState([]);
    const [selectedBlindsEntryStructures, setSelectedBlindsEntryStructures] = useState(null);
    const [bindKeyDown, unbindKeyDown] = useEventListener({
        type: 'keydown',
        listener: (e) => {
            if (e.code === 'Enter' || e.code === 'NumpadEnter') {
                if (editCustomDialog) {
                    saveRegisteringTournament();
                }
            }
        }
    });
    const [disableChange, setDisableChange] = useState(false);

    const [diasTorneioSelecionado, setDiasTorneioSelecionado] = useState([]);
    const [displayAlarme, setDisplayAlarme] = useState(false);
    // const [showDaysOfWeekAlarme, setShowDaysOfWeekAlarme] = useState(false);
    const [optionsalarmBlindsEntry, setOptionsalarmBlindsEntry] = useState([]);
    const [deleteAlarmDialog, setDeleteAlarmDialog] = useState(false);
    const [deleteFavoriteDialog, setDeleteFavoriteDialog] = useState(false);
    // const [retaEdicaoState, setRetaEdicaoState] = useState();
    const [showCreateReta, setShowCreateReta] = useState(false);
    const [showEditarReta, setShowEditarReta] = useState(false);
    const [showVincularReta, setShowVincularReta] = useState(false);
    const [clonarReta, setClonarReta] = useState(false);
    const [showVincularJogador, setShowVincularJogador] = useState(false);
    const [deleteReta, setDeleteReta] = useState(false);
    //const [nomeReta, setNomeReta] = useState('');
    const [editarNomeReta, setEditarNomeReta] = useState('');
    //const [subReta, setSubReta] = useState('');
    const [editarSubReta, setEditarSubReta] = useState('');
    const [retaEdicao, setRetaEdicao] = useState('');
    const [subEdicao, setSubEdicao] = useState('');
    const [emailJogador, setEmailJogador] = useState('');
    const [retaVincularJogador, setRetaVincularJogador] = useState(null);
    const [subVincularJogador, setSubVincularJogador] = useState(null);
    const [retaEdicaoTorneios, setRetaEdicaoTorneios] = useState(null);
    const [subEdicaoTorneios, setSubEdicaoTorneios] = useState(null);
    const [retaToClone, setRetaToClone] = useState(null);
    const [subToClone, setSubToClone] = useState(null);
    const [nomeRetaToClone, setNomeRetaToClone] = useState('');
    const [nomeSubToClone, setNomeSubToClone] = useState('');
    const [deleteRetaDialog, setDeleteRetaDialog] = useState(false);
    const [managersTeam, setManagersTeam] = useState([]);
    const [managerTeam, setManagerTeam] = useState(null);
    const [straights, setStraights] = useState([]);
    const tournamentStats = useRef(null);
    const [tournamentStatistics, setTournamentStatistics] = useState({});

    const straightsFormat = useMemo(() => {
        return removeDuplicatesByProperty(
            straights?.map((straight) => {
                return {
                    label: straight?.line,
                    value: straight?.line
                };
            }) ?? [],
            'label'
        );
    }, [straights]);

    const filterTypes = {
        INICIO_TORNEIO: 1,
        ENTRADA_BLINDS: 2,
        MAX_LATE: 3
    };

    const [filterAlarmeTorneio, setFilterAlarmeTorneio] = useState(null);
    const [diasAlarmeTorneio, setDiasAlarmeTorneio] = useState([]);

    const straightsHeaderMenu = [{ label: '--selecione--', value: null }, ...straightsFormat]

    const subsByRetas = useMemo(() => {
        setSubEdicao('');
        return straights?.filter(straight => straight.line === retaEdicao)?.map(straight => {
            return {
                label: straight?.sub,
                value: straight?.sub
            }
        });
    }, [retaEdicao, straights])

    const subsByRetasTorneios = useMemo(() => {
        setSubEdicao('');
        let subs = straights?.filter(straight => straight.line === retaEdicaoTorneios)?.map(straight => {
            return {
                label: straight?.sub,
                value: straight?.sub,
                straightsTournaments: straight?.straightsTournaments ?? []
            }
        });
        return [{ label: '--selecione--', value: null }, ...subs];
    }, [retaEdicaoTorneios, straights])

    const subsByRetasVincularJogador = useMemo(() => {
        setSubVincularJogador(null)
        return straights?.filter(straight => straight.line === retaVincularJogador)?.map(straight => {
            return {
                label: straight?.sub,
                value: straight?.sub
            }
        });
    }, [retaVincularJogador, straights])

    const subsByRetasTorneiosClone = useMemo(() => {
        setSubToClone(null)
        return straights?.filter(straight => straight.line === retaToClone)?.map(straight => {
            return {
                label: straight?.sub,
                value: straight?.sub,
                straightsTournaments: straight?.straightsTournaments ?? []
            }
        });
    }, [retaToClone, straights])

    // TODO substituir por getRetas; endpoint /consultarReta
    const getStraightByUser = useCallback(async () => {
        setLoading(true);
        setDisableChange(true);
        MyLobbyService.getUser({
            email: user?.email,
            accessToken: user?.accessToken,
            player: user?.email,
        }).then((data) => {
            setStraights(data.data?.straights);
        }).catch((error) => {
            let mensagem = tratarExcecao(error);
            setMensagens(mensagem);
        }).finally(() => {
            setLoading(false);
            setDisableChange(false);
        });
    }, [user]);

    const getStraightByManager = useCallback(async (manager) => {
        MyLobbyService.getUser({
            email: user?.email,
            accessToken: user?.accessToken,
            name: manager
        }).then(response => {
            return response.data;
        }).then(data => {
            setManagerTeam(data?.name);
            setStraights(data?.straights);
        }).catch((error) => {
            let mensagem = tratarExcecao(error);
            setMensagens(mensagem);
        }).finally(() => {
            setLoading(false);
            setDisableChange(false);
        });
    }, [user]);

    const loadTeamManagers = useCallback(async () => {
        MyLobbyService.getTeams({
            email: user?.email,
            accessToken: user?.accessToken
        }).then((response) => {
            return response.data;
        }).then((data) => {
            let _managersTeam = [];
            data.map((team) => {
                _managersTeam.push({
                    label: team.name,
                    value: team.manager
                });
                return _managersTeam;
            });
            setManagersTeam([{ label: '--selecione--', value: null }, ..._managersTeam]);
        }).catch((error) => {
        });
    }, [user]);

    const setTorneiosSelecionadosAndListRetas = () => {
        const torneiosRetasAndSubTemporary = subsByRetasTorneios?.find(sub => {
            return sub.label === subEdicaoTorneios
        })?.straightsTournaments ?? []

        const selecaoTorneios = torneios.filter(torneioItem => {
            let torneioIncludeReta = torneiosRetasAndSubTemporary?.filter(torneioReta => {
                return torneioReta.idnetwork === torneioItem.idnetwork &&
                    torneioReta.buyin === torneioItem.buyin &&
                    torneioReta.hourScheduledStartDate === torneioItem.hourScheduledStartDate
            });
            if (torneioIncludeReta.length) {
                return torneioItem;
            }
        })

        setSelectedTorneios(selecaoTorneios)
        setTorneiosRetasAndSub(state => uniqueTournaments([...state, ...torneiosRetasAndSubTemporary]))
    }

    useEffect(() => {
        setSubVincularJogador(null);
    }, [showEditarReta]);

    useEffect(() => {
        if (retaEdicaoTorneios) {
            setSubEdicaoTorneios(null)
        }
        setTorneiosRetasAndSub([]);
        setSelectedTorneios([]);
    }, [retaEdicaoTorneios]);

    useEffect(() => {
        if (subEdicaoTorneios) {
            setTorneiosSelecionadosAndListRetas()
        } else if (!subEdicaoTorneios) {
            setSelectedTorneios([])
        }
    }, [subEdicaoTorneios, torneios]);

    useEffect(() => {
        setRetaToClone(null);
        setSubToClone(null);
    }, [clonarReta]);

    useEffect(() => {
        setRetaVincularJogador(null);
        setSubVincularJogador(null);
    }, [showVincularJogador]);

    useEffect(() => {
        getStraights(managerTeam);
    }, [managerTeam]);

    useEffect(() => {
        if (selectedTorneios?.length) {
            setTorneiosRetasAndSub(state => uniqueTournaments([...state, ...selectedTorneios]))
        }
        let getTorneiosList = torneios?.filter(torn => {
            let list = getTorneiosSelecionadosReta()?.filter(tornItem => {
                if (
                    tornItem?.buyin === torn?.buyin &&
                    tornItem?.hourScheduledStartDate === torn?.hourScheduledStartDate &&
                    tornItem?.idnetwork === torn?.idnetwork
                ) {
                    return tornItem
                }
            });

            if (list?.length) {
                return torn;
            }
        });
        setSelectedTorneios(getTorneiosList);
    }, [torneios]);

    useEffect(() => {
        loadTeamManagers();
    }, []);


    const diasDaSemana = [
        {
            nome: 'Segunda-Feira',
            abreviacao: 'Seg',
            value: 1
        },
        {
            nome: 'Terça-Feira',
            abreviacao: 'Ter',
            value: 2
        },
        {
            nome: 'Quarta-Feira',
            abreviacao: 'Qua',
            value: 3
        },
        {
            nome: 'Quinta-Feira',
            abreviacao: 'Qui',
            value: 4
        },
        {
            nome: 'Sexta-Feira',
            abreviacao: 'Sex',
            value: 5
        },
        {
            nome: 'Sábado',
            abreviacao: 'Sáb',
            value: 6
        },
        {
            nome: 'Domingo',
            abreviacao: 'Dom',
            value: 0
        }
    ];
    const menu = useRef(null);

    const getTorneiosSelecionadosReta = () => {
        return torneiosRetasAndSub?.map(tournament => {
            return {
                idnetwork: tournament?.idnetwork,
                hourScheduledStartDate: tournament?.hourScheduledStartDate,
                buyin: tournament?.buyin,
            }
        }) ?? []
    }

    const handleVincularReta = () => {
        if (!retaEdicaoTorneios || !subEdicaoTorneios) {
            const mensagem = new MensagemLista();
            mensagem.simpleMensagem('Selecione uma Reta e a Sub-reta', EnumTipoMensagem.ERRO);
            setMensagens(mensagem);
            return;
        }
        const data = {
            line: retaEdicaoTorneios,
            sub: subEdicaoTorneios,
            managerTeam: {
                name: managerTeam === null ? user?.name : managerTeam
            },
            straightsTournaments: getTorneiosSelecionadosReta(),
            user: {
                email: user?.email,
                accessToken: user?.accessToken
            }
        };
        MyLobbyService.putReta(data)
            .then((data) => {
                const mensagem = new MensagemLista();
                mensagem.simpleMensagem('Torneios vinculados com sucesso', EnumTipoMensagem.SUCCESS);
                setMensagens(mensagem);
            })
            .catch((error) => {
                let mensagem = tratarExcecao(error);
                setMensagens(mensagem);
            })
            .finally(() => {
                setLoading(false);
                getStraights(managerTeam);
                //setSubEdicaoTorneios(null);
                //setTorneiosRetasAndSub([]);
            });
    };

    const handleCancelarEdicao = () => {
        setRetaEdicaoTorneios(null);
        setSubEdicaoTorneios(null);
        setTorneiosRetasAndSub([]);
        setSelectedTorneios([]);
        const mensagem = new MensagemLista();
        mensagem.simpleMensagem('Edição cancelada', EnumTipoMensagem.INFO);
        setMensagens(mensagem);
    };

    const menuStraightItems = [
        {
            label: 'Criar reta',
            icon: 'pi pi-fw pi-plus',
            command: () => setShowCreateReta(true),
            visible: user?.usersView?.tableSelectEdit,
        },
        {
            label: 'Editar reta',
            icon: 'pi pi-fw pi-pencil',
            command: () => setShowEditarReta(true),
            visible: user?.usersView?.tableSelectEdit,
        },
        {
            label: 'Clonar reta',
            command: () => setClonarReta(true),
            icon: 'pi pi-clone',
            visible: user?.usersView?.tableSelectEdit,
        },
        {
            label: 'Vincular torneios',
            icon: 'pi pi-fw pi-table',
            command: () => handleVincularReta(),
            //disabled: !user?.usersView?.tableSelectEdit
        },
        {
            label: 'Vincular jogador',
            icon: 'pi pi-user-plus',
            command: () => setShowVincularJogador(true)
        },
        {
            separator: true
        },
        {
            label: 'Cancelar',
            icon: 'pi pi-times',
            command: () => handleCancelarEdicao()
            // template: <TemplateButton icon='pi pi-times' title='Cancelar' actioProps={() => handleCancelarEdicao()} />
        }
    ];

    useEffect(() => {
        bindKeyDown();

        return () => {
            unbindKeyDown();
        };
    }, [bindKeyDown, unbindKeyDown]);

    const findBlindsEntryStructure = useCallback(async (nameStructureBlindsEntry, torneio) => {
        setLoading(true);
        const loadBlindsEntryStructure = (data) => {
            let _blindsStructures = data;
            if (_blindsStructures !== null) {
                const hourTournamentOnly = torneio.hourScheduledStartDate;
                const _blindsStructure = _blindsStructures[0];
                let minutesPause = torneio.networkDetails != null ? torneio.networkDetails.minutesPause : null;
                let _blindsEntryStructure = _blindsStructure.blindsEntryStructure === null ? [] : _blindsStructure.blindsEntryStructure;
                _blindsEntryStructure = calcNextNewHourByMinutes(_blindsEntryStructure, hourTournamentOnly, minutesPause);
                if (torneio.lateRegEndDateMinutes !== null && _blindsStructure.lateRegMinutes !== torneio.lateRegEndDateMinutes) {
                    setDisplayDiferenceLateReg(true);
                }
                setHumanLateRegEndDateMinCustomized(torneio?.lateRegEndDateMinutes === null ? _blindsStructure.lateRegMinutes : torneio?.lateRegEndDateMinutes);
                setInitialChipCustomized(_blindsStructure.initialChip);
                setMinutesBlindCustomized(_blindsStructure.minutesBlind);
                setStructureLevelCustomized(_blindsStructure.blindsEntryStructure !== null ? _blindsStructure.blindsEntryStructure.length : null);
                setBlindsEntryStructures(_blindsEntryStructure);
                setHasBlindsEntry(!isArrayEmpty(_blindsStructure.blindsEntryStructure));
            }
        };

        MyLobbyService.getStructures(nameStructureBlindsEntry)
            .then((response) => {
                return response.data;
            })
            .then((data) => {
                loadBlindsEntryStructure(data);
                setLoading(false);
            })
            .catch((error) => {
                setLoading(false);
                let mensagem = tratarExcecao(error);
                setMensagens(mensagem);
            });
    }, []);

    const loadLazyBlindsEntrys = useCallback(async (favoriteEntryBlind) => {
        setLoading(true);
        MyLobbyService.getStructures(favoriteEntryBlind)
            .then((response) => {
                return response.data;
            })
            .then((data) => {
                data = data?.map((structures) => {
                    return { structureName: structures.structureName };
                });
                setStructuresNames(data);
                setEditBlindsEntryDialog(true);
                setLoading(false);
            })
            .catch((error) => {
                setLoading(false);
                let mensagem = tratarExcecao(error);
                setMensagens(mensagem);
            });
    }, []);

    const loadLazyTorneios = useCallback(async () => {
        const data = {
            ...lazyState,
            user: {
                email: user?.email,
                accessToken: user?.accessToken
            }
        };
        MyLobbyService.getTornaments(
            { lazyEvent: JSON.stringify(data) }
        ).then((response) => {
            return response.data;
        }).then((data) => {
            setTotalRecords(data.totalRegisteringTournaments);
            setTournamentStatistics(data.tournamentStatistics);
            const _data = data.registeringTournaments?.map((tournament) => {
                let minutesPause = tournament.networkDetails != null ? tournament.networkDetails.minutesPause : null;
                let _customTournaments = tournament.customTournaments;
                let hourTournamentOnly = DateTime.fromISO(tournament.humanScheduledStartDate).toLocaleString(DateTime.TIME_24_SIMPLE);
                let _blindsStructures = _customTournaments !== null ? _customTournaments.blindsStructures : null;
                let _blindsEntryStructure = _blindsStructures !== null ? _blindsStructures.blindsEntryStructure : null;
                if (_blindsEntryStructure !== null) {
                    _blindsEntryStructure = calcNextNewHourByMinutes(_blindsEntryStructure, hourTournamentOnly, minutesPause);
                    _blindsStructures = { ..._blindsStructures, blindsEntryStructure: _blindsEntryStructure };
                    _customTournaments = { ..._customTournaments, blindsStructures: _blindsStructures };
                }
                return { ...tournament, customTournaments: _customTournaments };
            });
            setTorneios(_data);
            setInitialLoading(false);
        }).catch((error) => {
            let mensagem = tratarExcecao(error);
            setMensagens(mensagem);
        }).finally(() => {
            setLoading(false);
        });
    }, [lazyState]);

    const saveRegisteringTournaments = useCallback(async (registeringTournament) => {
        setLoading(true);
        MyLobbyService.patchTournament(registeringTournament)
            .then((response) => {
                setLoading(false);
                setEditCustomDialog(false);
                loadLazyTorneios();
                return response.data;
            })
            .catch((error) => {
                let mensagem = tratarExcecao(error);
                setMensagens(mensagem);
                setLoading(false);
            });
    }, [loadLazyTorneios]);

    const saveCustomTournaments = useCallback(async (customTournament) => {
        setLoading(true);
        MyLobbyService.patchCustomizedTournament(customTournament)
            .then((response) => {
                setLoading(false);
                setCustomTournamentsDialog(false);
                setEditBlindsEntryDialog(false);
                loadLazyTorneios();
                return response.data;
            })
            .catch((error) => {
                let mensagem = tratarExcecao(error);
                setMensagens(mensagem);
                setLoading(false);
            });
    }, [loadLazyTorneios]);

    const saveBlindsEntrys = useCallback(async (customTournament) => {
        setLoading(true);
        MyLobbyService.postCustomizedTournament(customTournament)
            .then((response) => {
                setConfirmNameBlindsEntryDialog(false);
                setEditBlindsEntryDialog(false);
                setCustomTournamentsDialog(false);
                setInitialChipCustomized(null);
                setMinutesBlindCustomized(null);
                loadLazyTorneios();
                return response.data;
            }).catch((error) => {
                let mensagem = tratarExcecao(error);
                setMensagens(mensagem);
            }).finally(() => {
                setLoading(false);
            });
    }, [loadLazyTorneios]);

    const saveFavoriteTournaments = useCallback(async (favoriteTournament) => {
        MyLobbyService.postFavoriteTournament(favoriteTournament)
            .then((response) => {
                const mensagem = new MensagemLista();
                const descricao = isArrayEmpty(favoriteTournament.daysOfWeek) ? "Favorito excluído com sucesso" : "Favorito salvo com sucesso";
                mensagem.simpleMensagem(descricao, EnumTipoMensagem.SUCCESS);
                setMensagens(mensagem);
                loadLazyTorneios();
                return response.data;
            }).catch((error) => {
                setLoading(false);
                let mensagem = tratarExcecao(error);
                setMensagens(mensagem);
            }).finally(() => {
                setDisplayFavoriteGold(false);
                setDiasTorneioSelecionado([]);
            });
    }, [loadLazyTorneios]);

    const saveAlarmTournaments = useCallback(async (alarmTournament) => {
        MyLobbyService.postAlarmTournament(alarmTournament).then((response) => {
            const mensagem = new MensagemLista();
            const descricao = isArrayEmpty(alarmTournament.alarmDaysOfWeek) ? "Alarme excluído com sucesso" : "Alarme salvo com sucesso";
            mensagem.simpleMensagem(descricao, EnumTipoMensagem.SUCCESS);
            setMensagens(mensagem);
            loadLazyTorneios();
            return response.data;
        }).catch((error) => {
            setLoading(false);
            let mensagem = tratarExcecao(error);
            setMensagens(mensagem);
            //setLoading(false);
        }).finally(() => {
            setDisplayAlarme(false);
            setOptionsalarmBlindsEntry(null);
            setDiasAlarmeTorneio([]);
            setFilterAlarmeTorneio(null);
        });
    }, [loadLazyTorneios]);

    const deleteBlindsEntryStructures = useCallback(async (blindsEntryStructure) => {
        setLoading(true);
        //imitate delay of a backend call
        MyLobbyService.deleteStructure(blindsEntryStructure)
            .then((response) => {
                setConfirmNameBlindsEntryDialog(false);
                setConfirmDeleteBlindsEntryDialog(false);
                setEditBlindsEntryDialog(false);
                setCustomTournamentsDialog(false);
                setConfirmNameDeleteBlindsEntry('');
                setBlindsEntryStructures([]);
                loadLazyTorneios();
                return response.data;
            }).catch((error) => {
                let mensagem = tratarExcecao(error);
                setMensagens(mensagem);
                setLoading(false);
            }).finally(() => {
                setLoading(false);
            });
    }, [loadLazyTorneios]);

    const deleteTournamentById = useCallback(async () => {
        setLoading(true);
        //imitate delay of a backend call
        MyLobbyService.deleteTornamentById({ delete: JSON.stringify(deleteTournament) })
            .then((response) => {
                setLoading(false);
                loadLazyTorneios();
                return response.data;
            })
            .catch((error) => {
                let mensagem = tratarExcecao(error);
                setMensagens(mensagem);
                setLoading(false);
            });
    }, [loadLazyTorneios, deleteTournament]);

    const deleteTornaments = useCallback(async () => {
        setLoading(true);
        MyLobbyService.deleteTornaments(JSON.stringify(deleteTournament))
            .then((response) => {
                setLoading(false);
                setDisableChange(false);
                setSelectedTorneios(null);
                loadLazyTorneios();
                return response.data;
            })
            .catch((error) => {
                let mensagem = tratarExcecao(error);
                setMensagens(mensagem);
                setLoading(false);
            });
    }, [loadLazyTorneios, deleteTournament]);

    const deletarReta = useCallback(async () => {
        setLoading(true);
        const data = {
            line: retaEdicao,
            sub: subEdicao,
            managerTeam: {
                name: managerTeam
            },
            user: {
                email: user?.email,
                accessToken: user?.accessToken
            }
        };
        MyLobbyService.deleteReta(data)
            .then((response) => {
                const mensagem = new MensagemLista();
                mensagem.simpleMensagem('Reta excluída com sucesso', EnumTipoMensagem.SUCCESS);
                setMensagens(mensagem);
                setDisableChange(false);
            })
            .catch((error) => {
                let mensagem = tratarExcecao(error);
                setMensagens(mensagem);
            })
            .finally(() => {
                setLoading(false);
                setShowEditarReta(false);
                getStraights(managerTeam);
                setSubEdicaoTorneios(null);
                setTorneiosRetasAndSub([]);
            });
        // eslint-disable-next-line
    }, [retaEdicao, straights, subEdicao, managerTeam, user]);

    useEffect(() => {
        let _filters = props.filter;
        lazyState.filtersMenu = _filters;
        setLoading(true);
        setlazyState(lazyState);
        loadLazyTorneios();
        if (!isArrayEmpty(_filters)) {
            props.onFilter(_filters);
        }
        setInitialLoading(isArrayEmpty(torneios));
    }, [lazyState, props, loadLazyTorneios]);

    useInterval(
        () => {
            setSeconds((prevSecond) => prevSecond + 1); //fn
        },
        1000, //delay (ms)
        true //condition (when)
    );

    const onPage = (event) => {
        event.filters = { global: lazyState.filters.global, humanLateRegEndDate: lazyState.filters.humanLateRegEndDate };
        event.filtersMenu = lazyState.filtersMenu;
        event.filters.withoutSchedulerStartDate = withoutSchedulerStartDate;
        event.user = {
            email: user?.email,
            accessToken: user?.accessToken
        };
        setLoading(true);
        setRows(event.rows);
        setlazyState(event);
    };

    const onSort = (event) => {
        event.filters = { global: lazyState.filters.global, humanLateRegEndDate: lazyState.filters.humanLateRegEndDate };
        event.filtersMenu = lazyState.filtersMenu;
        event.filters.withoutSchedulerStartDate = withoutSchedulerStartDate;
        event.user = {
            email: user?.email,
            accessToken: user?.accessToken
        };
        setLoading(true);
        setRows(event.rows);
        setlazyState(event);
    };

    const onFilter = (event) => {
        event['first'] = 0;
        event.filters.withoutSchedulerStartDate = withoutSchedulerStartDate;
        event.user = {
            email: user?.email,
            accessToken: user?.accessToken
        };
        setLoading(true);
        setlazyState(event);
    };

    /*const onStatusLateRegisterFilterChange = (event) => {
        const value = event.target.value;
        let _filters = { ...lazyState.filters };

        let humanLateRegEndDate = _filters.humanLateRegEndDate;
        humanLateRegEndDate.value = value;
        _filters.humanLateRegEndDate = humanLateRegEndDate;
        lazyState.filters = _filters;

        setLoading(true);
        setlazyState(lazyState);
        loadLazyTorneios();
    };*/

    const onFilterChange = (value) => {
        let _filters = { ...lazyState.filters };

        let global = _filters.global;
        global.value = value;
        _filters.global = global;
        lazyState.filters = _filters;
    };

    const onGlobalFilterChange = (event) => {
        const value = event.target.value;
        if (event.code === 'Enter' || (event.code === 'Backspace' && (isEmpty(value)))) {
            onFilterChange(value);
            setLoading(true);
            setlazyState(lazyState);
            loadLazyTorneios();
        }
    };

    const uniqueTournaments = (torneiosRepeat) => {
        let torneiosUnicos = []
        torneiosRepeat?.forEach(torneioUnique => {
            let exists = torneiosUnicos?.filter(torneioArray => {
                return torneioArray?.idnetwork === torneioUnique?.idnetwork &&
                    torneioArray?.hourScheduledStartDate === torneioUnique?.hourScheduledStartDate &&
                    torneioArray?.buyin === torneioUnique?.buyin
            });

            if (!exists.length) {
                torneiosUnicos.push(torneioUnique);
            }
        });

        return torneiosUnicos;
    };

    const onSelectTorneios = (event) => {
        let value = event.value;
        setSelectedTorneios(value);
        setDisableChange(!isArrayEmpty(value));

        // retirando torneios
        if (subEdicaoTorneios) {

            if (value?.length < selectedTorneios?.length || value?.length === 0) {

                let torneioNotInclude = selectedTorneios?.filter(torneioSelected => {
                    let torneioInValue = value?.filter(torneioValue => {
                        return torneioValue?.idnetwork === torneioSelected?.idnetwork &&
                            torneioValue?.hourScheduledStartDate === torneioSelected?.hourScheduledStartDate &&
                            torneioValue?.buyin === torneioSelected?.buyin
                    })
                    if (!torneioInValue?.length) {
                        return torneioSelected
                    }
                });

                let tournamentsIncludes = [...torneiosRetasAndSub];

                torneioNotInclude?.forEach(tornNotInclude => {
                    let indexIncludeTournament = tournamentsIncludes?.findIndex(torneioIndex => {
                        return torneioIndex?.idnetwork === tornNotInclude?.idnetwork &&
                            torneioIndex?.hourScheduledStartDate === tornNotInclude?.hourScheduledStartDate &&
                            torneioIndex?.buyin === tornNotInclude?.buyin
                    });
                    tournamentsIncludes.splice(indexIncludeTournament, 1);
                });

                setTorneiosRetasAndSub(uniqueTournaments([...tournamentsIncludes]))
            } else if (value?.length > selectedTorneios?.length) {
                setTorneiosRetasAndSub(state => uniqueTournaments([...state, ...value]))
            }
        }
    };

    const onFilterWithoutSchedulerStartDate = (event) => {
        let _filters = { ...lazyState.filters };

        let _withoutSchedulerStartDate = _filters.withoutSchedulerStartDate;
        _withoutSchedulerStartDate = !_withoutSchedulerStartDate;
        _filters.withoutSchedulerStartDate = _withoutSchedulerStartDate;
        lazyState.filters = _filters;
        lazyState.user = user;
        setLoading(true);
        setlazyState(lazyState);
        setWithoutSchedulerStartDate(_withoutSchedulerStartDate);
        loadLazyTorneios();
    };

    const onCellEditComplete = (_event) => {
        // eslint-disable-next-line
        let { rowData, newValue, field, originalEvent: event } = _event;
        switch (field) {
            case 'bigBlind':
                rowData[field] = newValue;
                rowData['blindsEntry'] = newValue !== null ? Math.round(initialChipCustomized / newValue) : null;
                break;
            case 'minutes':
                rowData[field] = newValue;
                let minutesPause = torneio.networkDetails != null ? torneio.networkDetails.minutesPause : null;
                calcNextNewHourByMinutes(_event.props.value, hourTournamentOnly, minutesPause);
                break;
            default:
                rowData[field] = newValue;
                //event.preventDefault();
                break;
        }
    };

    const calcNextNewHourByMinutes = (blindsEntryStructure, hourTournamentOnly, minutesPause) => {
        let _hourBlind = null;
        let _hourMinutePause = null;
        let _isMinutePause = false;
        let _miutesPauseIn = 0;
        let _hourBlindWithPause = null;
        return blindsEntryStructure?.map((blindEntry) => {
            // define a hora do minuto de pause
            if (minutesPause !== null && !isUndefined(minutesPause) && _hourMinutePause === null) {
                // define o minuto que sera a pausa. ex: 15:55, 00:55, 02:55...
                _hourMinutePause = String(DateTime.fromISO(hourTournamentOnly).hour).padStart(2, '0');
                _hourMinutePause = `${_hourMinutePause}:${minutesPause}`;
                _hourMinutePause = DateTime.fromISO(_hourMinutePause);
            }
            // primeira blind
            if (blindEntry.structureLevel === 1) {
                _hourBlind = hourTournamentOnly;
            }
            // hora da primeira blind
            blindEntry.hourBlind = DateTime.fromISO(_hourBlind).plus({ minutes: blindEntry.minutes }).toFormat('HH:mm');
            _hourBlind = blindEntry.hourBlind;

            // calcula minuto de pause
            let _hourBlindPause = DateTime.fromISO(_hourBlind);
            if (_hourMinutePause !== null) {
                if (_hourBlindPause > _hourMinutePause) {
                    _miutesPauseIn = 5;
                }
                if (_hourBlindPause.minute === _hourMinutePause.minute && _hourBlindPause.hour === _hourMinutePause.hour) {
                    _miutesPauseIn = 10;
                }
                if (_miutesPauseIn !== 0) {
                    // proximo minuto de pause
                    _hourMinutePause = _hourMinutePause.plus({ hours: 1 });
                    _hourBlindWithPause = DateTime.fromISO(_hourBlind).plus({ minutes: _miutesPauseIn }).toFormat('HH:mm');
                    _hourBlind = _hourBlindWithPause;
                    _isMinutePause = true;
                } else {
                    _isMinutePause = false;
                    _hourBlindWithPause = null;
                }
                _miutesPauseIn = 0;
            }
            blindEntry.isMinutePause = _isMinutePause;
            blindEntry.hourBlindWithPause = _hourBlindWithPause;
            return blindEntry;
        });
    };

    const onChangeInitialChipCustomized = (event) => {
        let _value = event.value;
        setInitialChipCustomized(_value);
        updateBlindsEntry('initialChip', _value);
    };

    const onChangeMinutesBlindCustomized = (event) => {
        let _value = event.value;
        setMinutesBlindCustomized(_value);
        updateBlindsEntry('minutesBlind', _value);
    };

    const onChangeStructureLevelCustomized = (event) => {
        let _value = event.value;
        setStructureLevelCustomized(_value);
        updateBlindsEntry('structureLevel', _value);
    };

    const onBlindsEntryStructures = (event) => {
        let _value = event.value;
        _value = { structureName: _value?.structureName };
        setSelectedBlindsEntryStructures(_value);
        setNameBlindsEntry(_value.structureName);
        findBlindsEntryStructure(_value, torneio);
    };

    const onConfirmNameBlindsEntry = () => {
        if (!isEmpty(nameBlindsEntry)) {
            let _bigBlind = blindsEntryStructures[0]?.bigBlind;
            _bigBlind = isUndefined(_bigBlind) ? '' : _bigBlind;
            let actualNameStructure = nameBlindsEntry.split('-');
            actualNameStructure[0] = torneio?.network;
            actualNameStructure[1] = initialChipCustomized;
            actualNameStructure[2] = `${minutesBlindCustomized}min`;
            actualNameStructure[3] = `${humanLateRegEndDateMinCustomized}min`;
            let hasIdentifyLetterName = containsString(actualNameStructure[4]);
            if (hasIdentifyLetterName !== null) {
                const nameWithLetterName = actualNameStructure[4].slice(hasIdentifyLetterName.index);
                actualNameStructure[4] = `${_bigBlind}${nameWithLetterName}`;
            } else {
                actualNameStructure[4] = _bigBlind;
            }
            let _structureName = actualNameStructure.join('-');
            blindsEntryStructures?.map((blind) => {
                return (blind.structureName = _structureName);
            });
            setNameBlindsEntry(_structureName);
        } else {
            let _bigBlind = blindsEntryStructures[0]?.bigBlind;
            _bigBlind = isUndefined(_bigBlind) ? '' : `-${_bigBlind}`;
            let _structureName = `${torneio?.network}-${initialChipCustomized}-${minutesBlindCustomized}min-${humanLateRegEndDateMinCustomized}min${_bigBlind}`;
            setNameBlindsEntry(_structureName);
        }
        //if (!isEmpty(_bigBlind)) {
        setConfirmNameBlindsEntryDialog(true);
        /*} else {
            setConfirmDeleteBlindsEntryDialog(true);
        }*/
    };

    const onNameBlindsEntry = (event) => {
        const _value = event.target.value;
        setNameBlindsEntry(_value);
        blindsEntryStructures?.map((blind) => {
            return (blind.structureName = _value);
        });
    };

    const onConfirmDeleteBlindsEntry = () => {
        if (isEmpty(nameBlindsEntry)) {
            let _bigBlind = blindsEntryStructures[0]?.bigBlind;
            _bigBlind = isUndefined(_bigBlind) ? '' : `-${_bigBlind}`;
            let _structureName = `${torneio?.network}-${initialChipCustomized}-${minutesBlindCustomized}min-${humanLateRegEndDateMinCustomized}min${_bigBlind}`;
            setNameBlindsEntry(_structureName);
        }
        setConfirmDeleteBlindsEntryDialog(true);
    };

    const updateBlindsEntry = (field, value) => {
        let hasNewBlindEntry = null;
        if (!isArrayEmpty(blindsEntryStructures)) {
            blindsEntryStructures?.map((blind) => {
                switch (field) {
                    case 'initialChip':
                        blind.blindsEntry = Math.round(value / blind.bigBlind);
                        break;
                    case 'minutesBlind':
                        blind.minutes = value;
                        /*let _level = blind.structureLevel;
                        let _minutes = value * _level;
                        blind.hourBlind = DateTime.fromISO(hourTournamentOnly).plus({ minutes: _minutes }).toFormat("HH:mm");*/
                        let minutesPause = torneio.networkDetails != null ? torneio.networkDetails.minutesPause : null;
                        calcNextNewHourByMinutes(blindsEntryStructures, hourTournamentOnly, minutesPause);
                        break;
                    case 'structureLevel':
                        // adiciona mais uma linha
                        if (blind.structureLevel < value) {
                            hasNewBlindEntry = true;
                        } else {
                            // remove ultima linha
                            hasNewBlindEntry = false;
                        }
                        break;
                    default:
                        break;
                }
                return blind;
            });
        }

        if (hasNewBlindEntry !== null) {
            if (hasNewBlindEntry) {
                for (let index = blindsEntryStructures.length; index < value; index++) {
                    const _blindsEntryStructure = {
                        structureName: nameBlindsEntry !== '' ? nameBlindsEntry : null,
                        structureLevel: blindsEntryStructures.length + 1,
                        bigBlind: null,
                        blindsEntry: null,
                        minutes: minutesBlindCustomized
                    };
                    blindsEntryStructures.push(_blindsEntryStructure);
                }
            } else {
                let _blindsEntryStructures = blindsEntryStructures.filter((blind) => {
                    return blind.structureLevel <= value;
                });
                setBlindsEntryStructures(_blindsEntryStructures);
                setHasBlindsEntry(!isArrayEmpty(_blindsEntryStructures));
            }
        }
    };

    const confirmDeleteTournament = (torneio) => {
        setTorneio(torneio);
        setDeleteTournamentDialog(true);
        const tournamentToDelete = {
            idsharkscope: torneio.idsharkscope,
            idnetwork: torneio.idnetwork,
            user: {
                email: user?.email,
                accessToken: user?.accessToken
            }
        };
        setDeleteTournament(tournamentToDelete);
    };

    const confirmDeleteTournaments = () => {
        setDeleteTournamentsDialog(true);
        let _selectedTorneios = selectedTorneios.map((torneio) => {
            return {
                idsharkscope: torneio.idsharkscope,
                idnetwork: torneio.idnetwork,
                user: {
                    email: user?.email,
                    accessToken: user?.accessToken
                }
            };
        });
        setDeleteTournament(_selectedTorneios);
    };

    const cancelConfirmDeleteTournaments = () => {
        setSelectedTorneios(null);
        setDisableChange(false);
    };

    const defineNomeTorneio = (torneio) => {
        let nomeTorneio = torneio?.name;
        if ((torneio?.customTournaments !== null) & torneio?.customTournaments?.ativo) {
            nomeTorneio = torneio.customTournaments?.nome;
        }
        return nomeTorneio;
    };

    const editTournaments = (torneio) => {
        setTorneio(torneio);
        let humanScheduledStartDate = torneio.humanScheduledStartDate !== null ? DateTime.fromISO(torneio.humanScheduledStartDate).toJSDate() : null;
        let humanDurationDate = null;
        let guarantee = torneio.guarantee;
        let currency = torneio.currency;
        if (torneio.customTournaments !== null) {
            if (torneio.customTournaments?.humanDurationDate !== null) {
                humanDurationDate = DateTime.fromISO(torneio.customTournaments.humanDurationDate);
            }
            if (torneio.customTournaments?.guarantee !== null) {
                guarantee = torneio.customTournaments?.guarantee;
            }
            if (torneio.customTournaments?.currency !== null) {
                currency = torneio.customTournaments?.currency;
            }
        }
        setHourTournamentCustomized(humanScheduledStartDate);
        setCurrencyCustomized(currency);
        setGuaranteeCustomized(guarantee);
        setNameTournamentsCustomized(torneio.customTournaments !== null ? torneio.customTournaments.nickName : '');
        setObservationTournamentsCustomized(torneio.customTournaments !== null ? torneio.customTournaments.observation : '');
        setFieldCustomized(torneio.customTournaments !== null ? torneio.customTournaments.field : '');
        setTypeAvAbilityCustomized(torneio.customTournaments !== null ? torneio.customTournaments.typeAvAbility : '');
        setPrizePositionOneCustomized(torneio.customTournaments !== null ? torneio.customTournaments.prizePositionOne : '');
        setHumanLateRegEndDateMinCustomized(torneio.lateRegEndDateMinutes);
        setHumanDurationDateCustomized(humanDurationDate !== null ? humanDurationDate.toJSDate() : '');
        setSpeedCustomized(torneio.customTournaments !== null ? torneio.customTournaments.speed : null);
        setTournamentStructureCustomized(torneio.customTournaments !== null ? torneio.customTournaments.tournamentStructure : null);

        if (!isNull(guarantee) && !isNull(humanScheduledStartDate)) {
            setCustomTournamentsDialog(true);
        } else {
            setEditCustomDialog(true);
        }
    };

    const editBlindsEntry = () => {
        setNameBlindsEntry('');
        setConfirmNameDeleteBlindsEntry('');
        setSelectedBlindsEntryStructures(null);
        let humanScheduledStartDate = DateTime.fromISO(torneio.humanScheduledStartDate);
        let _customTournaments = torneio.customTournaments !== null ? torneio.customTournaments : null;
        let _blindsStructures = null;
        let _blindsEntryStructure = null;
        if (_customTournaments !== null) {
            _blindsStructures = _customTournaments.blindsStructures !== null ? _customTournaments.blindsStructures : null;
            if (_blindsStructures !== null) {
                _blindsEntryStructure = _blindsStructures.blindsEntryStructure !== null ? _blindsStructures.blindsEntryStructure : null;
                if (!isArrayEmpty(_blindsEntryStructure)) {
                    setSelectedBlindsEntryStructures({ structureName: _blindsStructures.structureName });
                    setNameBlindsEntry(_blindsStructures.structureName);
                }
                setHasBlindsEntry(!isArrayEmpty(_blindsEntryStructure));
            } else {
                setHasBlindsEntry(false);
            }
        } else {
            setHasBlindsEntry(false);
        }
        setHourTournamentOnly(humanScheduledStartDate.toLocaleString(DateTime.TIME_24_SIMPLE));
        setStructureLevelCustomized(_blindsEntryStructure !== null && !isArrayEmpty(_blindsEntryStructure) ? _blindsEntryStructure.length : null);
        setHumanLateRegEndDateMinCustomized(torneio.lateRegEndDateMinutes);
        setInitialChipCustomized(torneio.customTournaments != null ? torneio.customTournaments.initialChip : null);
        setMinutesBlindCustomized(torneio.customTournaments != null ? torneio.customTournaments.minutesBlind : null);
        setBlindsEntryStructures(_blindsEntryStructure === null ? [] : _blindsEntryStructure);
        const _blindsEntryStructureNetwork = {
            structure: JSON.stringify({ network: torneio.network, filter: null })
        };
        loadLazyBlindsEntrys(_blindsEntryStructureNetwork);
    };

    const getSelectBlindsEntryTorneio = (torneio) => {
        let blinds = [];
        torneio?.customTournaments?.blindsStructures?.blindsEntryStructure.forEach((blindEntry) => {
            blinds.push({
                label: `Nível ${blindEntry.structureLevel} - ${blindEntry.blindsEntry} BB`,
                value: blindEntry.blindsEntry
            });
        });

        return blinds;
    };

    const handleRegisterAlarme = (torneio) => {
        let humanScheduledStartDate = torneio.humanScheduledStartDate !== null ? DateTime.fromISO(torneio.humanScheduledStartDate) : null;
        let filterAlarmeTorneio;
        setHourTournamentCustomized(humanScheduledStartDate);
        setHourTournamentOnly(humanScheduledStartDate.toLocaleString(DateTime.TIME_24_SIMPLE));
        setDisplayAlarme(true);
        setTorneio(torneio);

        if (torneio?.alarmTournaments !== null) {
            const _alarmTournaments = torneio?.alarmTournaments;
            if (_alarmTournaments.alarmBlindsEntry !== null) {
                filterAlarmeTorneio = filterTypes.ENTRADA_BLINDS;
            }
            if (_alarmTournaments.alarmScheduledStartDate !== null) {
                filterAlarmeTorneio = filterTypes.INICIO_TORNEIO;
            }
            if (_alarmTournaments.alarmMaxLate !== null) {
                filterAlarmeTorneio = filterTypes.MAX_LATE;
            }
        }
        setFilterAlarmeTorneio(filterAlarmeTorneio);

        let blindsEntry = getSelectBlindsEntryTorneio(torneio);
        setOptionsalarmBlindsEntry(blindsEntry);

        setDiasAlarmeTorneio(state => [...state, {
            hourScheduledStartDate: torneio.hourScheduledStartDate,
            idnetwork: torneio.idnetwork,
            buyin: torneio.buyin,
            alarmDaysOfWeek: torneio?.alarmTournaments ? torneio?.alarmTournaments?.alarmDaysOfWeek : [],
            alarmBlindsEntry: torneio?.alarmTournaments?.alarmBlindsEntry,
            alarmScheduledStartDate: torneio?.alarmTournaments?.alarmScheduledStartDate,
            alarmMaxLate: torneio?.alarmTournaments?.alarmMaxLate,
        }]);
    };

    const alarmeBodyTemplate = (torneio) => {
        return (
            <div>
                <Button
                    onClick={() => handleRegisterAlarme(torneio)}
                    icon={torneio.alarmTournaments ? <FontAwesomeIcon icon={solid('bell')} size="lg" /> : <FontAwesomeIcon icon={regular('bell')} size="lg" />}
                    className={`p-button-rounded p-button-plain p-button-text ${torneio.alarmTournaments ? 'text-yellow-300' : ''}`}
                />
            </div>
        );
    };

    const prioridadesBodyTemplate = (torneio) => {
        return (
            <label className="flex aligm-items-center justify-content-center">
                {torneio?.priorities === null && '-'}
                {
                    torneio?.priorities?.level === 'Alta' && (
                        <Tag style={{ width: 60, borderRadius: 16 }} severity="danger" value="Alta" className="p-overlay-badge">
                            <Badge value={torneio?.priorities?.score} severity="danger"></Badge>
                        </Tag>
                    )}
                {
                    torneio?.priorities?.level === 'Média' && (
                        <Tag style={{ width: 60, borderRadius: 16 }} severity="warning" value="Média" className="p-overlay-badge">
                            <Badge value={torneio?.priorities?.score} severity="warning" />
                        </Tag>
                    )}
                {
                    torneio?.priorities?.level === 'Baixa' && (
                        <Tag style={{ width: 60, borderRadius: 16 }} severity="success" value="Baixa" className="p-overlay-badge">
                            <Badge value={torneio?.priorities?.score} severity="success" />
                        </Tag>
                    )}
            </label>
        );
    };

    const favoritoRedBodyTemplate = (torneio) => {
        let _hasFavoriteStarRed = torneio.favoriteTournaments?.length === torneio.daysOfWeek?.length;
        let _hasFavoriteStarYellow = torneio.favoriteTournaments?.includes(torneio.weekDayScheduledStartDate) && !_hasFavoriteStarRed;

        return (
            <React.Fragment>
                <div className="flex align-items-center">
                    <Button
                        icon={_hasFavoriteStarRed ? <FontAwesomeIcon icon={solid('star')} size="lg" /> : <FontAwesomeIcon icon={regular('star')} size="lg" />}
                        style={{ color: _hasFavoriteStarRed ? 'red' : '' }}
                        className="p-button-rounded p-button-plain p-button-text"
                        onClick={() => saveFavoriteRed(torneio)}
                    />
                    <Button
                        icon={_hasFavoriteStarYellow ? <FontAwesomeIcon icon={solid('star')} size="lg" /> : <FontAwesomeIcon icon={regular('star')} size="lg" />}
                        className={`p-button-rounded p-button-plain p-button-text ${_hasFavoriteStarYellow ? 'text-yellow-300' : ''}`}
                        onClick={(e) => editFavoriteGold(torneio)}
                    />
                </div>
            </React.Fragment>
        );
    };

    const inicioBodyTemplate = (torneio) => {
        const dataInicio = torneio.humanScheduledStartDate !== null ? DateTime.fromISO(torneio.humanScheduledStartDate) : null;
        return (
            <div className="gap-2" style={{ width: '5%' }}>
                <div className="flex align-items-center ">
                    <span className="mr-2">{torneio.weekDayScheduledStartDate}.</span>
                    <span>{dataInicio !== null ? dataInicio.toFormat('dd/MM HH:mm') : '-'}</span>
                </div>
            </div>
        );
    };

    const siteBodyTemplate = (torneio) => {
        return (
            <div className="flex align-items-center gap-2">
                {siteImageTemplate(torneio.networkDetails?.nickName)}
                {torneio.networkDetails?.nickName}
            </div>
        );
    };

    const torneioBodyTemplate = (torneio) => {
        let name = torneio.customTournaments !== null ? (isEmpty(torneio.customTournaments?.nickName) || torneio.customTournaments?.nickName === null ? torneio.name : torneio.customTournaments?.nickName) : torneio.name;
        let tournamentStructureDefault = torneio?.tournamentStructure !== null ? torneio?.tournamentStructure : '-';
        let tournamentStructure = torneio.customTournaments !== null ? (torneio.customTournaments?.tournamentStructure === null ? tournamentStructureDefault : torneio.customTournaments?.tournamentStructure) : tournamentStructureDefault;
        return (
            <div className="flex align-items-center gap-2">
                {/*<Tooltip target=".tlpTornioName" hideDelay="2000" />
                    <span className="tlpTornioName" data-pr-tooltip={`id: ` + torneio.idsharkscope + ` última atualizacao: ` + torneio.humanLastUpdateTime}>{torneio.name}</span>*/}
                <span>
                    {name}
                    [<b>{torneio.playersPerTable} max</b>]
                </span>
                {tournamentStructure === 'Bounty' && <span className="material-symbols-outlined">
                    ads_click
                </span>}
            </div>
        );
    };

    const blindsEntryBodyTemplate = (torneio) => {
        let _blindsStructures = torneio?.customTournaments !== null ? torneio?.customTournaments : null;
        if (_blindsStructures !== null) {
            _blindsStructures = _blindsStructures?.blindsStructures !== null ? _blindsStructures?.blindsStructures : null;
        }
        return (
            <div className="flex align-items-center gap-2">
                <FontAwesomeIcon icon={solid('coins')} className="text-yellow-300" size="lg" />
                <TimerNextBlind torneio={torneio} />
                {infoLateReg(_blindsStructures, 'Ent. Blinds não cadastrado')}
            </div>
        );
    };

    const guaranteeWithCurrencyBodyTemplate = (torneio) => {
        let guaranteeValueDefault = torneio?.guaranteeWithCurrency !== null ? torneio?.guaranteeWithCurrency : '-';
        let guarantee = torneio.customTournaments !== null ? (torneio.customTournaments?.guaranteeWithCurrency === null ? guaranteeValueDefault : torneio.customTournaments?.guaranteeWithCurrency) : guaranteeValueDefault;
        return (
            <div className="flex align-items-center gap-2">
                <span>{guarantee}</span>
            </div>
        );
    };

    const fieldBodyTemplate = (torneio) => {
        //let fieldDefault = torneio.totalEntrants;
        //if (fieldDefault === 0) {
        let fieldDefault = '-';
        let valueField = Math.round(torneio.guarantee / torneio.buyin);
        if (valueField !== 0) {
            fieldDefault = String.fromCharCode(8773) + valueField;
        }
        //}
        let field = torneio.customTournaments !== null ? (torneio.customTournaments?.field === null ? fieldDefault : torneio.customTournaments?.field) : fieldDefault;
        return (
            <div className="flex align-items-center gap-2">
                <span>{field === 0 ? '-' : field}</span>
            </div>
        );
    };

    const firstBodyTemplate = (torneio) => {
        let prizeValueDefault = torneio?.prizeValue !== null ? torneio?.prizeValue : '-';
        let prize = torneio.customTournaments !== null ? (torneio.customTournaments?.prizePositionOne === null ? prizeValueDefault : torneio.customTournaments?.prizePositionOne) : prizeValueDefault;
        return <div className="flex align-items-center gap-2">{prize !== '-' ? prizeValue(prize) : '-'}</div>;
    };

    const prizeValue = (prizeValue) => {
        return (
            <>
                <FontAwesomeIcon icon={solid('hand-holding-dollar')} />
                <span>{usDollar.format(prizeValue)}</span>
            </>
        );
    };

    const infoLateReg = (hasRegister, infoDetails) => {
        return <>{hasRegister === null ? <FontAwesomeIcon id="infoLateReg" icon={solid('triangle-exclamation')} style={{ color: 'red' }} className="ml-2" title={isUndefined(infoDetails) ? '' : infoDetails} /> : ''}</>;
    };

    const finalizaEmBodyTemplate = (torneio) => {
        let humanLateRegEndDate = torneio.humanLateRegEndDate;
        let humanScheduledStartDate = torneio.humanScheduledStartDate;
        //let dataInicioTorneio = DateTime.fromISO(humanScheduledStartDate);
        //let dataFimRegistro = DateTime.fromISO(humanLateRegEndDate);
        let fimRegistro = calculaFimRegistro(humanScheduledStartDate, humanLateRegEndDate);
        //let lateRegEndDateMin = humanLateRegEndDate !== null ? Math.round(dataFimRegistro.diff(dataInicioTorneio, ['minutes']).minutes) : null;
        // let lateRegMinutes = torneio?.customTournaments !== null
        // ? torneio.customTournaments?.humanLateRegEndDateMin === null ? lateRegEndDateMin : torneio.customTournaments?.humanLateRegEndDateMin : lateRegEndDateMin;
        let lateRegMinutes = torneio.lateRegEndDateMinutes;
        return (
            <React.Fragment>
                <div className="flex align-items-center gap-2">
                    <span key={torneio.idsharkscope}>
                        {fimRegistro === 'end' ? (
                            <div>
                                <Tag severity="danger" value="Closed" className="p-overlay-badge">
                                    {lateRegMinutes !== null && <Badge value={lateRegMinutes} severity="danger"></Badge>}
                                </Tag>
                                {infoLateReg(humanLateRegEndDate, 'Late Reg. não cadastrado')}
                            </div>
                        ) : fimRegistro === 'reg' ? (
                            <div>
                                <Tag severity="info" value="Registering" className="p-overlay-badge">
                                    {lateRegMinutes !== null && <Badge value={lateRegMinutes} severity="info"></Badge>}
                                </Tag>
                                {infoLateReg(humanLateRegEndDate, 'Late Reg. não cadastrado')}
                            </div>
                        ) : fimRegistro === 'comp' ? (
                            <div>
                                <Tag severity="warning" value="Completed" className="p-overlay-badge">
                                    {lateRegMinutes !== null && <Badge value={lateRegMinutes} severity="warning"></Badge>}
                                </Tag>
                                {infoLateReg(humanLateRegEndDate, 'Late Reg. não cadastrado')}
                            </div>
                        ) : (
                            fimRegistro
                        )}
                    </span>
                </div>
            </React.Fragment>
        );
    };

    /*const finalizaEmFilterTemplate = (options) => {
        return <Dropdown value={options.value} options={humanLateRegEndDate} itemTemplate={finalizaEmItemTemplate} onChange={onStatusLateRegisterFilterChange} placeholder="--selecione--" className="p-column-filter" showClear />;
    };

    const finalizaEmItemTemplate = (option) => {
        return (
            <React.Fragment>
                {option === 'late' && <Tag value="Late Register"></Tag>}
                {option === 'end' && <Tag severity="danger" value="Closed"></Tag>}
                {option === 'reg' && <Tag severity="info" value="Registering"></Tag>}
                {option === 'comp' && <Tag severity="warning" value="Completed"></Tag>}
                {option === 'late,reg' && (
                    <>
                        <Tag value="LR" /> <i className="pi pi-plus" style={{ fontSize: '0.65rem' }}></i> <Tag severity="info" value="Registering" />
                    </>
                )}
            </React.Fragment>
        );
    };*/

    const velocidadeBodyTemplate = (torneio) => {
        let speedDefault = torneio?.speed !== null ? torneio?.speed : '-';
        let speed = torneio.customTournaments !== null ? (torneio.customTournaments?.speed === null ? speedDefault : torneio.customTournaments?.speed) : speedDefault;
        return (
            <div>
                <div className="flex align-items-center gap-2">
                    <span>{speed}</span>
                </div>
            </div>
        );
    };

    const fimDoTorneioEmBodyTemplate = (torneio) => {
        let humanDurationDateDefault = torneio?.humanDurationDate !== null ? torneio?.humanDurationDate : '-';
        let humanDurationDate = torneio.customTournaments !== null ? (torneio.customTournaments?.humanDurationDate === null ? humanDurationDateDefault : torneio.customTournaments?.humanDurationDate) : humanDurationDateDefault;
        const fimDoTorneio = calculaFimTorneio(humanDurationDate);
        return (
            <div className="flex align-items-center gap-2" style={{ width: '5%' }}>
                <span>{fimDoTorneio}</span>
            </div>
        );
    };

    const estruturaTorneioBodyTemplate = (torneio) => {
        let tournamentStructureDefault = torneio?.tournamentStructure !== null ? torneio?.tournamentStructure : '-';
        let tournamentStructure = torneio.customTournaments !== null ? (torneio.customTournaments?.tournamentStructure === null ? tournamentStructureDefault : torneio.customTournaments?.tournamentStructure) : tournamentStructureDefault;
        return (
            <div>
                <div className="flex align-items-center gap-2">
                    <span>{tournamentStructure}</span>
                </div>
            </div>
        );
    };

    /*const capBodyTemplate = (torneio) => {
        let typeAvAbilityDefault = torneio.statisticsTournaments !== null ? torneio.statisticsTournaments?.typeAvAbility : '-';
        let typeAvAbility = torneio.customTournaments !== null ? (torneio.customTournaments?.typeAvAbility === null ? typeAvAbilityDefault : torneio.customTournaments?.typeAvAbility) : typeAvAbilityDefault;
        return (
            <div>
                <div className="flex align-items-center gap-2">
                    <span>{typeAvAbility}</span>
                </div>
            </div>
        );
    };*/

    const blindEntryBodyTemplate = (blindEntry) => {
        let maxLate = blindEntry.structureLevel === blindsEntryStructures.length && blindEntry.blindsEntry !== null;
        return (
            <React.Fragment>
                {!maxLate ? <span>{blindEntry.blindsEntry}</span> : ''}
                {maxLate ? (
                    <>
                        <Tag value={blindEntry.blindsEntry} className="text-base" /> <span className="ml-2 text-lg">Max Late</span>
                    </>
                ) : (
                    ''
                )}
            </React.Fragment>
        );
    };

    const minutesBodyTemplate = (blindEntry) => {
        return <span>{blindEntry.minutes + ` min.`}</span>;
    };

    const hourBlindBodyTemplate = (blindEntry) => {
        return (
            <span>
                {blindEntry.hourBlind} {blindEntry.isMinutePause ? <i className="pi pi-clock ml-1 text-red-500" title="Minuto de pausa"></i> : ''}
            </span>
        );
    };

    const editBodyTemplate = (torneio) => {
        return (
            <React.Fragment>
                <div className="hidden lg:flex">
                    <Button icon={<FontAwesomeIcon icon={solid('pen-to-square')} />} className="p-button-text p-button-primary" onClick={() => editTournaments(torneio)} disabled={disableChange} />
                    <Button icon={<FontAwesomeIcon icon={solid('trash-can')} />} className="p-button-text p-button-danger" onClick={() => confirmDeleteTournament(torneio)} disabled={disableChange} />
                </div>
            </React.Fragment>
        );
    };

    const editCustomDialogFooter = () => {
        return <Button label="Salvar" icon="pi pi-check" onClick={saveRegisteringTournament} loading={loading} />;
    };

    const customTournamentsHeader = () => {
        return (
            <>
                <div>
                    <span>{torneio?.name}</span>
                </div>
            </>
        );
    };

    const customTournamentsDialogFooter = () => {
        return (
            <React.Fragment>
                <Button label="Ent. Blinds" className="p-button-warning" icon={<FontAwesomeIcon icon={solid('coins')} className="mr-2" />} onClick={editBlindsEntry} />
                <Button label="Salvar" icon="pi pi-check" onClick={saveCustomTournament} loading={loading} />
            </React.Fragment>
        );
    };

    const setDiaListTorneio = (valueDia) => {
        let hasTorneio = diasTorneioSelecionado.find(tn => {
            return tn?.hourScheduledStartDate === torneio?.hourScheduledStartDate &&
                tn?.idnetwork === torneio?.idnetwork &&
                tn?.buyin === torneio?.buyin
        });

        let indexTorneio = diasTorneioSelecionado?.findIndex(tn => {
            return tn?.hourScheduledStartDate === torneio?.hourScheduledStartDate &&
                tn?.idnetwork === torneio?.idnetwork &&
                tn?.buyin === torneio?.buyin
        });

        if (hasTorneio?.favoriteTournaments.includes(valueDia)) {
            let indexDay = diasTorneioSelecionado[indexTorneio]?.favoriteTournaments?.findIndex(d => d === valueDia)

            let torneioRemoveDay = diasTorneioSelecionado[indexTorneio];
            torneioRemoveDay?.favoriteTournaments?.splice(indexDay, 1);
            diasTorneioSelecionado.splice(indexTorneio, 1, torneioRemoveDay);
        } else {
            let torneioAddDay = diasTorneioSelecionado[indexTorneio];
            torneioAddDay?.favoriteTournaments?.push(valueDia);
            diasTorneioSelecionado.splice(indexTorneio, 1, torneioAddDay);
        }
    };

    const getTorneioAlarmeByTorneioState = () => {
        let getTorneio = diasAlarmeTorneio.find(tn => {
            return tn.hourScheduledStartDate === torneio.hourScheduledStartDate &&
                tn.idnetwork === torneio.idnetwork &&
                tn.buyin === torneio.buyin
        });
        return getTorneio;
    };

    const getTorneioFavoritosTorneioState = () => {
        let getTorneio = diasTorneioSelecionado.find(tn => {
            return tn.hourScheduledStartDate === torneio.hourScheduledStartDate &&
                tn.idnetwork === torneio.idnetwork &&
                tn.buyin === torneio.buyin
        });

        return getTorneio;
    };

    const formatarTimeInicioTorneio = (time) => {
        return time < 0 ? 0 : time > 60 ? 60 : time;
    };

    const handleChangeFilterAlarme = (alarmeType, value) => {
        let getTorneio = getTorneioAlarmeByTorneioState();

        if (getTorneio) {

            let getIndex = diasAlarmeTorneio.findIndex(tn => {
                return tn.hourScheduledStartDate === torneio.hourScheduledStartDate &&
                    tn.idnetwork === torneio.idnetwork &&
                    tn.buyin === torneio.buyin
            });

            let torneioObject = {
                ...diasAlarmeTorneio[getIndex]
            };

            switch (alarmeType) {
                case filterTypes.ENTRADA_BLINDS:
                    setFilterAlarmeTorneio(alarmeType);
                    torneioObject = {
                        ...diasAlarmeTorneio[getIndex],
                        alarmBlindsEntry: value,
                        alarmScheduledStartDate: null,
                        alarmMaxLate: null
                    };
                    break;
                case filterTypes.INICIO_TORNEIO:
                    setFilterAlarmeTorneio(alarmeType);
                    torneioObject = {
                        ...diasAlarmeTorneio[getIndex],
                        alarmMaxLate: null,
                        alarmScheduledStartDate: value ? formatarTimeInicioTorneio(value) : 0,
                        alarmBlindsEntry: null
                    };
                    break;
                case filterTypes.MAX_LATE:
                    setFilterAlarmeTorneio(alarmeType);
                    torneioObject = {
                        ...diasAlarmeTorneio[getIndex],
                        alarmMaxLate: true,
                        alarmScheduledStartDate: null,
                        alarmBlindsEntry: null
                    };
                    break;
                default:
                    torneioObject = {
                        ...diasAlarmeTorneio[getIndex],
                        alarmScheduledStartDate: null,
                        alarmMaxLate: null,
                        alarmBlindsEntry: null
                    };
                    break;
            }

            diasAlarmeTorneio.splice(getIndex, 1, torneioObject);
        }
    };

    const setDiaListAlarmeTorneio = (valueDia) => {

        let hasTorneio = diasAlarmeTorneio.find(tn => {
            return tn?.hourScheduledStartDate === torneio?.hourScheduledStartDate &&
                tn?.idnetwork === torneio?.idnetwork &&
                tn?.buyin === torneio?.buyin
        });

        let indexTorneio = diasAlarmeTorneio.findIndex(tn => {
            return tn?.hourScheduledStartDate === torneio?.hourScheduledStartDate &&
                tn?.idnetwork === torneio?.idnetwork &&
                tn?.buyin === torneio?.buyin
        });

        if (hasTorneio?.alarmDaysOfWeek.includes(valueDia)) {
            let indexDay = diasAlarmeTorneio[indexTorneio]?.alarmDaysOfWeek?.findIndex(d => d === valueDia)

            let torneioRemoveDay = diasAlarmeTorneio[indexTorneio]
            torneioRemoveDay?.alarmDaysOfWeek?.splice(indexDay, 1)
            diasAlarmeTorneio.splice(indexTorneio, 1, torneioRemoveDay)
        } else {

            let torneioAddDay = diasAlarmeTorneio[indexTorneio]
            torneioAddDay?.alarmDaysOfWeek?.push(valueDia)
            diasAlarmeTorneio.splice(indexTorneio, 1, torneioAddDay)
        }
    };

    const setHideDialogFavoritoGold = () => {
        let getTorneio = getTorneioFavoritosTorneioState();

        if (getTorneio) {

            let getIndex = diasTorneioSelecionado.findIndex(tn => {
                return tn.hourScheduledStartDate === torneio.hourScheduledStartDate &&
                    tn.idnetwork === torneio.idnetwork &&
                    tn.buyin === torneio.buyin
            });

            diasTorneioSelecionado.splice(getIndex, 1);
        }

        setDisplayFavoriteGold(false);
    };

    const setHideDialogAlarme = () => {
        let getTorneio = getTorneioAlarmeByTorneioState();

        if (getTorneio) {

            let getIndex = diasAlarmeTorneio.findIndex(tn => {
                return tn.hourScheduledStartDate === torneio.hourScheduledStartDate &&
                    tn.idnetwork === torneio.idnetwork &&
                    tn.buyin === torneio.buyin
            });

            diasAlarmeTorneio.splice(getIndex, 1);
        }

        setDisplayAlarme(false);
        setFilterAlarmeTorneio(null);
    };

    const salvarDiasTorneio = () => {
        let getTorneio = getTorneioFavoritosTorneioState();

        const dataFetch = {
            ...getTorneio,
            daysOfWeek: getTorneio.favoriteTournaments,
            user: {
                email: user?.email,
                accessToken: user?.accessToken
            }
        };

        saveFavoriteTournaments(dataFetch);
    };

    const salvarDiasAlarmeTorneio = () => {
        let getTorneio = getTorneioAlarmeByTorneioState();
        const mensagem = new MensagemLista();

        if (!filterAlarmeTorneio) {
            mensagem.simpleMensagem('O tipo do alarme não foi definido', EnumTipoMensagem.ERRO)
            setMensagens(mensagem);
            return;
        }

        if (filterAlarmeTorneio === filterTypes.ENTRADA_BLINDS && !getTorneio.alarmBlindsEntry) {
            mensagem.simpleMensagem('Nenhum nível de ent. blinds definido', EnumTipoMensagem.ERRO);
            setMensagens(mensagem);
            return;
        }

        if (!getTorneio.alarmDaysOfWeek.length) {
            mensagem.simpleMensagem('Pelo um menos um dia da semana deve ser marcado', EnumTipoMensagem.ERRO);
            setMensagens(mensagem);
            return;
        }

        const dataFetch = {
            ...getTorneio,
            user: {
                email: user?.email,
                accessToken: user?.accessToken
            }
        };
        saveAlarmTournaments(dataFetch);
    };

    const deleteAlarmTournamente = () => {
        let getTorneio = getTorneioAlarmeByTorneioState();

        const dataFetch = {
            ...getTorneio,
            alarmDaysOfWeek: [],
            alarmMaxLate: null,
            alarmScheduledStartDate: null,
            alarmBlindsEntry: null,
            user: {
                email: user?.email,
                accessToken: user?.accessToken
            }
        };

        saveAlarmTournaments(dataFetch);
    };

    const deleteFavoritosTournamente = () => {
        let getTorneio = getTorneioFavoritosTorneioState();
        const dataFetch = {
            ...getTorneio,
            daysOfWeek: [],
            user: {
                email: user?.email,
                accessToken: user?.accessToken
            }
        };
        saveFavoriteTournaments(dataFetch);
    };

    const displayWeekFavoriteGold = () => {

        let getTorneio = diasTorneioSelecionado.find(tn => {
            return tn.hourScheduledStartDate === torneio.hourScheduledStartDate && tn.idnetwork === torneio.idnetwork && tn.buyin === torneio.buyin
        });

        return (
            <React.Fragment>
                <div className="grid mt-2">
                    {diasDaSemana?.map((dia) => {
                        const disabledDay = !torneio?.daysOfWeek?.includes(dia.abreviacao);

                        return (
                            <div key={dia.abreviacao} className="col">
                                <Button
                                    label={dia.abreviacao}
                                    icon={PrimeIcons.STAR}
                                    disabled={disabledDay}
                                    className={getTorneio?.favoriteTournaments.includes(dia.abreviacao) ? 'bg-yellow-300 border-yellow-300 outline-yellow-300 text-gray-900' : 'bg-text-yellow-300 p-button-outlined text-white'}
                                    onClick={() => setDiaListTorneio(dia.abreviacao)}
                                />
                            </div>
                        );
                    })}
                </div>
            </React.Fragment>
        );
    };

    const displayWeekAlarme = () => {
        let getTorneio = getTorneioAlarmeByTorneioState();

        return (
            <React.Fragment>
                <div className="grid mt-2">
                    {diasDaSemana?.map((dia) => {
                        const disabledDay = !torneio?.daysOfWeek?.includes(dia.abreviacao);

                        return (
                            <div key={dia.abreviacao} className="col">
                                <Button
                                    label={dia.abreviacao}
                                    icon={PrimeIcons.STAR}
                                    disabled={disabledDay}
                                    className={
                                        getTorneio?.alarmDaysOfWeek?.includes(dia.abreviacao) ? 'bg-yellow-300 border-yellow-300 outline-yellow-300 text-gray-900' : "bg-text-yellow-300 p-button-outlined text-white"
                                    }
                                    onClick={() => setDiaListAlarmeTorneio(dia.abreviacao)}
                                />
                            </div>
                        )
                    })
                    }
                </div>
            </React.Fragment>
        );
    };

    const editBlindsEntryDialogFooter = () => {
        let validaInclusao = initialChipCustomized === null || minutesBlindCustomized === null || humanLateRegEndDateMinCustomized === null || structureLevelCustomized === null || structureLevelCustomized === 0;
        return (
            <React.Fragment>
                <div>
                    <Button
                        label="Excluir"
                        className="p-button-text p-button-danger"
                        icon={<FontAwesomeIcon icon={solid('xmark')} className="mr-2" />}
                        onClick={onConfirmDeleteBlindsEntry}
                        loading={loading}
                        visible={hasBlindsEntry}
                        disabled={!hasBlindsEntry}
                    />
                    <Button label="Salvar" icon="pi pi-check" onClick={onConfirmNameBlindsEntry} loading={loading} visible={hasBlindsEntry} disabled={blindsEntryStructures?.[blindsEntryStructures?.length - 1]?.bigBlind === null || validaInclusao} />
                </div>
                <div>
                    <Button label="Incluir" icon="pi pi-table" onClick={addBlindsEntryStructure} loading={loading} visible={!hasBlindsEntry} disabled={validaInclusao} />
                </div>
            </React.Fragment>
        );
    };

    const editCellBlindsEntry = (options) => {
        switch (options.field) {
            case 'bigBlind':
                return <InputNumber value={options.value} onChange={(e) => options.editorCallback(e.value)} minFractionDigits={0} size={2} autoFocus />;
            case 'minutes':
                return <InputNumber value={options.value} onChange={(e) => options.editorCallback(e.value)} showButtons suffix=" min." min={1} size={1} />;
            default:
                break;
        }
    };

    const editFavoriteGold = (torneio) => {
        let humanScheduledStartDate = torneio.humanScheduledStartDate !== null ? DateTime.fromISO(torneio.humanScheduledStartDate) : null;
        setHourTournamentCustomized(humanScheduledStartDate);
        setHourTournamentOnly(humanScheduledStartDate.toLocaleString(DateTime.TIME_24_SIMPLE));
        setTorneio(torneio);
        setDisplayFavoriteGold(true);
        setDiasTorneioSelecionado(state => [...state, {
            hourScheduledStartDate: torneio.hourScheduledStartDate,
            idnetwork: torneio.idnetwork,
            buyin: torneio.buyin,
            daysOfWeek: torneio.daysOfWeek,
            favoriteTournaments: torneio.favoriteTournaments
        }]);
    }

    const saveCustomTournament = () => {
        let blindsStructures = null;
        if (!isArrayEmpty(blindsEntryStructures)) {
            blindsStructures = {
                structureName: nameBlindsEntry !== '' ? nameBlindsEntry : null,
                blindsEntryStructure: blindsEntryStructures
            };
        }

        let customTournament = {
            idnetwork: torneio.idnetwork,
            nameSharkscope: torneio.name,
            hourTournament: torneio.hourScheduledStartDate,
            buyin: torneio.buyin.toFixed(2),
            guarantee: guaranteeCustomized,
            nickName: nameTournamentCustomized,
            currency: currencyCustomized,
            field: fieldCustomized,
            typeAvAbility: typeAvAbilityCustomized,
            prizePositionOne: prizePositionOneCustomized,
            humanDurationDate: humanDurationDateCustomized,
            humanLateRegEndDateMin: humanLateRegEndDateMinCustomized,
            speed: speedCustomized,
            tournamentStructure: tournamentStructureCustomized,
            observation: observationTournamentsCustomized,
            initialChip: initialChipCustomized,
            minutesBlind: minutesBlindCustomized,
            tournament: { idsharkscope: torneio.idsharkscope, idnetwork: torneio.idnetwork },
            blindsStructures,
            user: {
                email: user?.email,
                accessToken: user?.accessToken
            }
        };
        saveCustomTournaments(customTournament);
    };

    const saveRegisteringTournament = () => {
        let registeringTournament = {
            ...torneio,
            guarantee: guaranteeCustomized,
            humanScheduledStartDate: hourTournamentCustomized,
            hourScheduledStartDate: hourTournamentCustomized !== null ? DateTime.fromJSDate(hourTournamentCustomized).toLocaleString(DateTime.TIME_24_SIMPLE) : null,
            lateRegEndDateMinutes: humanLateRegEndDateMinCustomized,
            user: {
                email: user?.email,
                accessToken: user?.accessToken
            }
        };
        saveRegisteringTournaments(registeringTournament);
    };

    const deleteBlindsEntryStructure = () => {
        if (confirmNameDeleteBlindsEntry !== null || !isUndefined(confirmNameDeleteBlindsEntry)) {
            deleteBlindsEntryStructures({ name: confirmNameDeleteBlindsEntry });
        }
    };

    const saveBlindsStructure = () => {
        const blindsStructures = {
            structureName: nameBlindsEntry !== '' ? nameBlindsEntry : null,
            blindsEntryStructure: blindsEntryStructures
        };
        let customTournament = {
            idnetwork: torneio.idnetwork,
            nameSharkscope: torneio.name,
            hourTournament: torneio.hourScheduledStartDate,
            buyin: torneio.buyin.toFixed(2),
            guarantee: torneio.guarantee,
            nickName: nameTournamentCustomized,
            currency: torneio.currency,
            field: fieldCustomized,
            typeAvAbility: typeAvAbilityCustomized,
            prizePositionOne: prizePositionOneCustomized,
            humanDurationDate: humanDurationDateCustomized,
            humanLateRegEndDateMin: humanLateRegEndDateMinCustomized,
            speed: speedCustomized,
            tournamentStructure: tournamentStructureCustomized,
            observation: observationTournamentsCustomized,
            initialChip: initialChipCustomized,
            minutesBlind: minutesBlindCustomized,
            tournament: { idsharkscope: torneio.idsharkscope, idnetwork: torneio.idnetwork },
            blindsStructures,
            user: {
                email: user?.email,
                accessToken: user?.accessToken
            }
        };
        saveBlindsEntrys(customTournament);
    };

    const saveFavoriteRed = (torneio) => {
        let daysOfWeek = torneio.favoriteTournaments?.length === 0 ? torneio.daysOfWeek : [];
        torneio.favoriteTournaments = daysOfWeek;
        setTorneio(torneio);
        let saveFavoriteTournament = {
            hourScheduledStartDate: torneio.hourScheduledStartDate,
            idnetwork: torneio.idnetwork,
            buyin: torneio.buyin,
            daysOfWeek,
            user: {
                email: user?.email,
                accessToken: user?.accessToken
            }
        };
        saveFavoriteTournaments(saveFavoriteTournament);
    };

    const addBlindsEntryStructure = () => {
        setLoading(true);
        if (initialChipCustomized !== null && minutesBlindCustomized !== null && humanLateRegEndDateMinCustomized !== null && structureLevelCustomized !== null) {
            for (let index = 1; index <= structureLevelCustomized; index++) {
                const _blindsEntryStructure = {
                    structureLevel: index,
                    bigBlind: null,
                    blindsEntry: null,
                    minutes: minutesBlindCustomized,
                    hourBlind: null
                };
                blindsEntryStructures.push(_blindsEntryStructure);
            }
            setBlindsEntryStructures(blindsEntryStructures);
            setHasBlindsEntry(!isArrayEmpty(blindsEntryStructures));
        }
        setLoading(false);
    };

    const handleClickMenuReta = (e) => {
        menu.current.toggle(e);
    };

    const renderHeaderTournaments = () => {
        return (
            <div className="flex flex-wrap justify-content-between">
                <div className="flex align-items-end justify-content-center pb-2">
                    <span className="p-input-icon-left">
                        <i className="pi pi-search" />
                        <InputText type="text" value={globalFilterValue} onChange={(e) => setGlobalFilterValue(e.target.value)} onKeyDown={onGlobalFilterChange} placeholder="Pesquisar..." />
                        <Button onClick={clearGlobalFilter} className="p-button-rounded p-button-plain p-button-text" icon="pi pi-filter-slash" />
                        <Button type="button" icon="pi pi-chart-bar" loading={loading} className="p-button-secondary" onMouseOver={(e) => tournamentStats.current.show(e)} onMouseOut={(e) => tournamentStats.current.hide(e)} />
                        <OverlayPanel ref={tournamentStats}>
                            <div className="formgrid grid">
                                <div className="flex justify-content-between col gap-1">
                                    <h6 className='font-mylobby m-0'>
                                        MY LO<span className='text-primary'>BB</span>Y
                                    </h6>
                                    <div>
                                        <small className="font-semibold">Estatística</small>
                                    </div>
                                </div>
                            </div>
                            <Divider />
                            <div className="grid">
                                <div className="col">
                                    <div className="flex justify-content-between flex-wrap">
                                        <span className="font-bold"><i className="pi pi-dollar mr-1"></i>Buy-in</span>
                                        <span className="text-yellow-500 font-semibold">{usDollar.format(tournamentStatistics?.avgBuyIn)}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="grid">
                                <div className="col">
                                    <div className="flex justify-content-between flex-wrap">
                                        <span className="font-bold"><i className="pi pi-users mr-1"></i>Field</span>
                                        <span className="text-yellow-500 font-semibold">{tournamentStatistics?.avgField}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="grid">
                                <div className="col">
                                    <div className="flex justify-content-between flex-wrap">
                                        <span className="font-bold">
                                            <span className="material-symbols-outlined text-lg mr-1">
                                                ads_click
                                            </span>Structure Bounty
                                        </span>
                                        <span className="text-yellow-500 font-semibold">{percent.format(tournamentStatistics?.avgStructureBounty)}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="grid">
                                <div className="col">
                                    <div className="flex justify-content-between flex-wrap">
                                        <span className="font-bold"><i className="pi pi-folder mr-1"></i>Structure Regular</span>
                                        <span className="text-yellow-500 font-semibold">{percent.format(tournamentStatistics?.avgStructureRegular)}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="grid">
                                <div className="col">
                                    <div className="flex justify-content-between flex-wrap">
                                        <span className="font-bold"><span className="material-symbols-outlined text-lg mr-1">
                                            speed_0_2x
                                        </span>Speed Regular</span>
                                        <span className="text-yellow-500 font-semibold">{percent.format(tournamentStatistics?.avgSpeedRegular)}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="grid">
                                <div className="col">
                                    <div className="flex justify-content-between flex-wrap">
                                        <span className="font-bold"><span className="material-symbols-outlined text-lg mr-1">
                                            speed_0_5x
                                        </span>Speed Turbo</span>
                                        <span className="text-yellow-500 font-semibold">{percent.format(tournamentStatistics?.avgSpeedTurbo)}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="grid">
                                <div className="col">
                                    <div className="flex justify-content-between flex-wrap">
                                        <span className="font-bold"><span className="material-symbols-outlined text-lg mr-1">
                                            speed_0_7x
                                        </span>Speed Hyper</span>
                                        <span className="text-yellow-500 font-semibold">{percent.format(tournamentStatistics?.avgSpeedHyper)}</span>
                                    </div>
                                </div>
                            </div>
                            <Divider align="left">
                                <div className="inline-flex align-items-center">
                                    <i className="pi pi-th-large mr-2"></i>
                                    <b>Sites</b>
                                </div>
                            </Divider>
                            <div className="formgrid grid mb-4">
                                <div className="flex align-items-center flex-wrap">
                                    {tournamentStatistics?.avgNetworks?.map((network, i) => {
                                        return (
                                            <div id={`${network.network}-${i}-${network.avgNetwork}`} key={`${network.network}-${i}-${network.avgNetwork}`}
                                                className="flex align-items-center justify-content-center col gap-2">
                                                <span>{siteImageTemplate(network.network)}</span><span className="font-bold">{percent.format(network.avgNetwork)}</span>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                            <small>¹ Média, de acordo com os filtros utilizados</small>
                        </OverlayPanel>
                    </span>
                    {user?.usersView?.settingsMenu && <div className="hidden lg:flex pl-2 gap-3 align-items-end">
                        <div>
                            <Dropdown className="w-15rem" options={managersTeam} value={managerTeam} onChange={(e) => getStraights(e.value)} placeholder="Gerente" />
                        </div>
                    </div>}
                    {user?.usersView?.tableSelectStraightSub && <div className="hidden lg:flex pl-2 gap-3 align-items-end">
                        <div>
                            <Dropdown id="retaEdicao" className='w-14rem' options={straightsHeaderMenu} value={retaEdicaoTorneios} onChange={e => setRetaEdicaoTorneios(e.value)} placeholder='Reta' />
                        </div>
                        <div>
                            <Dropdown id="subEdicao" className='w-8rem' options={subsByRetasTorneios} value={subEdicaoTorneios} onChange={e => setSubEdicaoTorneios(e.value)} placeholder='Sub-reta' />
                        </div>
                    </div>}
                </div>
                <div className="hidden lg:flex align-items-center justify-content-center">
                    {user?.usersView?.tableSelectStraight && <div>
                        <TieredMenu model={menuStraightItems} popup ref={menu} breakpoint="767px" />
                        <Button
                            label="Retas"
                            icon="pi pi-sort-alt"
                            className='bg-yellow-300 border-yellow-300 text-gray-900'
                            onClick={(e) => handleClickMenuReta(e)}
                        />
                    </div>}
                    <Button label="Excluir" icon="pi pi-trash" className="p-button-secondary p-button-plain ml-2" onClick={confirmDeleteTournaments} disabled={!disableChange} visible={user?.usersView?.tableSelectDeleteAll} />
                    <Button icon="pi pi-calendar-times" className="p-button-success p-button-plain ml-2" onClick={onFilterWithoutSchedulerStartDate} tooltip="Torneios sem data/hora inicial" tooltipOptions={{ position: 'bottom' }} visible={user?.usersView?.tableSelectDeleteAll} />
                </div>
            </div>
        );
    };

    const clearGlobalFilter = () => {
        setGlobalFilterValue('');
        onFilterChange(null);
        setLoading(true);
        setlazyState(lazyState);
        loadLazyTorneios();
    };

    const loadDataTableTorneio = () => {
        return (
            <>
                <div className="border-round-left-3xl border-round-top-3xl border-round-bottom-3xl border-round-right-3xl surface-border p-4 surface-card">
                    <Skeleton height="2rem" width="15rem" className="mb-2"></Skeleton>
                    <Divider />
                    <div className="flex justify-content-center flex-wrap mb-3">
                        <Skeleton height="2rem" width="12rem" className="mb-2"></Skeleton>
                        <Skeleton size="2rem" className="ml-2 mb-2"></Skeleton>
                    </div>
                    <Divider />
                    <Skeleton width="100%" height="150px"></Skeleton>
                    <Divider />
                    <div className="flex justify-content-center flex-wrap mb-3">
                        <Skeleton height="2rem" width="12rem" className="mb-2"></Skeleton>
                        <Skeleton size="2rem" className="ml-2 mb-2"></Skeleton>
                    </div>
                    <Divider />
                </div>
            </>
        );
    };

    const confirmaNomeBlindsEntryFooter = () => {
        return (
            <div className="flex justify-content-end flex-wrap">
                <Button label="Confirmar" icon="pi pi-check" onClick={saveBlindsStructure} disabled={loading} />
            </div>
        );
    };

    const confirmaDeletarBlindsEntryFooter = () => {
        return (
            <div className="flex justify-content-end flex-wrap">
                <Button label="Deletar" icon="pi pi-trash" onClick={deleteBlindsEntryStructure} disabled={loading || confirmNameDeleteBlindsEntry !== nameBlindsEntry} />
            </div>
        );
    };

    const confirmaDiferencaLateReg = () => {
        return (
            <div className="flex justify-content-end flex-wrap">
                <Button label="OK" onClick={() => setDisplayDiferenceLateReg(false)} />
            </div>
        );
    };

    const confirmaFavoriteGold = () => {
        return (
            <div className="flex justify-content-end flex-wrap">
                <Button label="Excluir" className="p-button-text p-button-danger" icon={<FontAwesomeIcon icon={solid('xmark')} className="mr-2" />} onClick={() => setDeleteFavoriteDialog(true)} />
                <Button label="Salvar" className="bg-yellow-300 border-yellow-300 text-gray-900" icon="pi pi-check" onClick={() => salvarDiasTorneio()} />
            </div>
        );
    };

    const confirmaAlarme = () => {
        return (
            <div className="flex justify-content-end flex-wrap">
                <Button label="Excluir" className="p-button-text p-button-danger" icon={<FontAwesomeIcon icon={solid('xmark')} className="mr-2" />} onClick={() => setDeleteAlarmDialog(true)} />
                <Button label="Salvar" icon="pi pi-check" className="bg-yellow-300 border-yellow-300 text-gray-900" onClick={() => salvarDiasAlarmeTorneio()} />
            </div>
        );
    };

    const getStraights = (selectedManagerTeam) => {
        if (managerTeam !== selectedManagerTeam) {
            setRetaEdicaoTorneios(null);
            setSubEdicaoTorneios(null);
            setTorneiosRetasAndSub([]);
            setSelectedTorneios([]);
        }
        setManagerTeam(selectedManagerTeam);
        if (selectedManagerTeam !== null) {
            let _filters = props.filter;
            props.onFilter([{ field: 'manager', value: [selectedManagerTeam] }, ..._filters]);
            if (user?.usersView.settingsMenu) {
                getStraightByManager(selectedManagerTeam);
            }
        } else {
            getStraightByUser();
        }
    }


    /*const dynamicColumnsTournament = [
        { field: 'favorite', header: <span className={PrimeIcons.STAR}></span>, body: favoritoBodyTemplate },
        { field: 'alarm', header: <span className={PrimeIcons.BELL}></span>, body: alarmeBodyTemplate },
        { field: 'priorities', header: 'Prioridades', body: null },
        { field: 'startScheduler', header: 'Início', body: inicioBodyTemplate },
        { field: 'blindsEntry', header: 'Ent. Blinds', body: null },
        { field: 'buyinWithCurrency', header: 'Buy-in', body: null },
        { field: guaranteeWithCurrencyBodyTemplate, header: 'Garantido', body: null },
        { field: 'field', header: 'Field', body: fieldBodyTemplate },
        { field: '1', header: '1º', body: firstBodyTemplate },
        { field: 'speed', header: 'Velocidade', body: velocidadeBodyTemplate },
        { field: 'duration', header: 'Fim do Torneio', body: fimDoTorneioEmBodyTemplate },
        { field: 'tournamentStructure', header: 'Estrutura Torneio', body: estruturaTorneioBodyTemplate },
        { field: 'cap', header: 'Cap', body: capBodyTemplate }
    ];
    const [selectedColumns, setSelectedColumns] = useState(dynamicColumnsTournament);
    
    const dynamicColumnsComponents = selectedColumns.map((col) => {
        return <Column key={col.field} field={col.field} header={col.header} body={col.body} />;
    });*/

    const FavotiosStarHeader = () => {
        return (
            <div className="flex align-items-center">
                <i
                    className="pi pi-star mr-3"
                    style={{
                        fontSize: 22
                    }}
                ></i>
                <strong className="">Favoritos</strong>
            </div>
        );
    };

    const AlarmeBellHeader = () => {
        return (
            <div className="flex align-items-center">
                <i
                    className="pi pi-bell mr-3"
                    style={{
                        fontSize: 22
                    }}
                ></i>
                <strong className="">Alarme</strong>
            </div>
        );
    };

    const handleDeleteReta = () => {
        const mensagem = new MensagemLista();
        mensagem.simpleMensagem('Reta excluída com sucesso', EnumTipoMensagem.SUCCESS);
        setMensagens(mensagem);
        setShowVincularJogador(false);
    };

    const CriarRetaHeader = () => {
        return (
            <div className="flex align-items-center">
                <i className="pi pi-fw pi-plus mr-2"></i>
                <strong className="">Criar reta</strong>
            </div>
        );
    };

    const EditarRetaHeader = () => {
        return (
            <div className="flex align-items-center">
                <i className="pi pi-fw pi-pencil mr-2"></i>
                <strong className="">Editar reta</strong>
            </div>
        );
    };

    const VincularRetaHeader = () => {
        return (
            <div className="flex align-items-center">
                <i className="pi pi-save mr-2"></i>
                <strong className="">Vincular torneios</strong>
            </div>
        );
    };

    const ClonarRetaHeader = () => {
        return (
            <div className="flex align-items-center">
                <i className="pi pi-clone mr-2"></i>
                <strong className="">Clonar reta</strong>
            </div>
        );
    };

    const VincularJogadorHeader = () => {
        return (
            <div className="flex align-items-center">
                <i className="pi pi-user-plus mr-2"></i>
                <strong className="">Vincular jogador</strong>
            </div>
        );
    };

    const handleCriarReta = (e) => {
        e.preventDefault();
        const data = {
            line: retaEdicao,
            sub: subEdicao,
            managerTeam: {
                name: managerTeam
            },
            user: {
                email: user?.email,
                accessToken: user?.accessToken
            }
        };
        MyLobbyService.postRetas(data)
            .then((data) => {
                const mensagem = new MensagemLista();
                mensagem.simpleMensagem('Reta criada com sucesso', EnumTipoMensagem.SUCCESS);
                setMensagens(mensagem);
            }).catch((error) => {
                setLoading(false);
                let mensagem = tratarExcecao(error);
                setMensagens(mensagem);
            }).finally(() => {
                setShowCreateReta(false);
                setRetaEdicao('');
                setSubEdicao('');
                getStraights(managerTeam);
                setSubEdicaoTorneios(null);
                setTorneiosRetasAndSub([]);
            });
    }

    const handleGetReta = (e) => {
        e.preventDefault();
        const data = {
            line: retaEdicao,
            sub: subEdicao,
            managerTeam: {
                name: managerTeam
            },
            changeTo: {
                line: editarNomeReta,
                sub: editarSubReta
            },
            user: {
                email: user?.email,
                accessToken: user?.accessToken
            }
        };
        MyLobbyService.patchRetas(data)
            .then((data) => {
                const mensagem = new MensagemLista();
                mensagem.simpleMensagem('Reta alterada com sucesso', EnumTipoMensagem.SUCCESS);
                setMensagens(mensagem);
            })
            .catch((error) => {
                setLoading(false);
                let mensagem = tratarExcecao(error);
                setMensagens(mensagem);
            })
            .finally(() => {
                setShowEditarReta(false);
                setEditarNomeReta('');
                setEditarSubReta('');
                getStraights(managerTeam);
                setSubEdicaoTorneios(null);
                setTorneiosRetasAndSub([]);
            });
    };

    const handleVincularTounarmentsReta = (e) => {
        e.preventDefault();
        const mensagem = new MensagemLista();
        mensagem.simpleMensagem('Editando reta', EnumTipoMensagem.SUCCESS);
        setMensagens(mensagem);
        setShowVincularReta(false);
    };

    const handleCloneReta = (e) => {
        e.preventDefault();

        const data = {
            line: retaToClone,
            sub: subToClone,
            managerTeam: {
                name: managerTeam
            },
            cloneTo: {
                line: nomeRetaToClone,
                sub: nomeSubToClone
            },
            user: {
                email: user?.email,
                accessToken: user?.accessToken
            }
        };
        MyLobbyService.patchRetas(data)
            .then((data) => {
                const mensagem = new MensagemLista();
                mensagem.simpleMensagem('Reta clonada com sucesso', EnumTipoMensagem.SUCCESS);
                setMensagens(mensagem);
            })
            .catch((error) => {
                setLoading(false);
                let mensagem = tratarExcecao(error);
                setMensagens(mensagem);
            })
            .finally(() => {
                setClonarReta(false);
                setNomeRetaToClone('');
                setNomeSubToClone('');
                getStraights(managerTeam);
                setSubEdicaoTorneios(null);
                setTorneiosRetasAndSub([]);
            });
    };

    const handleVincularJogador = (e) => {
        e.preventDefault();
        const data = {
            line: retaVincularJogador,
            sub: subVincularJogador,
            managerTeam: {
                name: managerTeam
            },
            straightsPlayers: [
                {
                    user: {
                        email: emailJogador
                    }
                }
            ],
            straightsTournaments: [],
            user: {
                email: user?.email,
                accessToken: user?.accessToken
            }

        };
        MyLobbyService.putReta(data)
            .then((data) => {
                const mensagem = new MensagemLista();
                mensagem.simpleMensagem('Jogador vinculado com sucesso', EnumTipoMensagem.SUCCESS);
                setMensagens(mensagem);
            })
            .catch((error) => {
                setLoading(false);
                let mensagem = tratarExcecao(error);
                setMensagens(mensagem);
            })
            .finally(() => {
                setShowVincularJogador(false);
                setEmailJogador('');
                setSubVincularJogador('');
                setRetaVincularJogador('');
                getStraights(managerTeam);
                setSubEdicaoTorneios(null);
                setTorneiosRetasAndSub([]);
            });
    };

    return (
        <>
            <Dialog
                visible={showCreateReta}
                onHide={() => setShowCreateReta(false)}
                header={<CriarRetaHeader />}
                style={{ width: '15vw' }}
            >
                <form onSubmit={handleCriarReta}>
                    <div className="p-fluid">
                        <div className="field">
                            <label>Reta</label>
                            <InputText value={retaEdicao} onChange={e => setRetaEdicao(e.target.value)} required />
                        </div>
                        <div className="field">
                            <label>Sub-reta</label>
                            <InputText value={subEdicao} onChange={e => setSubEdicao(e.target.value)} required />
                        </div>
                    </div>
                    <div className="flex justify-content-end flex-wrap mt-4">
                        <Button type='submit' className='bg-yellow-300 border-yellow-300 text-gray-900' label="Salvar" icon="pi pi-check" onClick={() => { }} />
                    </div>
                </form>
            </Dialog>

            <Dialog
                visible={showEditarReta}
                onHide={() => setShowEditarReta(false)}
                header={<EditarRetaHeader />}
                style={{ width: '15vw' }}
            >
                <form onSubmit={handleGetReta}>
                    <div className="p-fluid">
                        <div className="field">
                            <label>Reta</label>
                            <Dropdown id="retaEdicao" options={straightsFormat} value={retaEdicao} onChange={e => setRetaEdicao(e.value)} />
                        </div>
                        <div className='field'>
                            <label>Sub-reta</label>
                            <Dropdown id="subEdicao" options={subsByRetas} value={subEdicao} onChange={e => setSubEdicao(e.value)} />
                        </div>
                        <Divider className='my-4' align="center">
                            <span className="p-tag">Dê um novo nome a:</span>
                        </Divider>
                        <div className='field'>
                            <label>Reta</label>
                            <InputText value={editarNomeReta} onChange={e => setEditarNomeReta(e.target.value)} required />
                        </div>
                        <div className='field'>
                            <label>Sub-reta</label>
                            <InputText value={editarSubReta} onChange={e => setEditarSubReta(e.target.value)} required />
                        </div>
                        <div className="flex justify-content-end mt-4 gap-2">
                            <div className="flex justify-content-end flex-wrap mt-2">
                                <Button label="Excluir" type='button' className="p-button-text p-button-danger" icon={<FontAwesomeIcon icon={solid('xmark')} className="mr-2" />} onClick={() => setDeleteRetaDialog(true)} />
                            </div>
                            <div className="flex justify-content-end flex-wrap mt-2">
                                <Button type='submit' className='bg-yellow-300 border-yellow-300 text-gray-900' label="Salvar" icon="pi pi-check" />
                            </div>
                        </div>
                    </div>
                </form>
            </Dialog>

            <Dialog
                visible={showVincularReta}
                onHide={() => setShowVincularReta(false)}
                header={<VincularRetaHeader />}
                style={{ width: '15vw' }}
            >
                <form onSubmit={handleVincularTounarmentsReta}>
                    <div className="p-fluid">
                        <div className="field">
                            <label>Reta</label>
                            <Dropdown id="retaEdicao" required options={['Reta ex: 1']} value={retaEdicao} onChange={e => setRetaEdicao(e.value)} />
                        </div>
                        <div className='field'>
                            <label>Sub-reta</label>
                            <Dropdown id="subEdicao" required options={['Sub ex: 2']} value={subEdicao} onChange={e => setSubEdicao(e.value)} />
                        </div>
                    </div>
                    <div className="flex justify-content-end flex-wrap mt-4">
                        <Button type='submit' className='bg-yellow-300 border-yellow-300 text-gray-900' label="Salvar" icon="pi pi-check" />
                    </div>
                </form>
            </Dialog>

            <Dialog
                visible={clonarReta}
                onHide={() => setClonarReta(false)}
                header={<ClonarRetaHeader />}
                style={{ width: '15vw' }}
            >
                <form onSubmit={handleCloneReta}>
                    <div className="p-fluid">
                        <div className='field'>
                            <label>Reta</label>
                            <Dropdown id="retaEdicaoClone" options={straightsFormat} value={retaToClone} onChange={e => setRetaToClone(e.value)} />
                        </div>
                        <div className='field'>
                            <label>Sub-reta</label>
                            <Dropdown id="subEdicao" options={subsByRetasTorneiosClone} value={subToClone} onChange={e => setSubToClone(e.value)} />
                        </div>
                        <Divider className='my-4' align="center">
                            <span className="p-tag">Copiar para:</span>
                        </Divider>
                        <div className='field'>
                            <label>Reta</label>
                            <InputText value={nomeRetaToClone} onChange={e => setNomeRetaToClone(e.target.value)} required />
                        </div>
                        <div className='field'>
                            <label>Sub-reta</label>
                            <InputText value={nomeSubToClone} onChange={e => setNomeSubToClone(e.target.value)} required />
                        </div>
                    </div>
                    <div className="flex justify-content-end flex-wrap mt-4">
                        <Button type='submit' className='bg-yellow-300 border-yellow-300 text-gray-900' label="Salvar" icon="pi pi-check" />
                    </div>
                </form>
            </Dialog>

            <Dialog
                visible={showVincularJogador}
                onHide={() => setShowVincularJogador(false)}
                header={<VincularJogadorHeader />}
                style={{ width: '15vw' }}
            >
                <form onSubmit={handleVincularJogador}>
                    <div className="p-fluid">
                        <div className='field'>
                            <label>Reta</label>
                            <Dropdown id="retaEdicao" options={straightsFormat} value={retaVincularJogador} onChange={e => setRetaVincularJogador(e.value)} />
                        </div>
                        <div className='field'>
                            <label>Sub-reta</label>
                            <Dropdown id="subEdicao" options={subsByRetasVincularJogador} value={subVincularJogador} onChange={e => setSubVincularJogador(e.value)} />
                        </div>
                        <Divider className='my-4' align="center">
                            <span className="p-tag">Informe o jogador:</span>
                        </Divider>
                        <div className='field'>
                            <label>E-mail</label>
                            <InputText type='email' value={emailJogador} onChange={e => setEmailJogador(e.target.value)} required />
                            {/* <MultiSelect
                            value={selectedPlayersReta}
                            onChange={(e) => setSelectedPlayersReta(e.value)}
                            options={jogadores}
                            optionLabel="name"
                            placeholder="Selecione os jogadores"
                            maxSelectedLabels={3}
                            className="w-full md:w-20rem"
                        /> */}
                        </div>
                    </div>
                    <div className="flex justify-content-end flex-wrap mt-4">
                        <Button type='submit' className='bg-yellow-300 border-yellow-300 text-gray-900' label="Salvar" icon="pi pi-check" />
                    </div>
                </form>
            </Dialog>

            {initialLoading && loadDataTableTorneio()}
            {!initialLoading && (
                <div className="card">
                    <ConfirmDialog visible={deleteReta} onHide={() => setDeleteReta(false)} message="Deseja excluir o reta?" accept={handleDeleteReta} icon="pi pi-exclamation-triangle" />
                    <ConfirmDialog
                        visible={deleteTournamentDialog}
                        onHide={() => setDeleteTournamentDialog(false)}
                        message="Deseja excluir o torneio?"
                        header={`ID: ${torneio?.idsharkscope} - ${defineNomeTorneio(torneio)} `}
                        accept={deleteTournamentById}
                        icon="pi pi-exclamation-triangle"
                    />
                    <ConfirmDialog
                        visible={deleteTournamentsDialog}
                        onHide={() => setDeleteTournamentsDialog(false)}
                        message="Deseja excluir os torneios selecionados?"
                        header="Excluir selecionados"
                        accept={deleteTornaments}
                        reject={cancelConfirmDeleteTournaments}
                        icon="pi pi-exclamation-triangle"
                    />
                    <ConfirmDialog
                        visible={deleteRetaDialog}
                        onHide={() => setDeleteRetaDialog(false)}
                        message="Deseja excluir reta?"
                        header="Excluir reta"
                        accept={deletarReta}
                        reject={() => setDeleteRetaDialog(false)}
                        icon="pi pi-exclamation-triangle"
                    />
                    {mensagens !== '' ? <AppMesagens mensagens={mensagens} position="bottom-center" /> : ''}
                    <DataTable
                        value={torneios}
                        size="small"
                        lazy
                        dataKey="idsharkscope"
                        paginator
                        paginatorPosition="both"
                        selection={selectedTorneios}
                        onSelectionChange={onSelectTorneios}
                        selectionMode={user?.usersView?.tableSelectStraight ? "checkbox" : ""}
                        selectionPageOnly
                        header={renderHeaderTournaments}
                        first={lazyState.first}
                        rows={rows}
                        rowsPerPageOptions={[5, 10, 25, 50]}
                        totalRecords={totalRecords}
                        onPage={onPage}
                        metaKeySelection={false}
                        onSort={onSort}
                        sortField={lazyState.sortField}
                        sortOrder={lazyState.sortOrder}
                        removableSort
                        onFilter={onFilter}
                        filters={lazyState.filters}
                        loading={loading}
                        className="p-datatable-striped"
                        responsiveLayout="scroll"
                        stripedRows
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown CurrentPageReport"
                        currentPageReportTemplate="Total de {totalRecords} torneios"
                    >
                        {user?.usersView?.tableSelectStraight && <Column selectionMode="multiple" headerStyle={{ width: '1rem' }}></Column>}
                        {user?.usersView?.tableSelectEdit && <Column body={editBodyTemplate} ></Column>}
                        {/* {dynamicColumnsComponents} */}
                        <Column header="Favoritos" body={favoritoRedBodyTemplate}></Column>
                        <Column header="Alarme" body={alarmeBodyTemplate}></Column>
                        <Column header="Prioridades" body={prioridadesBodyTemplate} sortable sortField="priorities"></Column>
                        <Column header="Início" field="humanScheduledStartDate" body={inicioBodyTemplate} sortable></Column>
                        <Column header="Site" field="network" body={siteBodyTemplate} sortable></Column>
                        <Column header="Torneio" field="name" body={torneioBodyTemplate} sortable></Column>
                        <Column header="Ent. Blinds" body={blindsEntryBodyTemplate} sortable={user?.usersView?.blindsEntryFilter} sortField="blindsEntry" ></Column>
                        <Column header="Buy-in" field="buyInWithCurrency" sortField="buyin" sortable></Column>
                        <Column header="Garantido" field={guaranteeWithCurrencyBodyTemplate} sortField="guarantee" sortable></Column>
                        <Column header="Field" body={fieldBodyTemplate} sortable sortField="field"></Column>
                        <Column header="1º" body={firstBodyTemplate}></Column>
                        <Column header="Velocidade" body={velocidadeBodyTemplate} sortField="speed" sortable></Column>
                        <Column header="Fim do Torneio" body={fimDoTorneioEmBodyTemplate} sortField="hourHumanDurationDate" sortable></Column>
                        <Column
                            header="Late Register"
                            field="humanLateRegEndDate"
                            body={finalizaEmBodyTemplate}
                            sortable
                            showFilterMatchModes={false}
                            style={{ minWidth: '12rem' }}
                        ></Column>
                        <Column header="Estrutura Torneio" body={estruturaTorneioBodyTemplate} sortField="tournamentStructure" sortable></Column>
                        {/* <Column header="Cap" body={capBodyTemplate}></Column> */}
                    </DataTable>
                </div>
            )}
            <Dialog header={customTournamentsHeader} visible={customTournamentsDialog} style={{ width: '50vw' }} onHide={() => setCustomTournamentsDialog(false)} footer={customTournamentsDialogFooter}>
                <div className="card p-fluid">
                    <div className="formgrid grid">
                        <div className="field col-2">
                            <label htmlFor="idTournaments">ID</label>
                            <InputText id="idTournaments" value={torneio?.idsharkscope} disabled />
                        </div>
                        <div className="field col-6">
                            <label htmlFor="nameTournaments">Nome do torneio</label>
                            <InputText id="nameTournaments" value={torneio?.name} disabled />
                        </div>
                        <div className="field col-2">
                            <label htmlFor="dayTournaments">Dia do torneio</label>
                            <Calendar id="dayTournaments" value={hourTournamentCustomized} dateFormat="dd/mm" disabled />
                        </div>
                        <div className="field col-2">
                            <label htmlFor="hourTournament">Hora do torneio</label>
                            <Calendar id="hourTournament" value={hourTournamentCustomized} timeOnly disabled />
                        </div>
                        <div className="field col-12">
                            <label htmlFor="nameTournamentCustomized">Apelido do torneio</label>
                            <InputText id="nameTournamentCustomized" value={nameTournamentCustomized || ''} onChange={(e) => setNameTournamentsCustomized(e.target.value)} autoFocus />
                        </div>
                        <div className="field col-3">
                            <label htmlFor="speedCustomized">Velocidade</label>
                            <Dropdown id="speedCustomized" value={speedCustomized} onChange={(e) => setSpeedCustomized(e.value)} options={['Regular', 'Turbo', 'Hyper']} showClear />
                        </div>
                        <div className="field col-3">
                            <label htmlFor="tournamentStructureCustomized">Estrutura do torneio</label>
                            <Dropdown id="tournamentStructureCustomized" value={tournamentStructureCustomized} onChange={(e) => setTournamentStructureCustomized(e.value)} options={['Regular', 'Bounty']} showClear />
                        </div>
                        <div className="field col-3">
                            <label htmlFor="humanDurationDateCustomized">Fim do Torneio</label>
                            <Calendar
                                id="humanDurationDateCustomized"
                                dateFormat="dd/mm/yy"
                                showIcon
                                icon={<FontAwesomeIcon icon={solid('calendar-xmark')} />}
                                showTime
                                value={humanDurationDateCustomized}
                                onChange={(e) => setHumanDurationDateCustomized(e.value)}
                                showButtonBar
                            />
                        </div>
                        <div className="field col-3">
                            <label htmlFor="humanLateRegEndDateMinCustomized">Late Register</label>
                            <InputNumber id="humanLateRegEndDateMinCustomized" value={humanLateRegEndDateMinCustomized} onChange={(e) => setHumanLateRegEndDateMinCustomized(e.value)} showButtons suffix=" min." step={10} min={0} />
                            <small id="username-help">a partir da hora de início do Torneio</small>
                        </div>
                        <div className="field col-3">
                            <label htmlFor="guaranteeCustomized">Garantido</label>
                            <div className="p-inputgroup">
                                <span className="p-inputgroup-addon">
                                    {optionsCurrency.map((curr) => (curr.type === currencyCustomized ? curr.code : ''))}
                                </span>
                                <InputNumber id="guaranteeCustomized" value={guaranteeCustomized} onChange={(e) => setGuaranteeCustomized(e.value)} locale="en-US" minFractionDigits={2} />
                            </div>
                        </div>
                        <div className="field col-3">
                            <label htmlFor="fieldCustomized">Field</label>
                            <div className="p-inputgroup">
                                <span className="p-inputgroup-addon">
                                    <FontAwesomeIcon icon={solid('users-line')} />
                                </span>
                                <InputNumber id="fieldCustomized" value={fieldCustomized} onChange={(e) => setFieldCustomized(e.value)} locale="en-US" minFractionDigits={2} />
                            </div>
                        </div>
                        <div className="field col-3">
                            <label htmlFor="typeAvAbilityCustomized">Cap</label>
                            <InputNumber id="typeAvAbilityCustomized" value={typeAvAbilityCustomized} onChange={(e) => setTypeAvAbilityCustomized(e.value)} locale="en-US" minFractionDigits={1} />
                        </div>
                        <div className="field col-3">
                            <label htmlFor="prizePositionOneCustomized">1º</label>
                            <div className="p-inputgroup">
                                <span className="p-inputgroup-addon">
                                    <FontAwesomeIcon icon={solid('hand-holding-dollar')} />
                                </span>
                                <InputNumber id="prizePositionOneCustomized" value={prizePositionOneCustomized} onChange={(e) => setPrizePositionOneCustomized(e.value)} locale="en-US" minFractionDigits={2} />
                            </div>
                        </div>
                        <div className="field col-12">
                            <label htmlFor="observationTournamentsCustomized">Observação</label>
                            <InputTextarea id="observationTournamentsCustomized" value={observationTournamentsCustomized || ''} onChange={(e) => setObservationTournamentsCustomized(e.target.value)} />
                        </div>
                    </div>
                </div>
            </Dialog>

            <Dialog header={customTournamentsHeader} visible={editCustomDialog} style={{ width: '18vw' }} onHide={() => setEditCustomDialog(false)} footer={editCustomDialogFooter}>
                <div className="card p-fluid">
                    <div className="formgrid grid">
                        <div className="col">
                            <div className="field">
                                <label htmlFor="idTournaments">ID:</label>
                                <InputText id="idTournaments" value={torneio?.idsharkscope} disabled />
                            </div>
                            <div className="field">
                                <label htmlFor="guaranteeCustomized">Garantido</label>
                                <span className="p-inputgroup">
                                    <span className="p-inputgroup-addon">{optionsCurrency.map((curr) => (curr.type === currencyCustomized ? curr.code : ''))}</span>
                                    <InputNumber id="guaranteeCustomized" value={guaranteeCustomized} onChange={(e) => setGuaranteeCustomized(e.value)} locale="en-US" minFractionDigits={2} autoFocus />
                                </span>
                                <small>Não há um valor garantido cadastrado para esse torneio. Informe o valor garantido no campo acima.</small>
                            </div>
                            <div className="field">
                                <label htmlFor="dayTournamentsCustomized">Data/Hora início do Torneio</label>
                                <Calendar id="dayTournamentsCustomized" value={hourTournamentCustomized} onChange={(e) => setHourTournamentCustomized(e.value)} showTime showButtonBar showIcon readOnlyInput />
                                <small>Data e Hora do Torneio deve ser informada.</small>
                            </div>
                            <div className="field">
                                <label htmlFor="humanLateRegEndDateMinCustomized">Late Register</label>
                                <InputNumber id="humanLateRegEndDateMinCustomized" value={humanLateRegEndDateMinCustomized} onChange={(e) => setHumanLateRegEndDateMinCustomized(e.value)} showButtons suffix=" min." step={10} min={0} />
                                <small>a partir da hora de início do Torneio</small>
                            </div>
                        </div>
                    </div>
                </div>
            </Dialog>

            <Dialog header="Entrada de Blinds" headerClassName="text-center" visible={editBlindsEntryDialog} style={{ width: '40vw' }} onHide={() => setEditBlindsEntryDialog(false)} footer={editBlindsEntryDialogFooter}>
                <div className="card p-fluid">
                    <div className="formgrid grid">
                        <div className="field col-6">
                            <label htmlFor="nameTournaments">Nome do torneio</label>
                            <InputText id="nameTournaments" value={torneio?.name} disabled />
                        </div>
                        <div className="field col-3">
                            <label htmlFor="speed">Velocidade</label>
                            <InputText id="speed" value={torneio?.speed} disabled />
                        </div>
                        <div className="field col-3">
                            <label htmlFor="humanLateRegEndDateMinCustomized">Late Register</label>
                            <InputNumber id="humanLateRegEndDateMinCustomized" value={humanLateRegEndDateMinCustomized} onChange={(e) => setHumanLateRegEndDateMinCustomized(e.value)} showButtons suffix=" min." step={10} min={0} />
                            <small>a partir da hora de início às: {hourTournamentOnly}</small>
                        </div>
                        <div className="field col-4">
                            <label htmlFor="blindsEntrys">Ent. Blinds (Estrutura)</label>
                            <Dropdown
                                id="blindsEntrys"
                                value={selectedBlindsEntryStructures}
                                options={structuresNames}
                                onChange={onBlindsEntryStructures}
                                optionLabel="structureName"
                                filter
                                filterBy="structureName"
                                showClear
                                placeholder="--selecione--"
                            />
                        </div>
                        <div className="field col-3">
                            <label htmlFor="chip">Ficha inicial</label>
                            <span className="p-inputgroup">
                                <span className="p-inputgroup-addon">
                                    <FontAwesomeIcon icon={solid('coins')} />
                                </span>
                                <InputNumber id="chip" value={initialChipCustomized} onChange={onChangeInitialChipCustomized} locale="en-US" minFractionDigits={0} autoFocus />
                            </span>
                        </div>
                        <div className="field col-3">
                            <label htmlFor="minutesBlind">Minutos Blind</label>
                            <InputNumber id="minutesBlind" value={minutesBlindCustomized} onChange={onChangeMinutesBlindCustomized} showButtons suffix=" min." min={0} max={humanLateRegEndDateMinCustomized} />
                        </div>
                        <div className="field col-2">
                            <label htmlFor="structureLevel">Nível</label>
                            <InputNumber id="structureLevel" value={structureLevelCustomized} onChange={onChangeStructureLevelCustomized} showButtons min={0} />
                        </div>
                    </div>

                    {hasBlindsEntry && (
                        <div>
                            <DataTable value={blindsEntryStructures} editMode="cell" size="small" responsiveLayout="scroll" width={{ width: '10%' }}>
                                <Column field="structureLevel" header="Nível Estrutura" className="font-bold" />
                                <Column field="bigBlind" header="Big Blind" editor={(options) => editCellBlindsEntry(options)} onCellEditComplete={onCellEditComplete} />
                                <Column header="Ent. Blind" body={blindEntryBodyTemplate} />
                                <Column field="minutes" header="Blind em" body={minutesBodyTemplate} editor={(options) => editCellBlindsEntry(options)} onCellEditComplete={onCellEditComplete} />
                                <Column field="hourBlind" header="Hora" body={hourBlindBodyTemplate} />
                            </DataTable>
                            <small className="flex flex-wrap justify-content-end text-right">
                                ¹ Clique sobre a linha da coluna 'Big Blind' para editar valores.
                                <br />² Use a tecla TAB para editar próxima linha.
                            </small>
                        </div>
                    )}
                </div>
            </Dialog>

            <Dialog visible={confirmNameBlindsEntryDialog} style={{ width: '16vw' }} onHide={() => setConfirmNameBlindsEntryDialog(false)} footer={confirmaNomeBlindsEntryFooter}>
                <div className="card p-fluid">
                    <div className="formgrid grid">
                        <div className="field col">
                            <label htmlFor="nameBlindsEntry">Nome sugerido</label>
                            <InputText id="nameBlindsEntry" value={nameBlindsEntry} onChange={onNameBlindsEntry} />
                            <small>* Utilize esse nome para cadastrar essa estrutura de Big Blinds em outros torneios</small>
                        </div>
                    </div>
                </div>
            </Dialog>

            <Dialog visible={confirmDeleteBlindsEntryDialog} style={{ width: '16vw' }} onHide={() => setConfirmDeleteBlindsEntryDialog(false)} footer={confirmaDeletarBlindsEntryFooter}>
                <div className="card p-fluid">
                    <div className="formgrid grid">
                        <div className="field col">
                            <label htmlFor="nameBlindsEntry">Confirme o nome para excluir</label>
                            <InputText id="nameBlindsEntry" value={confirmNameDeleteBlindsEntry} onChange={(e) => setConfirmNameDeleteBlindsEntry(e.target.value)} />
                            <small>
                                * Utilize o nome: <span className="font-bold text-red-500 select-all">{nameBlindsEntry}</span> para confirmar a exclusão da estrutura de Big Blinds
                            </small>
                        </div>
                    </div>
                </div>
            </Dialog>

            <Dialog visible={displayDiferenceLateReg} onHide={() => setDisplayDiferenceLateReg(false)} style={{ width: '25vw' }} footer={confirmaDiferencaLateReg}>
                <p>
                    O Late Register do torneio,{' '}
                    <span className="font-bold">
                        "{torneio?.name} ({torneio?.lateRegEndDateMinutes} min.)
                    </span>
                    " é diferente da estrutura informada.{' '}
                    <span className="font-bold">
                        "{blindsEntryStructures[0]?.structureName} ({blindsEntryStructures[0]?.lateRegMinutes} min.)"
                    </span>
                </p>
            </Dialog>

            <Dialog header={<FavotiosStarHeader />} visible={displayFavoriteGold} onHide={() => setHideDialogFavoritoGold()} style={{ width: '42vw' }} footer={confirmaFavoriteGold}>
                <div className="card p-fluid">
                    <div className="flex align-items-center gap-3">
                        <div className="surface-600 border-round h-full py-2 px-3 ">{siteImageTemplate(torneio?.networkDetails?.nickName, '42px')}</div>
                        <div className="flex-1 px-4">
                            <label className="mb-2 block text-xs">Torneio</label>
                            <p className="text-xl">{torneio?.name}</p>
                        </div>
                        <div className="px-4">
                            <label className='mb-2 block text-xs'>ID</label>
                            <p className='text-xl'>{torneio?.idsharkscope}</p>
                        </div>
                        <div className="px-4">
                            <label className="mb-2 block text-xs">Horário</label>
                            <p className="text-xl">{hourTournamentOnly}</p>
                        </div>
                    </div>
                    <Divider />

                    <div>{displayWeekFavoriteGold()}</div>
                </div>
            </Dialog>

            <Dialog header={<AlarmeBellHeader />} visible={displayAlarme} onHide={() => setHideDialogAlarme()} style={{ width: '42vw' }} footer={confirmaAlarme}>
                <div className="card p-fluid">
                    <div className="flex align-items-center gap-3 mb-4">
                        <div className="surface-600 border-round h-full py-2 px-3 ">{siteImageTemplate(torneio?.networkDetails?.nickName, '42px')}</div>
                        <div className="flex-1 px-3">
                            <label className="mb-2 block text-xs">Torneio</label>
                            <p className="text-xl">{torneio?.name}</p>
                        </div>
                        <div className="px-3">
                            <label className='mb-2 block text-xs'>Garantido</label>
                            <p className='text-xl'>{torneio?.guaranteeWithCurrency}</p>
                        </div>
                        <div className="px-3">
                            <label className='mb-2 block text-xs'>Buyin</label>
                            <p className='text-xl'>{torneio?.buyInWithCurrency}</p>
                        </div>
                        <div className="px-3">
                            <label className="mb-2 block text-xs">Horário</label>
                            <p className="text-xl">{hourTournamentOnly}</p>
                        </div>
                    </div>

                    <Divider />

                    <div className="mt-4">
                        <div className="flex gap-2 align-items-center mb-4">
                            <div className="flex align-items-center">
                                <Button
                                    onClick={() => handleChangeFilterAlarme(filterTypes.INICIO_TORNEIO)}
                                    className={filterAlarmeTorneio === filterTypes.INICIO_TORNEIO ? 'w-10rem bg-yellow-300 border-yellow-300 text-gray-900' : 'w-10rem p-button-outlined text-white'}
                                >
                                    <i className="pi pi-bell mr-1" />
                                    Ínicio
                                </Button>
                            </div>

                            <div>
                                <InputNumber
                                    showButtons
                                    value={getTorneioAlarmeByTorneioState()?.alarmScheduledStartDate}
                                    className="w-10rem"
                                    decrementButtonClassName="bg-yellow-300 border-yellow-300 text-gray-900"
                                    incrementButtonClassName="bg-yellow-300 border-yellow-300 text-gray-900"
                                    onChange={(e) => handleChangeFilterAlarme(filterTypes.INICIO_TORNEIO, e.value)}
                                    placeholder="0 min."
                                    suffix=" min."
                                    min={0}
                                    max={60}
                                    disabled={filterAlarmeTorneio !== filterTypes.INICIO_TORNEIO}
                                />
                            </div>

                            <div className={filterAlarmeTorneio === filterTypes.INICIO_TORNEIO ? 'flex align-items-center hidden' : 'hidden'}>
                                <p>Avisar {getTorneioAlarmeByTorneioState()?.alarmScheduledStartDate > 0 ? `${getTorneioAlarmeByTorneioState()?.alarmScheduledStartDate} min. antes do início do torneio.` : "no início do torneio."}</p>
                            </div>
                        </div>

                        <div className={user?.usersView?.blindsEntryFilter ? "flex gap-2 align-items-center mb-4" : "hidden"}>
                            <div className="flex align-items-center">
                                <Button
                                    style={{ width: 155 }}
                                    onClick={() => handleChangeFilterAlarme(filterTypes.ENTRADA_BLINDS)}
                                    className={filterAlarmeTorneio === filterTypes.ENTRADA_BLINDS ? 'bg-yellow-300 border-yellow-300 text-gray-900' : 'p-button-outlined text-white'}
                                >
                                    <i className="pi pi-bell mr-1" /> Ent. Blinds
                                </Button>
                            </div>

                            <div className="">
                                <Dropdown
                                    style={{ width: 175 }}
                                    id="blindsOptionsTorneioAlarme"
                                    value={getTorneioAlarmeByTorneioState()?.alarmBlindsEntry}
                                    onChange={(e) => handleChangeFilterAlarme(filterTypes.ENTRADA_BLINDS, e.target.value)}
                                    options={optionsalarmBlindsEntry}
                                    placeholder="Ent. Blinds"
                                    disabled={filterAlarmeTorneio !== filterTypes.ENTRADA_BLINDS}
                                />
                            </div>
                        </div>

                        <div className="flex gap-2 align-items-center mb-4">
                            <div className="flex align-items-center">
                                <Button
                                    onClick={() => handleChangeFilterAlarme(filterTypes.MAX_LATE)}
                                    className={filterAlarmeTorneio === filterTypes.MAX_LATE ? 'w-10rem bg-yellow-300 border-yellow-300 text-gray-900' : 'w-10rem p-button-outlined text-white'}
                                >
                                    <i className="pi pi-bell mr-1" /> Max. Late
                                </Button>
                            </div>

                            <div className={filterAlarmeTorneio === filterTypes.MAX_LATE ? "flex align-items-center" : "hidden"}>Você será avisado {user?.settings?.maxLateInMinutes} min. antes do fim do registro.</div>
                        </div>
                    </div>

                    {/* <div className=''>
                        <Button
                            onClick={() => changealarmDaysOfWeekTorneio()}
                            icon={PrimeIcons.BELL}
                            className={`p-button-rounded p-button-plain ${showDaysOfWeekAlarme ? '' : 'p-button-text'}`}
                        />
                    </div> */}
                    <div>{displayWeekAlarme()}</div>
                </div>
            </Dialog>
            <ConfirmDialog header="Confirmação" visible={deleteAlarmDialog} onHide={() => setDeleteAlarmDialog(false)} message="Deseja excluir o alarme?" accept={deleteAlarmTournamente} icon="pi pi-exclamation-triangle" />
            <ConfirmDialog header="Confirmação" visible={deleteFavoriteDialog} onHide={() => setDeleteFavoriteDialog(false)} message="Deseja excluir o favorito?" accept={deleteFavoritosTournamente} icon="pi pi-exclamation-triangle" />
        </>
    );
};

export default memo(Tournaments);
